import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CallToActionFooter = styled(Box)(({ theme: { spacing, zIndex, palette } }) => ({
  justifyContent: 'center',
  position: 'sticky',
  textAlign: 'center',
  marginTop: 3,
  bottom: 0,
  width: '100%',
  padding: spacing(3),
  zIndex: zIndex.appBar,
  background: palette.background.patterned,
}));
