import React, { useState, useRef, useEffect } from 'react';
import { Box, Slider, Typography } from '@mui/material';

import { PauseIcon, PlayIcon } from '../../assets/icons';

export const CustomAudioPlayer: React.FC<{
  audioUrl: string;
  title1?: string;
  title2?: string;
  isPlaying: boolean;
  onAudioClick: () => void;
}> = ({ audioUrl, title1, title2, isPlaying, onAudioClick }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playing, setPlaying] = useState(isPlaying);

  useEffect(() => {
    const audio: any = audioRef.current;

    const handleLoadedData = () => {
      setDuration(audio.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    audio.addEventListener('loadeddata', handleLoadedData);
    audio.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      audio.removeEventListener('loadeddata', handleLoadedData);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, []);

  useEffect(() => {
    const audio: any = audioRef.current;
    if (isPlaying && playing) {
      audio.play();
    } else {
      audio.pause();
      setCurrentTime(0); // Reset current time when pausing
    }
  }, [isPlaying, playing]);

  const handleAudioEnd = () => {
    setCurrentTime(0);
  };

  const handleSliderChange = (e: any, value: number | number[]) => {
    if (typeof value === 'number') {
      const audio = audioRef.current;
      if (audio) {
        audio.currentTime = value;
        setCurrentTime(value);
      }
    }
  };

  const formatTime = (time: number | undefined) => {
    if (!time) {
      return '0:00';
    }
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleToggleClick = () => {
    const audio: any = audioRef.current;

    if (playing) {
      audio.pause();
    } else {
      audio.play();
    }
    setPlaying(!playing);
    onAudioClick(); // Notify the parent component
  };

  return (
    <Box pt={2} pb={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <img onClick={handleToggleClick} src={playing && isPlaying ? PauseIcon : PlayIcon} />
        <Box pl={2}>
          <Typography>{title1}</Typography>
          <Typography>{title2}</Typography>
        </Box>
      </Box>
      <Box>
        <Slider value={currentTime} max={duration} onChange={handleSliderChange} aria-labelledby="audio-slider" />
        <Box display="flex" justifyContent="space-between">
          <div>{formatTime(currentTime)}</div>
          <div>{formatTime(duration)}</div>
        </Box>
      </Box>
      <audio ref={audioRef} src={audioUrl} onEnded={handleAudioEnd} />
    </Box>
  );
};
