import React, { ReactNode } from 'react';
import { Container, Box, BoxProps, Typography, Button, TextField } from '@mui/material';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { useCurrentUser } from '../../contexts/user-context';
import { BackgroundCheckFormData, useBackgroundCheck } from '../../common/http/hooks/background-check';
import { TitleText } from '../Guide/GuidePageLayout';
import { ROUTE_PATH } from '../../routing/route-paths';
import { FormattedUser } from '../../common/http/hooks/user';

const BackgroundCheck = () => {
  const user = useCurrentUser();
  return user.listenerRole?.backgroundCheckVerified ? (
    <ApprovedBackgroundPage />
  ) : user.listenerRole?.checkrId !== null ? (
    <PendingBackgroundPage />
  ) : (
    <IncompleteBackgroundPage user={user} />
  );
};

const BackgroundCheckPage: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <>
    <PageNavigator />
    <Container sx={{ pb: 10 }}>
      <BackgroundPageLayout>{children}</BackgroundPageLayout>
    </Container>
  </>
);

const ApprovedBackgroundPage = () => {
  return (
    <BackgroundCheckPage>
      <TitleText pt={2} pb={3}>
        Complete your Background Check
      </TitleText>
      <BackgroundStatusBox title="Approved" sx={{ bgcolor: 'secondary.main', alignSelf: 'flex-start' }} />
      <BackgroundCheckMessage>Thank you for completing your background check!</BackgroundCheckMessage>
    </BackgroundCheckPage>
  );
};

const PendingBackgroundPage = () => {
  return (
    <BackgroundCheckPage>
      <TitleText pt={2} pb={3}>
        Complete your Background Check
      </TitleText>
      <BackgroundStatusBox title="Requested" sx={{ bgcolor: 'warning.main', alignSelf: 'flex-start' }} />
      <BackgroundCheckMessage>Your background check link will be sent via email.</BackgroundCheckMessage>
      <Box pt={4} />
      <Button type="button" variant="contained" href={ROUTE_PATH.support} sx={{ px: 4, maxWidth: '580px' }}>
        Contact Us
      </Button>
    </BackgroundCheckPage>
  );
};

const backgroundCheckSchema = yup.object().shape({
  email_address: yup.string().email('Must be valid email').required('Email is required'),
});

const IncompleteBackgroundPage: React.FC<{ user: FormattedUser }> = ({ user }) => {
  const mutation = useBackgroundCheck();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BackgroundCheckFormData>({
    resolver: yupResolver(backgroundCheckSchema),
    defaultValues: {
      listener_role_id: user.listenerRole?.id,
      email_address: user.emailAddress,
    },
  });

  const submit = (formData: BackgroundCheckFormData) => {
    mutation.mutate(formData);
  };
  return (
    <BackgroundCheckPage>
      <TitleText pt={2} pb={3}>
        Complete your Background Check
      </TitleText>
      <BackgroundStatusBox title="Not Completed" sx={{ bgcolor: 'warning.main', alignSelf: 'flex-start' }} />
      <BackgroundCheckMessage>Your background check link will be sent via email.</BackgroundCheckMessage>
      <Box pt={4} />
      <form noValidate onSubmit={handleSubmit(submit)}>
        <TextField
          disabled
          aria-disabled
          autoComplete="email"
          {...register('email_address')}
          id="email_address"
          fullWidth
          name="email_address"
          label="EMAIL"
          error={Boolean(errors.email_address)}
          variant="outlined"
          helperText={errors.email_address ? errors.email_address.message : ''}
        />
        <Button type="submit" variant="contained" sx={{ width: '100%', mt: 4, px: 4 }}>
          Request Background Check
        </Button>
      </form>
    </BackgroundCheckPage>
  );
};

const BackgroundCheckMessage: React.FC<React.ComponentProps<typeof Typography>> = (props) => (
  <Typography {...props} mt={4} color="text.primary" sx={{ fontSize: 18, fontWeight: 400 }} />
);

const BackgroundPageLayout: React.FC<React.ComponentProps<typeof Box>> = (props) => (
  <Box
    display="flex"
    justifyContent="flex-start"
    alignItems="center"
    flexDirection="column"
    sx={{ px: 2 }}
    {...props}
  />
);

interface BackgroundStatusBoxProps extends BoxProps {
  title: string;
}

const BackgroundStatusBox: React.FC<BackgroundStatusBoxProps> = ({ title, ...rest }) => (
  <Box
    {...rest}
    color="text.light"
    alignItems="center"
    display="flex"
    justifyContent="center"
    width="200px"
    height="48px"
    borderRadius="8px"
    fontSize="18px"
    fontWeight={700}
  >
    {title}
  </Box>
);

export default BackgroundCheck;
