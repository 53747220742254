import { useState } from 'react';
import { Box } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import StepLabel from '@mui/material/StepLabel';
import Step from '@mui/material/Step';

interface StepperSteps {
  text: string;
  stepNumber: number;
}

export const ProgressMobileStepper: React.FC<{ steps: StepperSteps[]; stepCount: number; currentStep: number }> = ({
  steps,
  stepCount,
  currentStep,
}) => {
  return (
    <>
      <MobileStepper
        variant="progress"
        steps={stepCount}
        position="static"
        activeStep={currentStep}
        backButton
        nextButton
        LinearProgressProps={{ sx: { width: '100%' } }}
      />
      <Box display="flex" justifyContent="space-around" marginBottom="2rem">
        {steps.map((step) => (
          <Step key={step.text} active={currentStep === step.stepNumber}>
            <StepLabel>{step.text}</StepLabel>
          </Step>
        ))}
      </Box>
    </>
  );
};
