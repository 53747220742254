import React from 'react';
import List from '@mui/material/List/List';
import { OnboardMenuListItem } from '../OnboardMenuListItem/OnboardMenuListItem';
import { Divider } from '@mui/material';
import { OnboardMenuItem } from '../OnboardMenu';

interface OnboardMenuItemProps {
  onboardingList: OnboardMenuItem[];
}

export enum OnboardingStatus {
  PENDING = 'PENDING',
  REVISIT = 'REVISIT',
  DONE = 'DONE',
}

export const OnboardMenuList = ({ onboardingList }: OnboardMenuItemProps) => {
  return (
    <List sx={{ width: '100%', paddingBottom: 0 }}>
      <Divider variant="fullWidth" component="li" />
      {onboardingList.map((item, key) => {
        return <OnboardMenuListItem key={key} name={item.name} status={item.status} href={item.href} />;
      })}
    </List>
  );
};
