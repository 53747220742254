import React, { createContext, useEffect, useState, useRef } from 'react';
import Keycloak from 'keycloak-js';
import { PwaFeatures, useFeatureFlag } from '../common/http/hooks/feature-flags';
import { useUserDeauthentication } from '../common/http/hooks/user';
import { useQueryClient } from 'react-query';
import { ROUTE_PATH } from '../routing/route-paths';
import useStickyState from '../common/hooks/useStickyState';
import { useNavigate } from 'react-router-dom';
import Spinner from '../common/components/Spinner/Spinner';

interface AuthContextProps {
  keycloak: Keycloak | null;
  authToken: string | null;
  logout: () => void;
  isKeycloakEnabled: string | null;
  onUserAuthenticated: (props: OnUserAuthenticatedProps) => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: React.ReactNode;
}

interface OnUserAuthenticatedProps {
  isListenerRole: boolean;
  isCallerRole: boolean;
  isAdminRole: boolean;
  authToken: string;
}

export const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_IDP_URL!,
  realm: process.env.REACT_APP_KEYCLOAK_IDP_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_IDP_CLIENT_ID!,
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const isFirstRun = useRef<boolean>(false);
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
  const [authToken, setAuthToken] = useStickyState('token', null);
  const keycloakFeatureFlag = useFeatureFlag(PwaFeatures.PWA_KEYCLOAK_LOGIN);
  const [isKeycloakEnabled, setIsKeycloakEnabled] = useStickyState('isKeycloakEnabled', null);
  const navigate = useNavigate();
  const deauthenticateUser = useUserDeauthentication();
  const queryClient = useQueryClient();

  const onUserAuthenticated = ({ isListenerRole, isCallerRole, isAdminRole, authToken }: OnUserAuthenticatedProps) => {
    if (!isListenerRole) {
      if (isCallerRole) {
        navigate(ROUTE_PATH.mwaRedirect);
        return;
      } else if (isAdminRole) {
        navigate(ROUTE_PATH.switchboardRedirect);
        return;
      }
    } else {
      if (authToken) {
        setAuthToken(authToken);
      }
      queryClient.invalidateQueries(['currentUser']);
      navigate(ROUTE_PATH.home.home);
    }
  };

  const logout = () => {
    if (keycloakFeatureFlag.enabled && keycloak) {
      keycloak?.logout();
    } else {
      deauthenticateUser.mutateAsync();
    }
    localStorage.clear();
    setAuthToken(null);
    queryClient.setQueryData(['currentUser'], null);
    queryClient.setQueryData(['me'], null);
  };

  useEffect(() => {
    setIsKeycloakEnabled(keycloakFeatureFlag.enabled ? 'true' : null);
  }, [keycloakFeatureFlag.enabled]);

  useEffect(() => {
    if (isFirstRun.current) return;
    isFirstRun.current = true;
    const initKeycloak = async () => {
      const keycloakInstance: Keycloak = new Keycloak(keycloakConfig);

      keycloakInstance
        .init({
          onLoad: 'check-sso',
        })
        .then(() => {
          if (keycloakInstance.token && !authToken) {
            const isListenerRole = keycloakInstance.hasRealmRole('listener');
            const isCallerRole = keycloakInstance.hasRealmRole('caller');
            const isAdminRole = keycloakInstance.hasRealmRole('administrator');
            const authToken = keycloakInstance.token;
            onUserAuthenticated({ isListenerRole, isCallerRole, isAdminRole, authToken });
          }
        })
        .catch((error) => {
          setAuthToken(null);
        })
        .finally(() => {
          setKeycloak(keycloakInstance);
        });
    };

    initKeycloak();
  }, []);

  // keycloak takes longer to intialize than most pages do to load;
  // this was causing pages to render, and then re-render when keycloak was initialized
  // this spinner will prevent that bad UI
  if (isKeycloakEnabled && !keycloak) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider value={{ keycloak, authToken, logout, isKeycloakEnabled, onUserAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
