import React, { useState } from 'react';

import Spinner from '../../common/components/Spinner/Spinner';
import {
  Experience,
  UpdateExperiencePayload,
  useAudioFile,
  useUpdateExperience,
} from '../../common/http/hooks/listener-audio';
import { ExperienceFlow } from './ExperienceFlow';
import axios, { AxiosError } from 'axios';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import { ApiError } from '../../common/types';
import { useRollbar } from '@rollbar/react';
import { useCurrentUser } from '../../contexts/user-context';

const EditExperienceFlow: React.FC<{
  goBack: () => void;
  experience: Experience;
  header?: React.ReactNode;
  transitionPage?: () => void;
}> = ({ goBack, experience, header, transitionPage }) => {
  const [wasAudioChanged, setWasAudioChanged] = useState(false);

  const audioFileQuery = useAudioFile(experience.fileUrl);
  const updateExperienceMutation = useUpdateExperience(experience.id);
  const rollbar = useRollbar();
  const user = useCurrentUser();

  const onSubmit = ({ audioFile, title, topicTagIds }: UpdateExperiencePayload) => {
    updateExperienceMutation.mutate(
      { audioFile: wasAudioChanged ? audioFile : undefined, title, topicTagIds },
      {
        onSuccess: transitionPage ? transitionPage : goBack,
        onError: (error) => {
          if (axios.isAxiosError(error)) {
            Toast.error(
              'API Error: ' + ((error as AxiosError<ApiError>).response?.data.description ?? 'Request Failed'),
            );
          } else {
            Toast.error('Error: ' + ((error as Error).message ?? 'Unhandled Exception'));
          }
          rollbar.debug(`[LOC-3720] experience audio update failed user_id=${user.id}`);
        },
      },
    );
  };

  if (!audioFileQuery.data) return <Spinner />;

  return (
    <ExperienceFlow
      header={header}
      goBack={goBack}
      onSubmit={onSubmit}
      isSubmitting={updateExperienceMutation.isLoading}
      defaultValues={{
        audioFile: audioFileQuery.data,
        audioUrl: experience.fileUrl,
        title: experience.title,
        topicTagIds: experience.topicIds,
      }}
      onSetExperienceAudio={() => {
        setWasAudioChanged(true);
      }}
    />
  );
};

export default EditExperienceFlow;
