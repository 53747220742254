import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { If } from './If';

export interface SimpleDialogProps {
  title: string;
  text: string;
  close: () => void;
  exit: () => void;
  confirmAction: () => void;
  openState: boolean;
  button1text?: string;
  button2text?: string;
  button1Color?: 'error';
}

function ConfirmModal(props: SimpleDialogProps) {
  const theme = useTheme();

  return (
    <Dialog PaperProps={{ style: { backgroundColor: theme.palette.background.paper } }} open={props.openState}>
      <DialogTitle fontWeight={800}>
        <Grid container alignItems="baseline" justifyContent="space-between" mb={-1}>
          <Grid item>
            <Typography variant="h6" fontWeight={800}>
              {props.title}
            </Typography>
          </Grid>
          <Grid item alignSelf="right" mr={-2}>
            <IconButton onClick={props.exit} aria-label="close">
              <CloseIcon sx={{ color: theme.palette.text.primary }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} flexDirection="column">
          <Grid mb={2} item>
            <Typography fontSize="1.2rem" variant="body1">
              {props.text}
            </Typography>
          </Grid>
          <Grid alignSelf="center" item>
            <Button
              sx={{ width: '200px' }}
              color={props.button1Color}
              variant="contained"
              onClick={props.confirmAction}
            >
              {props.button1text ? props.button1text : 'Save Changes'}
            </Button>
          </Grid>
          <Grid alignSelf="center" item>
            <If value={Boolean(props.button2text)}>
              <Button
                sx={{
                  width: '200px',
                }}
                variant="outlined"
                color="primary"
                onClick={props.close}
              >
                {props.button2text ? props.button2text : 'Discard Changes'}
              </Button>
            </If>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmModal;
