import invariant from 'tiny-invariant';
import { useMutation, useQueryClient } from 'react-query';
import http from '../http-provider';
import { useCurrentUser } from '../../../contexts/user-context';

const sendGuide = async (listenerRoleId: number, emailAddress: string) => {
  const listenerGuidePath = `/listeners/${listenerRoleId}/send_listener_guide`;
  const search = emailAddress && `?${new URLSearchParams({ email_address: emailAddress })}`;
  await http.get(`${listenerGuidePath}${search}`);
};

export const useSendGuide = () => {
  const user = useCurrentUser();

  return {
    ...useMutation(async ({ emailAddress }: { emailAddress: string }) => {
      invariant(user.listenerRole, 'Listener role is not defined on the user.');

      await sendGuide(user.listenerRole.id, emailAddress);
    }),
    isEnabled: Boolean(user.listenerRole),
  };
};

const confirmGuide = async (listenerRoleId: number) => {
  await http.get(`/listeners/${listenerRoleId}/confirm_guide`);
};

export const useConfirmGuide = () => {
  const user = useCurrentUser();
  const queryClient = useQueryClient();

  return {
    ...useMutation(
      async () => {
        invariant(user.listenerRole, 'Listener role is not defined on the user.');

        await confirmGuide(user.listenerRole.id);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['currentUser']);
        },
      },
    ),
    isEnabled: Boolean(user.listenerRole),
  };
};
