import React from 'react';
import { Box } from '@mui/material';

export enum StepStatus {
  Active,
  Completed,
  ToDo,
}

const blueBorder = { width: 2, style: 'solid', color: 'primary.main' };
const statusAttributes = {
  [StepStatus.Active]: {
    backgroundColor: 'primary.main',
    textColor: 'text.light',
    border: null,
    disabled: true,
  },
  [StepStatus.Completed]: {
    backgroundColor: 'success.main',
    textColor: 'text.light',
    border: null,
    disabled: false,
  },
  [StepStatus.ToDo]: {
    backgroundColor: 'background.default',
    textColor: 'primary.main',
    border: blueBorder,
    disabled: true,
  },
};

const Step: React.FC<{ children: React.ReactNode; status: StepStatus; onClick?: () => void; size?: string }> = ({
  children,
  status,
  onClick,
  size = '2.75rem',
}) => {
  const { backgroundColor, textColor, border, disabled } = statusAttributes[status];
  const isClickable = onClick !== undefined && !disabled;

  const borderStyles = border
    ? { borderWidth: border.width, borderStyle: border.style, borderColor: border.color }
    : {};

  const handleClick = () => {
    if (isClickable) onClick();
  };

  return (
    <Box
      width={size}
      height={size}
      borderRadius="50%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={backgroundColor}
      color={textColor}
      sx={{ cursor: isClickable ? 'pointer' : null, ...borderStyles }}
      onClick={handleClick}
    >
      {children}
    </Box>
  );
};

export default Step;
