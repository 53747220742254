import { useQuery } from 'react-query';
import invariant from 'tiny-invariant';

import http from '../http-provider';
import { DateTime } from 'luxon';
import { useCurrentUser } from '../../../contexts/user-context';

interface RawCall {
  request: {
    status: string;
    perform_backup_requests: boolean;
    caller_role_id: number;
    connections_processed_at: string | null;
    text: string | null;
    audio_file_url: string | null;
    topic_id: number;
    updated_at: string | null;
    id: number;
    call_id: number;
    original_backup_request_id: number | null;
    is_backup_request: boolean;
    original_request_id: number | null;
    scheduled_at: string | null;
    created_at: string;
    call_units_length: number | null;
  };
  caller_role: {
    id: number;
    user: {
      display_name: string;
      mobile_phone: string;
      pronoun: string | null;
      first_name: string;
      email_address: string;
      id: number;
      last_name: string;
    };
  };
  state: {
    // TODO: be more specific about possible state-type values
    type: string;
  };
  ended_at: string | null;
  created_at: string;
  phone_number: string | null;
  crisis_id: number | null;
}

export interface Call {
  createdAt: DateTime;
  callId: number;
  callUnitsLength: number | null;
  audioFileUrl: string | null;
  requestText: string | null;
  crisisId: number | null;
  phoneNumber: string | null;
  // TODO: make state an enum
  state: string;
  caller: {
    firstName: string;
    pronoun: string | null;
  };
}

const formatCallRequest = ({
  request: {
    created_at: createdAt,
    call_id: callId,
    call_units_length: callUnitsLength,
    audio_file_url: audioFileUrl,
    text: requestText,
  },
  crisis_id: crisisId,
  phone_number: phoneNumber,
  state: { type: state },
  caller_role: {
    user: { first_name: firstName, pronoun },
  },
}: RawCall): Call => ({
  createdAt: DateTime.fromISO(createdAt),
  callId,
  callUnitsLength,
  audioFileUrl,
  requestText,
  crisisId,
  phoneNumber,
  state,
  caller: { firstName, pronoun },
});

const fetchActiveCall = async (listenerRoleId: number): Promise<RawCall | null> => {
  invariant(listenerRoleId, 'listener role Id required');
  const { data: activeCalls } = await http.get<RawCall[]>(
    `/calls/?${new URLSearchParams({ listener_role_id: String(listenerRoleId), is_active: 'true' })}`,
  );
  return activeCalls?.[0] ?? null;
};

export const useActiveCall = (options?: { onSuccess?: (call: Call | null) => void }) => {
  const user = useCurrentUser();

  return useQuery(
    ['activeCall', user?.listenerRole?.id],
    () => {
      invariant(user?.listenerRole, 'Listener role is not defined on the user.');
      return fetchActiveCall(user.listenerRole.id);
    },
    {
      enabled: Boolean(user?.listenerRole),
      select: (callRequest) => callRequest && formatCallRequest(callRequest),
      ...options,
    },
  );
};
