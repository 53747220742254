export const capitalizeFirstLetter = (string: string | null | undefined) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

const numberToWordMapping = {
  1: 'one ',
  2: 'two ',
  3: 'three ',
  4: 'four ',
  5: 'five ',
  6: 'six ',
  7: 'seven ',
  8: 'eight ',
  9: 'nine ',
  10: 'ten ',
  11: 'eleven ',
  12: 'twelve ',
  13: 'thirteen ',
  14: 'fourteen ',
  15: 'fifteen ',
  16: 'sixteen ',
  17: 'seventeen ',
  18: 'eighteen ',
  19: 'nineteen ',
  20: 'twenty',
};

type WordableNumber = keyof typeof numberToWordMapping;

export const isWordable = (num: number): num is WordableNumber => num in numberToWordMapping;

export const numberAsWord = (num: WordableNumber) => numberToWordMapping[num];

export const readFileToString = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === 'string') resolve(reader.result);
      else reject(new Error('Unable to read file to string'));
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsDataURL(file);
  });
