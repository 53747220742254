import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { useAddBackgroundTag, type BackgroundTag } from '../../common/http/hooks/tags';
import BackButton from '../CrisisElevation/BackButton';

const MAX_MEGABYTES = 10;
const MEGABYTE = 1000000;
const MAX_FILE_SIZE = MAX_MEGABYTES * MEGABYTE;
const backgroundTagDocumentationSchema = yup.object({
  documentationType: yup.string().required().oneOf(['link', 'file']),
  link: yup.string().when('documentationType', {
    is: 'link',
    then: (schema) =>
      schema.required('Please enter a valid URL').url('Please enter a valid URL starting with http:// or https://'),
    otherwise: (schema) => schema.notRequired(),
  }),
  fileList: yup
    .mixed((input): input is FileList => input instanceof FileList)
    .when('documentationType', {
      is: 'file',
      then: (schema) =>
        schema
          .required('Please upload a file')
          .test({
            test: ({ [0]: file }) => Boolean(file && file.size > 0),
            message: 'File cannot be empty',
          })
          .test({
            test: ({ [0]: file }) => Boolean(file && file.size <= MAX_FILE_SIZE),
            message: `File must be less than ${MAX_MEGABYTES} megabytes`,
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
});

type BackgroundTagDocumentation =
  | { documentationType: 'link'; link: string; fileList: null }
  | { documentationType: 'file'; fileList: FileList; link: '' };

const BackgroundTagApplication: React.FC<{ tag: BackgroundTag; goBack: () => void }> = ({ tag, goBack }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<BackgroundTagDocumentation>({
    resolver: yupResolver(backgroundTagDocumentationSchema),
    defaultValues: { documentationType: 'link', link: '', fileList: null },
  });

  const addBackgroundTagMutation = useAddBackgroundTag(tag.id);

  const onSubmit = (formValues: BackgroundTagDocumentation) => {
    addBackgroundTagMutation.mutate(
      formValues.documentationType === 'link' ? formValues : { ...formValues, file: formValues.fileList[0] },
      { onSuccess: goBack },
    );
  };

  const renderInput = () => {
    if (watch('documentationType') === 'link')
      return (
        <>
          <Typography textAlign="left">Please provide a link to relevant documentation</Typography>
          <Box pt={2} />
          <TextField
            fullWidth
            label="LINK TO CERTIFICATION DOCUMENT(S)"
            error={Boolean(errors.link)}
            helperText={errors.link?.message}
            {...register('link')}
          />
        </>
      );

    const fileInputValue = watch('fileList');
    if (fileInputValue)
      return (
        <>
          <Box display="flex" justifyContent="center">
            <Typography display="flex" alignItems="center">
              {fileInputValue[0].name}
            </Typography>
            <IconButton onClick={() => setValue('fileList', null)}>
              <CloseIcon color="error" sx={{ cursor: 'pointer' }} />
            </IconButton>
          </Box>
          {errors.fileList && <Typography color="error">{errors.fileList.message}</Typography>}
        </>
      );

    return (
      <>
        <input
          type="file"
          id="file-input"
          hidden
          accept=".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
          {...register('fileList')}
        />
        <Button
          color={errors.fileList ? 'error' : 'primary'}
          variant="outlined"
          sx={{ px: 0, py: 0, label: { cursor: 'inherit', width: '100%' } }}
        >
          <label htmlFor="file-input">Attach file</label>
        </Button>
        <Box pt={2} />
        <Typography>Max file size: {MAX_MEGABYTES}MB</Typography>
      </>
    );
  };

  return (
    <>
      <PageNavigator isBackButtonVisible={false} />
      <Box pt={3} />
      <BackButton onClick={goBack}>Tags</BackButton>
      <Stack px={3} sx={{ form: { textAlign: 'center' } }}>
        <Box pt={1} />
        <BackgroundTagInstructions tagName={tag.name} />

        <Box pt={3} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="documentationType"
            render={({ field }) => (
              <FormControl>
                <RadioGroup row {...field}>
                  <FormControlLabel value="link" control={<Radio />} label="Link" />
                  <Box pl={5} />
                  <FormControlLabel value="file" control={<Radio />} label="Attach file" />
                </RadioGroup>
              </FormControl>
            )}
          />

          <Box pt={2} />
          {renderInput()}
          <Box pt={3} />
          <LoadingButton
            variant="contained"
            type="submit"
            disabled={watch('documentationType') === 'file' && !watch('fileList')}
            loading={addBackgroundTagMutation.isLoading}
          >
            Submit
          </LoadingButton>
        </form>
      </Stack>
    </>
  );
};

const BackgroundTagInstructions: React.FC<{ tagName: string }> = ({ tagName }) => (
  <>
    <Typography fontWeight={800} variant="h5" component="h1">
      Background Tag Application
    </Typography>
    <Box pt={1} />
    <Typography>Background Tags require review and approval before being displayed with your profile.</Typography>
    <Box pt={1} />
    <Typography>
      Please provide a link to relevant certification documentation below or attach your certification for verifcation.
    </Typography>

    <Box pt={1} />
    <Typography>Provide documentation for:</Typography>
    <Box pt={2} />
    <Typography fontWeight="bold">{tagName}</Typography>
    <Box pt={2} />
    <Typography>
      Please provide relevant documentation to certify your background in your selected area of expertise:
    </Typography>
  </>
);
export default BackgroundTagApplication;
