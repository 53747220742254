import React from 'react';
import { styled } from '@mui/material';
import { Box } from '@mui/system';
import { useActiveCall } from '../../common/http/hooks/active-call';
import Spinner from '../../common/components/Spinner/Spinner';

export const FooterContainer = styled(Box)(({ theme: { spacing, zIndex, palette } }) => ({
  justifyContent: 'center',
  position: 'sticky',
  bottom: 0,
  display: 'flex',
  width: '100%',
  padding: spacing(5),
  zIndex: zIndex.appBar,
  background: palette.background.patterned,
}));

const ActiveCallFooter: React.FC = () => {
  const { data: activeCall } = useActiveCall();

  if (!activeCall) return <Spinner />;

  return (
    <FooterContainer justifyContent="space-between">
      <Box fontSize={20} fontWeight="bold" color="white">
        Have a great call!
      </Box>
    </FooterContainer>
  );
};

export default ActiveCallFooter;
