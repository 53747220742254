import { DateTime } from 'luxon';
import * as yup from 'yup';

import { testEmail, testPhoneNumber } from '../../common/http/http-service';
import { useCurrentUser } from '../../contexts/user-context';

export type ProfileForm = Omit<
  yup.InferType<ReturnType<typeof useProfileFormSchema>>,
  'birthday' | 'secondaryLanguage' | 'newProfilePhotoSrc'
> & { birthday: Date | null; secondaryLanguage: string; newProfilePhotoSrc?: string };

// is this really necessary?
const stringLengthTest = {
  test: (name: string | undefined) => Boolean(name && name.length >= 2),
  message: 'Must be at least 2 characters',
};

const was18YearsAgo = (date: Date) => Boolean(date && DateTime.fromJSDate(date).diffNow('years').years <= -18);

export const useProfileFormSchema = () => {
  const user = useCurrentUser();

  return yup.object({
    currentProfilePhotoSrc: yup.string().nullable(),
    proposedProfilePhotoSrc: yup.string().nullable(),
    newProfilePhotoSrc: yup
      .string()
      .nullable()
      .test('is-profile-photo-required', 'Profile image is required', function (value) {
        const { currentProfilePhoto, proposedProfilePhoto } = this.parent;
        return Boolean(currentProfilePhoto || proposedProfilePhoto || value);
      }),
    firstName: yup.string().trim().required('First name is required').test(stringLengthTest),
    lastName: yup.string().trim().required('Last name is required').test(stringLengthTest),
    displayName: yup.string().trim().test(stringLengthTest),
    streetAddress1: yup.string().trim().required('Street is required').test(stringLengthTest),
    streetAddress2: yup.string().trim(),
    city: yup.string().trim().required('City is required').test(stringLengthTest),
    state: yup.string().required('State is required'),
    zip: yup
      .string()
      .trim()
      .required('ZIP code is required')
      .matches(/^\d{5}([ -]\d{4})?$/, 'Please enter a valid ZIP code'),
    timezone: yup.string().required('Timezone is required'),
    birthday: yup
      .date()
      // this is a hack that allows us to handle invalid dates (e.g. if the user hits submit with a partially-filled input)
      .transform((date: Date) => (date?.toString() === 'Invalid Date' ? null : date))
      .nullable()
      .required('Date of birth is required')
      .test({
        test: (date) => Boolean(date && was18YearsAgo(date)),
        message: 'Must be at least 18 years old to use Kindly Human',
      }),
    phoneNumber: yup
      .string()
      .required('Phone number is required')
      .transform((phoneNumber: string) => phoneNumber.replace(/[^\d]/g, ''))
      .length(10, 'Please enter a valid 10-digit phone number')
      .test({ name: 'check-phone', test: testPhoneNumber, message: 'Please enter a valid 10-digit phone number' }),
    gender: yup.string().required('Gender is required'),
    pronouns: yup
      .string()
      .nullable()
      .defined('Pronoun is required')
      .transform((pronoun) => (pronoun === 'none' ? null : pronoun)),
    race: yup
      .string()
      .nullable()
      .defined('Race is required')
      .transform((race) => (race === 'none' ? null : race)),
    spirituality: yup
      .string()
      .nullable()
      .defined('Spirituality is required')
      .transform((spirituality) => (spirituality === 'none' ? null : spirituality)),
    family: yup
      .string()
      .nullable()
      .defined('Family is required')
      .transform((family) => (family === 'none' ? null : family)),
    relationship: yup
      .string()
      .nullable()
      .defined('Relationship is required')
      .transform((relationship) => (relationship === 'none' ? null : relationship)),
    languages: yup.array().of(yup.string()).min(1, 'Must select at least one language').required(),
    howDidYouHear: yup.string().trim().max(150, 'Please limit your response to 150 characters'),
    email: yup
      .string()
      .trim()
      .required('Email is required')
      .email('Please enter a valid email address')
      .test({
        test: (email: string | undefined) => {
          if (!email) return false;
          if (email === user.emailAddress) return true;
          return testEmail(email);
        },
        message: 'Email already in use.',
      }),
  });
};
