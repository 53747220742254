import React from 'react';
import { Chip, Divider, Grid, IconButton, ListItem, Typography, useTheme } from '@mui/material';

// icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { OnboardingStatus } from '../OnboardMenuList/OnboardMenuList';

export interface OnboardMenuListItemProps {
  name: string;
  status: OnboardingStatus;
  href: string;
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
}

export const OnboardMenuListItem = ({ href, name, status }: OnboardMenuListItemProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const getStatusColor = (status: OnboardingStatus) => {
    if (status === OnboardingStatus.DONE) {
      return theme.palette.success.main;
    } else if (status === OnboardingStatus.PENDING) {
      return theme.palette.warning.main;
    } else {
      return theme.palette.error.main;
    }
  };

  return (
    <>
      <ListItem sx={{ marginTop: '.2rem', marginBottom: '.2rem' }}>
        <Grid onClick={() => navigate(href)} justifyContent="space-between" container flexWrap="nowrap">
          <Grid pl={2} item>
            <Typography color={theme.palette.text.primary} lineHeight="36px" fontWeight={600} variant="h6">
              {name}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container flexWrap="nowrap" spacing={2}>
              <Grid item>
                <Chip
                  sx={{
                    borderRadius: '8px',
                    height: '27px',
                    fontSize: '16px',
                    marginTop: '.3rem',
                    color: theme.palette.text.light,
                    backgroundColor: getStatusColor(status),
                  }}
                  label={status}
                />
              </Grid>
              <Grid mt={-0.3} item>
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </>
  );
};
