import React, { lazy, Suspense } from 'react';
import { ROUTE_PATH } from '../route-paths';
import Spinner from '../../common/components/Spinner/Spinner';
import { RouteObject } from 'react-router-dom';
import RouterWrapper from '../RouterWrapper';
import { SupportPage } from '../../pages/Support/Support';

const PeerApplicationFlow = lazy(() => import('../../pages/Apply/PeerApplicationFlow'));

const peerApplicationRoutes: RouteObject[] = [
  {
    path: ROUTE_PATH.apply.apply,
    element: (
      <Suspense fallback={<Spinner />}>
        <PeerApplicationFlow />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.support,
    element: (
      <Suspense fallback={<Spinner />}>
        <SupportPage />
      </Suspense>
    ),
  },
];

const PeerApplicationRouter: React.FC = () => (
  <RouterWrapper routes={peerApplicationRoutes} defaultPath={ROUTE_PATH.apply.apply} />
);

export default PeerApplicationRouter;
