import { Close, EditOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import find from 'lodash/fp/find';
import React, { useState } from 'react';

import Spinner from '../../common/components/Spinner/Spinner';
import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import {
  Experience,
  experienceQualityMapping,
  useExperiences,
  useUpdateExperience,
} from '../../common/http/hooks/listener-audio';
import { useMinimumExperiences } from '../../common/http/hooks/metadata';
import { ROUTE_PATH } from '../../routing/route-paths';
import { MAX_EXPERIENCE_TITLE_CHARS, useExperienceTitleForm } from './TitleExperienceInputForm';
import useMediaQuery from '@mui/material/useMediaQuery';

const ExperienceList: React.FC<{ addNewExperience: () => void; editExperience: (experience: Experience) => void }> = ({
  addNewExperience,
  editExperience,
}) => {
  const experiencesQuery = useExperiences();
  const { data: minExperiences } = useMinimumExperiences();
  const [isTitleDialogOpen, setIsTitleDialogOpen] = useState(false);
  const [editingTitleId, setEditingTitleId] = useState<number | null>(null);
  const onCloseDialog = () => {
    setIsTitleDialogOpen(false);
  };
  const small = useMediaQuery('(max-width:450px)');

  if (!experiencesQuery.data || minExperiences === undefined) return <Spinner />;

  const experienceBeingEdited = (editingTitleId && find(['id', editingTitleId], experiencesQuery.data)) || null;

  return (
    <>
      <PageNavigator />
      <Box px={3}>
        <Typography fontWeight={800} component="h1" variant="h5">
          {location.pathname === ROUTE_PATH.onboarding.experiences ? 'Share your experience' : 'My Experiences'}
        </Typography>
        <Box pt={2} />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button variant="contained" onClick={addNewExperience}>
            Add Experience
          </Button>
          <Box>
            <Typography sx={{ color: 'primary.main', fontWeight: 800, fontSize: '24px' }}>
              {experiencesQuery.data.length} of {minExperiences}
            </Typography>
            <Typography>Required</Typography>
          </Box>
        </Box>
      </Box>
      <Box pt={2} />
      <Divider />
      <Stack divider={<Divider />}>
        {experiencesQuery.data.map((experience) => {
          const { bgcolor, status } = experienceQualityMapping[experience.quality];
          return (
            <Box key={experience.id} display="flex" px={3} py={2} justifyContent="space-between" alignItems="center">
              <Box>
                <Box display="flex" alignItems="center">
                  <style></style>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: small ? '11rem' : '1' }}>
                    <Typography fontWeight={700}>{experience.title}</Typography>
                  </div>
                  <IconButton
                    onClick={() => {
                      setEditingTitleId(experience.id);
                      setIsTitleDialogOpen(true);
                    }}
                  >
                    <EditOutlined color="primary" fontSize="small" />
                  </IconButton>
                </Box>
                <Typography>
                  {experience.duration.toFormat('mm:ss')} | {experience.createdAt.toFormat('MMMM dd, yyyy')}
                </Typography>
              </Box>
              <Box textAlign="right">
                <Link
                  component="button"
                  onClick={() => {
                    editExperience(experience);
                  }}
                  fontSize="large"
                  sx={{ textDecoration: 'none' }}
                >
                  Edit
                </Link>
                <Box mt={1} />
                <Box bgcolor={bgcolor} px={1} py={0.5} color="text.light" fontWeight={700} borderRadius={2}>
                  {status}
                </Box>
              </Box>
            </Box>
          );
        })}

        {new Array(Math.max(minExperiences - experiencesQuery.data.length, 0)).fill('_').map((_, index) => (
          <Box
            component={Button}
            onClick={addNewExperience}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap={1}
            p={2}
            height="unset"
            width="unset"
            // There is truly no way to distinguish these elements, aside from their position in the array.
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <AddIcon fontSize="large" sx={{ color: 'text.primary' }} />
            <Typography sx={{ fontWeight: 700, color: 'text.primary' }}>Add experience</Typography>
          </Box>
        ))}
      </Stack>
      <Divider />
      <Dialog onClose={onCloseDialog} open={isTitleDialogOpen}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" p={3} pb={2} gap={1}>
          <DialogTitle sx={{ flexShrink: 1, p: 0 }} width="100%" color="primary.main" fontWeight={800}>
            Change Experience Title
          </DialogTitle>
          <IconButton onClick={onCloseDialog} sx={{ alignSelf: 'flex-start' }}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          {experienceBeingEdited && (
            <EditExperienceTitleForm experience={experienceBeingEdited} onSubmit={onCloseDialog} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

const EditExperienceTitleForm: React.FC<{ experience: Experience; onSubmit: () => void }> = ({
  experience,
  onSubmit,
}) => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useExperienceTitleForm(experience.title);
  const updateExperienceMutation = useUpdateExperience(experience.id);

  return (
    <form
      onSubmit={handleSubmit(({ title }) => {
        updateExperienceMutation.mutate({ title }, { onSuccess: onSubmit });
      })}
    >
      <TextField
        fullWidth
        label="Experience Title"
        inputProps={{ maxLength: MAX_EXPERIENCE_TITLE_CHARS }}
        error={Boolean(errors.title)}
        {...register('title')}
      />
      {errors.title && (
        <Typography color="error.main" fontSize="16px">
          {errors.title.message}
        </Typography>
      )}
      <Box pt={3} />
      <Box textAlign="center">
        <LoadingButton
          loading={updateExperienceMutation.isLoading}
          disabled={watch('title').trim() === experience.title}
          type="submit"
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Box>
    </form>
  );
};

export default ExperienceList;
