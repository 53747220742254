import React from 'react';
import { Button, Container, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useQuery } from 'react-query';
import { ThinkificResponse, User } from '../../common/types';
import { getThinkificUrl, getUser } from '../../common/http/http-service';
import { If } from '../../common/components/If';
import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';

function ListeningAcademy() {
  const theme = useTheme();
  const { data } = useQuery<User>('me', getUser);
  const think = useQuery<ThinkificResponse>('think', () => getThinkificUrl(data?.id ?? null), {
    enabled: !!data,
  });

  return (
    <>
      <PageNavigator />
      <Container>
        <Grid flexDirection="column" container>
          <Grid ml={3} mr={4} mb={4} item>
            <Typography fontWeight={800} variant="h5">
              Listening Academy
            </Typography>
          </Grid>
          <If value={Boolean(data)}>
            <Grid ml={3} mr={4} item>
              <Paper
                sx={{
                  borderRadius: '8px',
                  width: '180px',
                  height: '48px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor:
                    data?.listener_role?.required_training_complete ?? false
                      ? theme.palette.success.main
                      : theme.palette.warning.main,
                }}
              >
                <Typography color={theme.palette.text.light} fontWeight={800} variant="h6">
                  {data?.listener_role?.required_training_complete ?? false ? 'Completed' : 'Not Completed'}
                </Typography>
              </Paper>
            </Grid>
          </If>
          <Grid ml={3} mr={4} mt={4} item>
            <If value={!data?.listener_role?.required_training_complete}>
              <Typography sx={{ textAlign: 'left' }} variant="body1">
                You are ready to begin your official Listener training! Once you have completed all courses and quizzes
                of the Listener Academy, your certificate will be sent to the Care Team and this requirement will be
                fulfilled! You may revisit your training at any time.
              </Typography>
            </If>
            <If value={Boolean(data?.listener_role?.required_training_complete)}>
              <Typography sx={{ textAlign: 'left' }} variant="body1">
                Congratulations on completing your Listener Academy training. You can revisit your Academy Training at
                any time by selecting the button below!
              </Typography>
            </If>
          </Grid>
          <Grid alignSelf="center" mt={3} item>
            <a
              style={{ textDecoration: 'none' }}
              target="_blank"
              href={think.data?.thinkific_url ?? ''}
              rel="noreferrer"
            >
              <Button variant="contained" sx={{ width: '200px' }}>
                {data?.listener_role?.required_training_complete ?? false ? 'Revisit Training' : 'Complete Training'}
              </Button>
            </a>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ListeningAcademy;
