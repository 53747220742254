import invariant from 'tiny-invariant';
import { useQuery } from 'react-query';
import http from '../http-provider';
import { useCurrentUser } from '../../../contexts/user-context';
import { ConnectionRequest } from '../../types';

export const newConnectionsKeys = {
  all: ['newConnections'] as const,
};

const getNewConnections = async (listenerRoleId: number) => {
  const { data } = await http.get<ConnectionRequest[]>(`/connections/?listener_role_id=${listenerRoleId}&active`);
  return data;
};

export const useNewConnections = () => {
  const user = useCurrentUser();

  return useQuery(newConnectionsKeys.all, () => {
    invariant(user.listenerRole?.isListener, 'Non-listener peers cannot request connections.');

    return getNewConnections(user.listenerRole!.id);
  });
};
