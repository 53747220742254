import React from 'react';
import { useQuery } from 'react-query';
import { ApplicationMetaData } from '../../common/types';
import { getApplicationMetadata } from '../../common/http/http-service';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';

export const TermsPage = () => {
  return (
    <>
      <a id="top"></a>
      <PageNavigator />
      <Terms />
    </>
  );
};

function Terms() {
  const theme = useTheme();
  const metaData = useQuery<ApplicationMetaData>('metadata', getApplicationMetadata);

  return (
    <Container>
      <Grid flexDirection="column" justifyContent="center" alignItems="center" container>
        <Grid ml={2} mb={2} item>
          <Typography color="text.primary" fontWeight={800} variant="h5">
            Terms & Conditions and Privacy Policy
          </Typography>
        </Grid>
        <Grid item>
          <Divider />
          <Accordion elevation={0} sx={{ backgroundColor: theme.palette.common.white }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
              <Typography>Terms and Conditions of Use</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div dangerouslySetInnerHTML={{ __html: metaData.data?.data.app.TERMS_OF_SERVICE ?? '' }} />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ backgroundColor: theme.palette.common.white }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
              <Typography>Privacy Policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div dangerouslySetInnerHTML={{ __html: metaData.data?.data.app.PRIVACY_POLICY ?? '' }} />
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item mt={3} mb={5} justifySelf="center" alignSelf="center">
          <a href="#top">
            <Button variant="outlined" color="primary">
              Go Back
            </Button>
          </a>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Terms;
