import React from 'react';

import { TopNavigatorLogo, TopNavigatorWrapper } from './TopNavigator';

export const UnauthenticatedTopNavigator: React.FC = () => (
  <TopNavigatorWrapper
    sx={{
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
      minHeight: '100px',
      minWidth: '100%',
      px: 3,
    }}
  >
    <TopNavigatorLogo />
  </TopNavigatorWrapper>
);
