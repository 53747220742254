import React, { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import Spinner from '../../common/components/Spinner/Spinner';
import RouterWrapper from '../RouterWrapper';
import { ROUTE_PATH } from '../route-paths';
import { SupportPage } from '../../pages/Support/Support';

const ActiveCall = lazy(() => import('../../pages/ActiveCall/ActiveCall'));
const CrisisElevation = lazy(() => import('../../pages/CrisisElevation/CrisisElevation'));

const listenerActiveInCallRoutes: RouteObject[] = [
  {
    path: ROUTE_PATH.home.call,
    element: (
      <Suspense fallback={<Spinner />}>
        <ActiveCall />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.crisis,
    element: (
      <Suspense fallback={<Spinner />}>
        <CrisisElevation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.support,
    element: (
      <Suspense fallback={<Spinner />}>
        <SupportPage />
      </Suspense>
    ),
  },
];

const ListenerActiveInCallRouter: React.FC = () => (
  <RouterWrapper routes={listenerActiveInCallRoutes} defaultPath={ROUTE_PATH.home.call} />
);

export default ListenerActiveInCallRouter;
