import React from 'react';
import { Button } from '@mui/material';

const ToggleTagButton: React.FC<React.ComponentProps<typeof Button> & { selected: boolean }> = ({
  selected,
  ...buttonProps
}) => (
  <Button
    variant={selected ? 'contained' : 'outlined'}
    color={selected ? 'secondary' : 'primary'}
    sx={{
      width: 'unset',
      height: 'auto',
      borderRadius: 3,
      color: selected ? 'secondary.contrastText' : 'text.primary',
      border: selected ? null : '1px solid #222833',
      '&:hover': {
        border: selected ? null : '1px solid #222833',
      },
    }}
    {...buttonProps}
  />
);

export default ToggleTagButton;
