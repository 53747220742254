import React, { createRef, useEffect, useState } from 'react';
import useAudioRecorder, { RecorderControls } from './useAudioRecorder';
import { Box, Slider, Typography } from '@mui/material';
import invariant from 'tiny-invariant';
import {
  PauseIcon,
  PlayIcon,
  RecordIcon,
  RecordingIcon,
  DisabledPlayIcon,
  DisabledRecordIcon,
} from '../../../assets/icons';
import { ReRecordButton } from './ReRecordButton';
import ConfirmModal from '../ConfirmModal';

export const AudioController: React.FC<{
  recorderControls?: RecorderControls;
  setAudioBlob: (blob: Blob | null) => void;
  audioUrl: string | undefined;
}> = ({ recorderControls, setAudioBlob, audioUrl }) => {
  const audioRef = createRef<HTMLAudioElement>();
  const { startRecording, stopRecording, recordingBlob, isRecording, recordingTime, deleteRecording } =
    recorderControls ?? useAudioRecorder();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [confirmModalAction, setConfirmModalAction] = useState<any>();
  const [duration, setDuration] = useState<number | undefined>(undefined);

  const onAudioDelete = () => {
    setAudioBlob(null);
    deleteRecording();
  };

  const stopAudioRecorder: () => void = () => {
    stopRecording();
  };

  useEffect(() => {
    if (recordingBlob != null) {
      setAudioBlob(recordingBlob);
    }
  }, [recordingBlob]);

  function handlePlayPause() {
    invariant(audioRef, 'gotta have an audio reference');
    isPlaying ? audioRef.current?.pause() : audioRef.current?.play();
    setIsPlaying(!isPlaying);
  }

  function handleTimeUpdate(time: number) {
    invariant(audioRef, 'gotta have an audio reference');
    // update audio time
    setCurrentTime(time);
  }

  function formatTime(time: number | undefined) {
    if (!time) {
      return '0:00';
    }
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-evenly" alignItems="center" gap={2}>
        <ReRecordButton
          disabled={audioUrl === undefined}
          handleRerecord={() =>
            setConfirmModalAction({
              title: 'Are you sure?',
              text: 'Are you sure you want to delete and re-record this experience?  This action cannot be undone.',
              button1text: 'Yes, Delete',
              button2text: 'No, Cancel',
              close: () => setConfirmModalAction(undefined),
              confirmAction: () => {
                onAudioDelete();
                setConfirmModalAction(undefined);
              },
            })
          }
        />
        <RecordingButton
          disabled={audioUrl !== undefined}
          isRecording={isRecording}
          stopAudioRecorder={stopAudioRecorder}
          startRecording={startRecording}
        />
        <PlayPauseButton disabled={audioUrl === undefined} isPlaying={isPlaying} handlePlayPause={handlePlayPause} />
      </Box>
      <Box display="flex" justifyContent="center" pt={4}>
        {isRecording && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography fontWeight={800} color="#FF5D00">
              Recording...
            </Typography>
            <Box pt={2} />
            <Typography>{formatTime(recordingTime)}</Typography>
          </Box>
        )}
      </Box>
      <Box mt={4} display="flex" justifyContent="center">
        {audioUrl && (
          <>
            <audio
              controls
              hidden
              preload="auto"
              ref={audioRef}
              src={audioUrl}
              onLoadedMetadata={(event) => {
                // this solves the chrome infinity duration bug that they won't fix
                // https://stackoverflow.com/questions/21522036/html-audio-tag-duration-always-infinity
                if (event.currentTarget.duration === Infinity || isNaN(Number(event.currentTarget.duration))) {
                  event.currentTarget.currentTime = 0;
                }
              }}
              onLoadedData={(event) => {
                if (event.currentTarget.duration !== Infinity) {
                  setDuration(event.currentTarget.duration);
                }
                setCurrentTime(0);
              }}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onTimeUpdate={(event) => {
                handleTimeUpdate(event.currentTarget.currentTime);
                if (event.currentTarget.duration !== Infinity && !isNaN(Number(event.currentTarget.duration))) {
                  setDuration(event.currentTarget.duration);
                }
              }}
            />
            <Slider
              sx={{ width: '85%' }}
              value={currentTime}
              min={0}
              step={1}
              marks={[
                { label: formatTime(currentTime), value: 0 },
                { label: formatTime(duration), value: duration ?? 100 },
              ]}
              max={duration}
              onChange={(_, value) => {
                if (audioRef.current) {
                  audioRef.current.currentTime = value as number;
                }
                handleTimeUpdate(value as number);
              }}
            />
          </>
        )}
      </Box>
      <ConfirmModal
        title={confirmModalAction?.title}
        text={confirmModalAction?.text}
        button1text={confirmModalAction?.button1text}
        button1Color="error"
        button2text={confirmModalAction?.button2text}
        close={() => {
          confirmModalAction?.close();
        }}
        exit={() => confirmModalAction?.close()}
        confirmAction={() => {
          confirmModalAction?.confirmAction();
        }}
        openState={!!confirmModalAction?.title}
      />
    </>
  );
};

const RecordingButton: React.FC<{
  disabled: boolean;
  isRecording: boolean;
  stopAudioRecorder: () => void;
  startRecording: () => void;
}> = ({ disabled, isRecording, stopAudioRecorder, startRecording }) => {
  return disabled ? (
    <img
      style={{
        width: '8rem',
      }}
      src={DisabledRecordIcon}
      data-testid="ar_mic"
      title={isRecording ? 'Save recording' : 'Start recording'}
    />
  ) : (
    <img
      style={{ width: '8rem', color: '#FF5D00', cursor: 'pointer' }}
      src={isRecording ? RecordingIcon : RecordIcon}
      onClick={isRecording ? () => stopAudioRecorder() : startRecording}
      data-testid="ar_mic"
      title={isRecording ? 'Save recording' : 'Start recording'}
    />
  );
};

const PlayPauseButton: React.FC<{ disabled: boolean; isPlaying: boolean; handlePlayPause: () => void }> = ({
  disabled,
  isPlaying,
  handlePlayPause,
}) => {
  return disabled ? (
    <img style={{ width: '5rem' }} src={DisabledPlayIcon} role="button" alt="play-button" />
  ) : (
    <img
      style={{ width: '5rem', cursor: 'pointer' }}
      src={isPlaying ? PauseIcon : PlayIcon}
      role="button"
      alt="play-button"
      onClick={handlePlayPause}
    />
  );
};
