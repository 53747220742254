import { isAxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useCurrentUser } from '../../../contexts/user-context';
import { ProfileForm } from '../../../pages/Profile/useProfileFormSchema';
import { ListenerRole, ListenerRoleStatus, PeerState, ProfilePhoto, ProfilePhotoStatus, Tier, User } from '../../types';
import http from '../http-provider';
import { AboutMeForm } from '../../../pages/AboutMe/AboutMe';
import { SetPasswordForm } from '../../../pages/SetPassword/SetPassword';

export interface FormattedListenerRole {
  aboutMe?: string | null;
  proposedAboutMe?: string | null;
  aboutMeRejected: boolean;
  availabilityChangesAt: DateTime | null;
  availabilityChangesTo: boolean | null;
  backgroundCheckVerified: boolean;
  currentProfilePhoto: FormattedProfilePhoto | null;
  firstActiveListenerAt: string | null;
  firstActivePeerAt: string | null;
  hasRequiredExperiences: boolean;
  hasRequiredOfficeHours: boolean;
  id: number;
  isGuideCompleted: boolean;
  isListener: boolean;
  isPeerActive: boolean;
  peerStatus: ListenerRoleStatus;
  checkrId: string | null;
  profilePhotoApproved: boolean;
  profilePhotoFileUrl: string;
  profilePhotoSquareFileUrl?: string;
  proposedProfilePhoto: FormattedProfilePhoto | null;
  requiredTrainingComplete: boolean;
  state: PeerState;
  status: ListenerRoleStatus;
  stripeUserId: string | null;
  tier?: FormattedTier;
}

export interface FormattedProfilePhoto {
  fileUrl: string;
  id: number;
  status: ProfilePhotoStatus;
}

export interface FormattedTier {
  name: string;
  maxDailyOfficeHours: number;
  minOfficeHours: number;
  callEarnings: number;
  paidOfficeHoursCap: number;
  officeHoursRate: number;
  experienceListenRate: number;
  hasOfficeHours: boolean;
  maxExperiencePayouts: number;
}

export interface FormattedUser {
  id: number;
  firstName: string;
  lastName: string;
  displayName: string;
  proposedDisplayName: string;
  displayNameRejected: boolean;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zip: string;
  timezone: string;
  birthday: DateTime | null;
  phoneNumber: string;
  gender: string;
  pronouns: string;
  race: string | null;
  spirituality: string;
  family: string;
  relationship: string;
  languages: string[];
  howDidYouHear: string;
  emailAddress: string;
  isAdmin: boolean;
  isPartial: boolean;

  listenerRole: FormattedListenerRole | null;
  tier?: FormattedTier;
  needsPassword: boolean;
}

const deauthenticateUser = async () => {
  return http.post('/users/deauthenticate');
};

const fetchCurrentUser = async () => {
  if (!localStorage.getItem('token')) return null;

  try {
    const { data: user } = await http.get<User>('/users/me');
    return user;
  } catch (error) {
    if (isAxiosError(error) && error.response?.status === 403) return null;
    throw error;
  }
};

export const formatUser = ({
  id,
  first_name: firstName,
  last_name: lastName,
  display_name: displayName,
  proposed_display_name: proposedDisplayName,
  display_name_rejected: displayNameRejected,
  street_address_1: streetAddress1,
  street_address_2: streetAddress2,
  city,
  state,
  zip_code: zip,
  timezone,
  date_of_birth: birthday,
  mobile_phone: phoneNumber,
  gender,
  pronoun: pronouns,
  race,
  spirituality,
  family,
  relationship,
  languages,
  how_did_you_hear: howDidYouHear,
  email_address: emailAddress,
  is_partial: isPartial,
  access_role: accessRole,
  listener_role: listenerRole,
  needs_password: needsPassword,
}: User): FormattedUser => ({
  id,
  firstName,
  lastName,
  displayName: proposedDisplayName || displayName,
  proposedDisplayName,
  displayNameRejected,
  streetAddress1,
  streetAddress2,
  city,
  state,
  zip,
  birthday: !birthday || birthday === '0001-01-01' ? null : DateTime.fromFormat(birthday, 'yyyy-MM-dd'),
  phoneNumber: phoneNumber?.replace('+1', ''),
  gender,
  pronouns,
  race,
  spirituality,
  family,
  relationship,
  languages,
  howDidYouHear,
  emailAddress,
  isPartial,
  isAdmin: accessRole === 'administrator',
  timezone,
  listenerRole: listenerRole && formatListenerRole(listenerRole),
  needsPassword,
});

const formatListenerRole = (listenerRole: ListenerRole): FormattedListenerRole => {
  // TODO: this is imperfect, should ideally reference `can_take_calls` to match the backend
  const isListener = listenerRole.state === 'active_listener' || listenerRole.state === 'upgrading_peer';

  return {
    aboutMe: listenerRole.about_me,
    proposedAboutMe: listenerRole.proposed_about_me,
    aboutMeRejected: listenerRole.about_me_rejected,
    availabilityChangesAt: listenerRole.availability_changes_at
      ? DateTime.fromISO(listenerRole.availability_changes_at)
      : null,
    availabilityChangesTo: listenerRole.availability_changes_to,
    currentProfilePhoto: listenerRole.current_profile_photo && formatProfilePhoto(listenerRole.current_profile_photo),
    id: listenerRole.id,
    status: listenerRole.status,
    isListener,
    // TODO: this will be wrong after peer upgrades are implemented
    isGuideCompleted: isListener ? listenerRole.has_completed_listener_guide : listenerRole.has_completed_peer_guide,

    profilePhotoApproved: listenerRole.profile_photo_approved,
    profilePhotoFileUrl: listenerRole.profile_photo_file_url,
    profilePhotoSquareFileUrl: listenerRole.profile_photo_square_file_url,

    requiredTrainingComplete: listenerRole.required_training_complete,
    hasRequiredExperiences: listenerRole.has_required_experiences,
    hasRequiredOfficeHours: listenerRole.has_required_office_hours,
    backgroundCheckVerified: listenerRole.background_check_verified,
    stripeUserId: listenerRole.stripe_user_id,
    checkrId: listenerRole.checkr_id,
    firstActiveListenerAt: listenerRole.first_active_listener_at,
    peerStatus: listenerRole.peer_status,
    proposedProfilePhoto:
      listenerRole.proposed_profile_photo && formatProfilePhoto(listenerRole.proposed_profile_photo),
    isPeerActive: listenerRole.is_peer_active,
    firstActivePeerAt: listenerRole.first_active_peer_at,
    state: listenerRole.state,
    tier: listenerRole.tier && formatTier(listenerRole.tier),
  };
};

const formatTier = ({
  name,
  max_daily_office_hours: maxDailyOfficeHours,
  minimum_office_hours: minOfficeHours,
  call_earnings: callEarnings,
  paid_office_hours_cap: paidOfficeHoursCap,
  office_hour_rate: officeHoursRate,
  experience_listen_rate: experienceListenRate,
  pay_office_hours: hasOfficeHours,
  max_experience_payouts: maxExperiencePayouts,
}: Tier): FormattedTier => ({
  name,
  maxDailyOfficeHours,
  minOfficeHours,
  callEarnings,
  paidOfficeHoursCap,
  officeHoursRate,
  experienceListenRate,
  hasOfficeHours,
  maxExperiencePayouts,
});

const formatProfilePhoto = ({ file_url: fileUrl, id, status }: ProfilePhoto): FormattedProfilePhoto => ({
  fileUrl,
  id,
  status,
});

const updateUser = async (id: number, form: ProfileForm | AboutMeForm | SetPasswordForm) => {
  let requestPaylod;

  if ('proposed_about_me' in form) {
    requestPaylod = {
      listener_role: { proposed_about_me: form.proposed_about_me },
    };
  } else if ('password' in form) {
    requestPaylod = {
      password: form.password,
    };
  } else {
    form = form as ProfileForm;

    const listenerRole = {
      profile_photo_file_data: form.newProfilePhotoSrc,
    };
    requestPaylod = {
      first_name: form.firstName,
      last_name: form.lastName,
      proposed_display_name: form.displayName,
      street_address_1: form.streetAddress1,
      street_address_2: form.streetAddress2,
      city: form.city,
      state: form.state,
      zip_code: form.zip,
      timezone: form.timezone,
      date_of_birth: form.birthday && DateTime.fromJSDate(form.birthday).toFormat('yyyy-MM-dd'),
      mobile_phone: `+1${form.phoneNumber}`,
      gender: form.gender,
      pronoun: form.pronouns,
      race: form.race,
      spirituality: form.spirituality,
      family: form.family,
      relationship: form.relationship,
      languages: form.languages,
      how_did_you_hear: form.howDidYouHear,
      email_address: form.email,
      listener_role: listenerRole,
    };
  }
  const { data } = await http.put<User>(`/users/${id}`, requestPaylod);
  return data;
};

const selectUser = (user: User | null) => user && formatUser(user);

export const useCurrentUserQuery = (options?: { onSuccess?: (user: FormattedUser | null) => void }) =>
  useQuery(['currentUser'], fetchCurrentUser, { select: selectUser, ...options });

export const useUpdateUser = () => {
  const user = useCurrentUser();
  const queryClient = useQueryClient();

  return useMutation(
    async (profileData: ProfileForm | AboutMeForm | SetPasswordForm) => {
      const updatedUser = await updateUser(user.id, profileData);
      return { formattedUser: formatUser(updatedUser) };
    },
    {
      onSuccess: () => {
        // TODO: setQueryData once we're getting the full rawUser from the backend
        queryClient.invalidateQueries(['me']);
        queryClient.invalidateQueries(['currentUser']);
      },
      onError: () => {
        queryClient.invalidateQueries(['me']);
        queryClient.invalidateQueries(['currentUser']);
      },
    },
  );
};

export const useSend2FAEmail = () =>
  useMutation(async ({ email_address }: { email_address: string }) => {
    return await http.post('/users/send_mfa', { email_address }, { baseURL: process.env.REACT_APP_API_V3 });
  });

export const useAuthenticateWithToken = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ token, userId }: { token: string; userId: number }) => {
      const data = {
        token,
      };
      return await http.post(`/users/${userId}/authenticate_with_token`, data);
    },
    {
      onSuccess: (result: any) => {
        localStorage.setItem('token', result.data.authorization_token);
        queryClient.setQueryData(['currentUser'], result.data);
      },
    },
  );
};

export const useUserDeauthentication = () => useMutation(() => deauthenticateUser());
