import { useQuery } from 'react-query';
import http from '../http-provider';

interface StateOption {
  label: string;
  value: string;
}

const fetchStateOptions = async () => {
  const { data } = await http.get<StateOption[]>('/users/state_options');
  return data;
};

export const useStateOptions = () =>
  useQuery(['stateOptions'], fetchStateOptions, { staleTime: Infinity, refetchOnWindowFocus: false });
