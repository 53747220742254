import React, { useEffect } from 'react';
import { Button, Grid, Typography, TextField, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { requestResetPassword } from '../../common/http/http-service';
import { ApiError, resetPassword } from '../../common/types';
import { AxiosError } from 'axios';
import * as yup from 'yup';
// images
import logo from '../../assets/images/kindly-human-logo-2-line.svg';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import { ROUTE_PATH } from '../../routing/route-paths';

export interface ResetFormData {
  password: string;
  passwordConfirmation: string;
}

const validationSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  passwordConfirmation: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
});

export default function ForgetPassword() {
  const {
    register,
    handleSubmit,
    setError,
    trigger,
    formState: { errors, touchedFields },
  } = useForm<ResetFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  useEffect(() => {
    const token = new URLSearchParams(search).get('token');
    token === null && navigate('/login');
  }, [search, navigate]);

  const mutation = useMutation(
    (resetData: ResetFormData) => {
      const input: resetPassword = {
        token,
        password: resetData.password,
      };
      return requestResetPassword(input);
    },
    {
      onSuccess: () => {
        Toast.success('Password Reset Successful');
        navigate('/login');
      },
      onError: (err: AxiosError<ApiError>) => {
        setError('password', { message: err.response?.data.description || '' });
      },
    },
  );

  const submit = (formData: ResetFormData) => {
    mutation.mutate(formData);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        color="primary.contrastText"
        alignItems="center"
        p={2}
        pb={5}
        mb={4}
        sx={{ background: ({ palette: { background } }) => background.patterned }}
      >
        <Box width="100%" display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" component={Link} to={ROUTE_PATH.login} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
        <img src={logo} />
      </Box>
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Grid mt={3} mb={3} container spacing={3} direction="column" justifyContent="center" alignItems="center">
          <Grid mt={4} item width="90%">
            <Typography fontWeight={800} variant="h5" component="h1">
              Reset Password
            </Typography>
          </Grid>
          <Grid mt={1} item width="90%">
            <TextField
              {...register('password')}
              onChange={(...args) => {
                if (touchedFields.passwordConfirmation) trigger('passwordConfirmation');
                register('password').onChange(...args);
              }}
              fullWidth
              name="password"
              type="password"
              required
              label="Password"
              error={Boolean(errors.password)}
              variant="outlined"
              helperText={errors.password ? errors.password.message : ''}
            />
          </Grid>
          <Grid mt={1} item width="90%">
            <TextField
              {...register('passwordConfirmation')}
              fullWidth
              name="passwordConfirmation"
              required
              label="Confirm Password"
              type="password"
              error={Boolean(errors.passwordConfirmation)}
              variant="outlined"
              helperText={errors.passwordConfirmation ? errors.passwordConfirmation.message : ''}
            />
          </Grid>

          <Grid item>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
