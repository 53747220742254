import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export const MAX_EXPERIENCE_TITLE_CHARS = 25;

export interface TitleForm {
  title: string;
}

export const useExperienceTitleForm = (defaultTitle: string) =>
  useForm<TitleForm>({
    resolver: yupResolver(yup.object({ title: yup.string().trim().required('Title is required.') })),
    defaultValues: { title: defaultTitle },
  });
