import { useTheme } from '@mui/material';
import { DeleteIcon, DisabledDeleteIcon } from '../../../assets/icons';

export const ReRecordButton: React.FC<{ handleRerecord: () => void; disabled: boolean }> = ({
  handleRerecord,
  disabled,
}) => {
  const theme = useTheme();
  return disabled ? (
    <img style={{ width: '5rem' }} src={DisabledDeleteIcon} data-testid="delete" />
  ) : (
    <img style={{ width: '5rem', cursor: 'pointer' }} src={DeleteIcon} onClick={handleRerecord} data-testid="delete" />
  );
};
