import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import capitalize from 'lodash/fp/capitalize';
import values from 'lodash/fp/values';
import { DateTime, Interval } from 'luxon';
import React, { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';

import { CallToActionFooter } from '../../common/components/CallToActionFooter';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { isDayOfWeek, useSchedule, useUpdateSchedule, type Schedule } from '../../common/http/hooks/availabilities';
import { PeerState } from '../../common/types';
import { useCurrentUser } from '../../contexts/user-context';
import DaySelector from './DaySelector';
import useScheduleReducer, { isTempId, type FormValues, type FormWindow } from './useScheduleReducer';
import WindowInput from './WindowInput';

export const getWindowHours = ({ start, end }: FormWindow) =>
  start && end ? Interval.fromDateTimes(start, end).length('hours') : 0;

export const sumHours = (windows: FormWindow[]) => windows.reduce((hours, window) => hours + getWindowHours(window), 0);

const getIsFormDirty = (defaultValues: Schedule, formValues: FormValues) =>
  Object.entries(formValues).some(([day, windows]) => {
    // this check should never be true, but it type-narrows day
    if (!isDayOfWeek(day)) return true;
    if (formValues[day].length !== defaultValues[day].length) return true;
    return windows.some((formWindow) => {
      if (isTempId(formWindow.windowId) || !formWindow.start || !formWindow.end) return true;
      const defaultWindow = defaultValues[day].find((window) => window.windowId === formWindow.windowId);
      if (!defaultWindow) return true;
      return !defaultWindow.start.equals(formWindow.start) || !defaultWindow.end.equals(formWindow.end);
    });
  });

const SchedulePage: React.FC = () => {
  const { listenerRole } = useCurrentUser();
  invariant(listenerRole, 'User has no listener role.');
  const { tier } = listenerRole;
  invariant(tier, 'User has no listener tier.');
  const { maxDailyOfficeHours, minOfficeHours, paidOfficeHoursCap, hasOfficeHours } = tier;

  const [selectedDay, setSelectedDay] = useState(() => {
    const today = DateTime.now().weekdayLong.toLowerCase();
    return isDayOfWeek(today) ? today : 'monday';
  });

  const [formState, dispatch] = useScheduleReducer();

  const scheduleQuery = useSchedule();
  useEffect(() => {
    if (scheduleQuery.data) dispatch({ type: 'initialize', formValues: scheduleQuery.data });
  }, [scheduleQuery.data, dispatch]);

  const { formValues, formErrors } = formState;
  const isFormDirty = scheduleQuery.data ? getIsFormDirty(scheduleQuery.data, formValues) : false;
  const selectedDayWindows = formValues[selectedDay];
  const tooManyHoursInDayError = formErrors.day.find(({ type }) => type === 'too-many-day-hours');
  const notEnoughHoursInWeekError = formErrors.week.find(({ type }) => type === 'not-enough-week-hours');
  const tooManyHoursInWeekError = formErrors.week.find(({ type }) => type === 'too-many-week-hours');
  const canSave =
    !formState.isLoading &&
    isFormDirty &&
    !values(formErrors)
      .flat()
      .some(({ severity }) => severity === 'error');

  const updateScheduleMutation = useUpdateSchedule();

  // some TS hackiness, just saying if the form is valid we know it's ok to send it to the backend
  const isFormValidSchedule = (scheduleCandidate: FormValues): scheduleCandidate is Schedule => canSave;

  const onSave = () => {
    if (isFormValidSchedule(formValues))
      updateScheduleMutation.mutate(formValues, {
        onSuccess: () => {
          Toast.success('Your schedule has been saved.');
        },
        onError: (error) => {
          const genericError = 'There was a problem saving your schedule.';
          if (isAxiosError(error)) Toast.error(error.response?.data?.description ?? genericError);
          else Toast.error(genericError);
        },
      });
  };

  // TODO: we could extract 2 different page-components that inject the instructions string
  const getInstructions = () => {
    const activeUserStates: PeerState[] = [PeerState.active_peer, PeerState.active_listener];
    return activeUserStates.includes(listenerRole.state) ? (
      <>
        Please select the hours in which you will be available to receive calls. During your Office Hours is also a good
        time to check the Listener Lobby for any communication from the Listener Liaison!
      </>
    ) : (
      <>
        Please set the ranges of time you would like to be available to connect with Members. These times will also
        reflect your availability for scheduled calls.
      </>
    );
  };

  const getTotalHoursColor = () => {
    const hasNotEnoughHours = formErrors.week.some(({ type }) => type === 'not-enough-week-hours');
    const hasTooManyHours = formErrors.week.some(({ type }) => type === 'too-many-week-hours');
    return hasTooManyHours ? 'warning.main' : hasNotEnoughHours ? 'error.main' : 'text.primary';
  };

  const renderTotalHours = () => {
    const totalHours = sumHours(values(formValues).flat()).toFixed(1);
    const color = totalHours ? getTotalHoursColor() : 'text.primary';

    return (
      <Typography fontWeight="bold" color={color}>
        {totalHours}
      </Typography>
    );
  };

  const renderAddButton = () => {
    const isFormIncomplete = formErrors.time.some(({ type }) => type === 'missing-time');
    const isDisabled = isFormIncomplete || Boolean(tooManyHoursInDayError);

    return (
      <Box
        component="label"
        display="flex"
        alignItems="center"
        pl={1}
        sx={{ cursor: isDisabled ? null : 'pointer', opacity: isDisabled ? 0.5 : 1 }}
      >
        <IconButton
          disabled={isDisabled}
          onClick={() => {
            dispatch({ type: 'add', day: selectedDay });
          }}
        >
          <Add fontSize="large" sx={{ color: 'text.primary' }} />
        </IconButton>
        <Typography fontWeight="bold">Add window</Typography>
      </Box>
    );
  };

  return (
    <>
      <PageNavigator />

      <Box px={3}>
        <Typography component="h1" variant="h5" fontWeight={800}>
          My Schedule
        </Typography>

        <Box pt={2} />
        <Typography>{getInstructions()}</Typography>
        <Box pt={2} />
        <Box display="flex" justifyContent="space-between" gap={2}>
          <Typography fontWeight="bold">My total Office Hours</Typography>
          {renderTotalHours()}
        </Box>
        {notEnoughHoursInWeekError && (
          <Typography textAlign="right" variant="body2" color="error.main" fontWeight="bold">
            {notEnoughHoursInWeekError.message}
          </Typography>
        )}
        {tooManyHoursInWeekError && (
          <Typography textAlign="right" variant="body2" color="error.main" fontWeight="bold">
            {tooManyHoursInWeekError.message}
          </Typography>
        )}
        <Box pt={2} />

        <Box display="flex" justifyContent="center">
          <DaySelector formState={formState} selectedDay={selectedDay} setSelectedDay={setSelectedDay} />
        </Box>
        <Box pt={2} />
        <Box display="flex" justifyContent="space-between">
          <Typography fontWeight="bold">{capitalize(selectedDay)}s</Typography>
          <Typography fontWeight="bold" color={tooManyHoursInDayError ? 'error.main' : 'text.primary'}>
            {sumHours(selectedDayWindows).toFixed(1)}
          </Typography>
        </Box>
        {tooManyHoursInDayError && (
          <Typography textAlign="right" variant="body2" color="error.main" fontWeight="bold">
            {tooManyHoursInDayError.message}
          </Typography>
        )}
      </Box>

      <Box pt={2} />
      <Divider />
      <Stack divider={<Divider />}>
        {selectedDayWindows.map((window) => (
          <WindowInput
            formErrors={formErrors}
            selectedDayWindows={selectedDayWindows}
            day={selectedDay}
            window={window}
            dispatch={dispatch}
            key={window.windowId}
          />
        ))}
        <>{renderAddButton()}</>
      </Stack>
      <Divider />
      <Box pt={2} />

      {hasOfficeHours && (
        <Box px={3}>
          <Typography fontWeight="bold">Reminders</Typography>
          <Box pt={1} />
          <Box display="grid" gridTemplateColumns="1fr auto" rowGap={0.5} columnGap={2}>
            <Typography>Required hours per week</Typography>
            <Typography>{minOfficeHours.toFixed(1)}</Typography>

            <Typography>Maximum hours per day</Typography>
            <Typography>{maxDailyOfficeHours.toFixed(1)}</Typography>

            <Typography>Maximum paid hours per week</Typography>
            <Typography>{paidOfficeHoursCap.toFixed(1)}</Typography>
          </Box>
        </Box>
      )}

      <Box pt={2} />
      <Box mt="auto" />
      <CallToActionFooter>
        <LoadingButton
          onClick={onSave}
          loading={updateScheduleMutation.isLoading}
          disabled={!canSave}
          variant="contained"
        >
          Save
        </LoadingButton>
      </CallToActionFooter>
    </>
  );
};

export default SchedulePage;
