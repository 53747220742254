import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_PATH } from '../../routing/route-paths';

export const ClientOnboarding = () => {
  const pathParams: Readonly<Params<string>> = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathParams.clientId) {
      sessionStorage.setItem('clientId', pathParams.clientId);
    }
    navigate(ROUTE_PATH.landingPage, { replace: true });
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" pt={6}>
      <Box>Please Wait, you are being redirected</Box>
    </Box>
  );
};
