import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { OnboardingHomeNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { useExperiences } from '../../common/http/hooks/listener-audio';
import { useCurrentUser } from '../../contexts/user-context';
import PeerApplicationPending from './PeerApplicationPending';
import Profile from '../../pages/Profile/Profile';
import { ProfileType } from '../../pages/Profile/Profile';
import { ProgressMobileStepper } from '../../common/components/CreateAccount/ProgressMobileStepper';
import { onboardingSteps } from '../CreateAccount/CreateAccountForm';
import { ExperienceOnboarding } from '../Experiences/ExperienceOnboarding';
import { FormattedUser } from '../../common/http/hooks/user';
import { ProfilePhotoStatus } from '../../common/types';
import { SetPassword } from '../SetPassword/SetPassword';

export enum Steps {
  Password = 'set-password',
  Profile = 'profile',
  Experience = 'experience-intro',
  Pending = 'pending',
}

const PeerApplicationFlow: React.FC = () => {
  const [searchParams] = useSearchParams();
  const user = useCurrentUser();
  const experiencesQuery = useExperiences();
  const isExperienceSubmitted = Boolean(experiencesQuery.data && experiencesQuery.data.length > 0);
  const defaultStep = searchParams.get('step') as Steps | null;
  const [revisit, setRevisit] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(
    defaultStep && Object.values(Steps).includes(defaultStep) ? defaultStep : Steps.Password,
  );

  const shouldVisitPassword = (userData: FormattedUser): boolean => {
    return userData.needsPassword;
  };

  const shouldVisitProfile = (userData: FormattedUser): boolean => {
    if (revisit) {
      return (
        userData.displayNameRejected ||
        userData.listenerRole?.proposedProfilePhoto?.status === ProfilePhotoStatus.rejected
      );
    }
    // If a member comes in with an existing account to create a peer, they will already be `is_partial = false`
    // Therefore, check for a `display_name` and non-default avatar _before_ checking their partial status
    return (
      (!userData.displayName && userData.listenerRole?.proposedProfilePhoto?.status === ProfilePhotoStatus.rejected) ||
      (!userData.proposedDisplayName &&
        !userData.displayName &&
        !userData.listenerRole?.proposedProfilePhoto &&
        !userData.listenerRole?.currentProfilePhoto)
    );
  };

  const shouldVisitExperiences = (): boolean => {
    if (revisit) {
      return experiencesQuery.data?.some((experience) => experience.quality === 'revisit') ?? false;
    }
    return !isExperienceSubmitted;
  };

  // We only want to do the 'initial step' logic once (e.g. if the user reloads the page part-way through the flow).
  const gotInitialStep = useRef(false);
  useEffect(() => {
    if ((!revisit && gotInitialStep.current) || experiencesQuery.isLoading) return;
    if (shouldVisitPassword(user)) {
      setActiveStep(Steps.Password);
    } else if (shouldVisitProfile(user)) {
      setActiveStep(Steps.Profile);
    } else if (shouldVisitExperiences()) {
      setActiveStep(Steps.Experience);
    } else {
      setActiveStep(Steps.Pending);
    }
    gotInitialStep.current = true;
  }, [experiencesQuery.isLoading, revisit, isExperienceSubmitted, activeStep, user]);

  const renderActiveStep = (): JSX.Element => {
    switch (activeStep) {
      case Steps.Password:
        return (
          <>
            <Box mt={4} />
            <ProgressMobileStepper steps={onboardingSteps} stepCount={4} currentStep={1} />
            <SetPassword
              title="Set Password"
              transitionPage={() => {
                setActiveStep(Steps.Profile);
              }}
            />
          </>
        );
      case Steps.Profile:
        return (
          <>
            <Box mt={4} />
            <ProgressMobileStepper steps={onboardingSteps} stepCount={4} currentStep={2} />
            <Profile
              title="Create Profile"
              profileType={ProfileType.ONBOARDING}
              transitionPage={() => {
                setActiveStep(revisit ? Steps.Pending : Steps.Experience);
                setRevisit(false);
              }}
            />
          </>
        );

      case Steps.Experience:
        return (
          <>
            <Box mt={4} />
            <ProgressMobileStepper steps={onboardingSteps} stepCount={4} currentStep={3} />
            <ExperienceOnboarding
              transitionPage={() => {
                setActiveStep(Steps.Pending);
                setRevisit(false);
              }}
              onBack={() => setActiveStep(Steps.Profile)}
            />
          </>
        );

      case Steps.Pending:
        return <PeerApplicationPending setActiveStep={setActiveStep} setRevisit={setRevisit} />;
    }
  };

  return (
    <>
      <OnboardingHomeNavigator />
      {renderActiveStep()}
    </>
  );
};

export default PeerApplicationFlow;
