import { useQuery, useQueryClient } from 'react-query';
import http from '../http-provider';

export const featureFlagKeys = {
  featureFlags: ['featureFlags'] as const,
};
export interface FeatureFlags {
  count: number;
  data: { [key: string]: FeatureFlag };
}

export interface FeatureFlag {
  created_at: Date;
  description: string;
  enabled: boolean;
  id: number;
  key: string;
}

const getFeatureFlags = async (): Promise<FeatureFlags> => {
  const { data } = await http.get<FeatureFlags>(`/feature_flags`, { baseURL: process.env.REACT_APP_API_V3 });
  return data;
};

export enum PwaFeatures {
  PWA_KEYCLOAK_LOGIN = 'PWA_KEYCLOAK_LOGIN',
}

export const useFeatureFlag = (key: keyof typeof PwaFeatures) => {
  const queryClient = useQueryClient();
  const query = useQuery<FeatureFlags>(featureFlagKeys.featureFlags, () => getFeatureFlags(), {
    staleTime: Infinity,
    cacheTime: Infinity,
    retry: false,
  });
  return {
    ...query,
    enabled: !!query.data?.data[key]?.enabled,
    setEnabled: (newValue: Boolean) => {
      queryClient.setQueryData(['featureFlags'], {
        ...query.data,
        data: {
          ...query.data?.data,
          [key]: {
            ...query.data?.data?.[key],
            enabled: newValue,
          },
        },
      });
    },
  };
};
