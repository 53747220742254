import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import invariant from 'tiny-invariant';

import Spinner from '../common/components/Spinner/Spinner';
import { useActiveCall } from '../common/http/hooks/active-call';
import { FormattedUser, useCurrentUserQuery } from '../common/http/hooks/user';
import { UserProvider } from '../contexts/user-context';
import AuthenticatedLayout from '../layouts/AuthenticatedLayout';
import ListenerActiveInCallRouter from './routers/ListenerActiveInCallRouter';
import ListenerActiveNotInCallRouter from './routers/ListenerActiveNotInCallRouter';
import PeerActiveRouter from './routers/PeerActiveRouter';
import PeerApplicationRouter from './routers/PeerApplicationRouter';
import UnauthenticatedRouter from './routers/UnauthenticatedRouter';
import PeerUpgradingRouter from './routers/PeerUpgradingRouter';
import { AuthProvider } from '../contexts/auth-context';

const ListenerActiveRouter: React.FC = () => {
  const activeCallQuery = useActiveCall();

  if (activeCallQuery.isLoading) return <Spinner />;
  if (activeCallQuery.data) return <ListenerActiveInCallRouter />;
  return <ListenerActiveNotInCallRouter />;
};

const AuthenticatedRouter: React.FC<{ user: FormattedUser }> = ({ user }) => {
  const renderRouter = (): React.ReactElement => {
    invariant(user.listenerRole, 'Listener role is not defined on user.');

    switch (user.listenerRole.state) {
      case 'onboarding_peer':
      case 'rejected_peer':
        return <PeerApplicationRouter />;
      case 'active_peer':
        return <PeerActiveRouter />;
      case 'upgrading_peer':
        return <PeerUpgradingRouter />;
      case 'active_listener':
        return <ListenerActiveRouter />;
      default:
        throw new Error(`User is in invalid state: ${user.listenerRole.state}`);
    }
  };

  return (
    <UserProvider user={user}>
      <AuthProvider>
        <AuthenticatedLayout>{renderRouter()}</AuthenticatedLayout>
      </AuthProvider>
    </UserProvider>
  );
};

const GlobalRouter: React.FC = () => {
  const userQuery = useCurrentUserQuery();

  if (userQuery.isLoading) return <Spinner />;

  const token = localStorage.getItem('token')!;
  const router = token && userQuery.data && userQuery.data.listenerRole ? <AuthenticatedRouter user={userQuery.data} /> : <UnauthenticatedRouter />;
  const browserRouter = createBrowserRouter([{ path: '*', element: router }]);
  
  return <RouterProvider router={browserRouter} />;
};

export default GlobalRouter;
