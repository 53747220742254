import React from 'react';
import invariant from 'tiny-invariant';

import { CreateExperiencePayload, useCreateExperience } from '../../common/http/hooks/listener-audio';
import { ExperienceFlow } from './ExperienceFlow';
import axios, { AxiosError } from 'axios';
import { ApiError } from '../../common/types';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import { useRollbar } from '@rollbar/react';
import { useCurrentUser } from '../../contexts/user-context';

const AddExperienceFlow: React.FC<{ goBack: () => void; header?: React.ReactNode; transitionPage?: () => void }> = ({
  goBack,
  header,
  transitionPage,
}) => {
  const createExperienceMutation = useCreateExperience();
  const rollbar = useRollbar();
  const user = useCurrentUser();

  const onSubmit = ({ audioFile, ...payload }: CreateExperiencePayload) => {
    invariant(audioFile !== null, 'Experience audio not saved');

    createExperienceMutation.mutate(
      { ...payload, audioFile },
      {
        onSuccess: transitionPage ? transitionPage : goBack,
        onError: (error) => {
          if (axios.isAxiosError(error)) {
            Toast.error(
              'API Error: ' + ((error as AxiosError<ApiError>).response?.data.description ?? 'Request Failed'),
            );
          } else {
            Toast.error('Error: ' + ((error as Error).message ?? 'Unhandled Error'));
          }
          rollbar.debug(`[LOC-3720] experience audio upload failed user_id=${user.id}`);
        },
      },
    );
  };

  return (
    <ExperienceFlow
      header={header}
      goBack={goBack}
      onSubmit={onSubmit}
      isSubmitting={createExperienceMutation.isLoading}
      defaultValues={{ audioFile: null, audioUrl: undefined, title: '', topicTagIds: [] }}
    />
  );
};

export default AddExperienceFlow;
