import { Close } from '@mui/icons-material';
import { Box, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { DateTime, Interval } from 'luxon';
import React from 'react';

import { normalizeDateTime, type DayOfWeek } from '../../common/http/hooks/availabilities';
import { getWindowHours } from './SchedulePage';
import { FormAction, FormErrors, FormWindow, h_mm_a } from './useScheduleReducer';

interface WindowInputProps {
  formErrors: FormErrors;
  selectedDayWindows: FormWindow[];
  day: DayOfWeek;
  window: FormWindow;
  dispatch: (action: FormAction) => void;
}

const WindowInput: React.FC<WindowInputProps> = ({ formErrors, selectedDayWindows, day, window, dispatch }) => {
  const doesTimeHaveError = (timePosition: 'start' | 'end') =>
    formErrors.time.some(({ windowId, position }) => windowId === window.windowId && position === timePosition);

  const isIncompleteWindow = formErrors.time.some(
    ({ type, windowId }) => type === 'missing-time' && windowId === window.windowId,
  );

  // TODO: this might be a bad move, didn't really think about the performance implications when I started it :P
  // I was hoping disabling the invalid options would be a better UX than showing validation-errors,
  // but we might want to move this logic to a validation rule so it runs just once, instead of potentially hundreds of times per render.

  const isTimeOptionDisabled = (rawTimeOption: string, position: 'start' | 'end') => {
    let timeOption = normalizeDateTime(DateTime.fromFormat(rawTimeOption, h_mm_a));

    // if we select '12:00 AM' as an end time Option, then we need to consider that time as the same time next day
    if (position === 'end' && rawTimeOption === '12:00 AM') {
      timeOption = normalizeDateTime(DateTime.fromFormat(rawTimeOption, h_mm_a)).plus({ days: 1 });
    }

    const overlapsOtherWindow = selectedDayWindows
      .filter(({ windowId }) => windowId !== window.windowId)
      .some(({ start, end }) => {
        if (!start && !end) return false;
        const candidateWindowInterval =
          position === 'start'
            ? Interval.fromDateTimes(timeOption, window.end ?? timeOption)
            : Interval.fromDateTimes(window.start ?? timeOption, timeOption);
        // we know that either start or end has to exist, thanks to this callback's early-return.
        const existingWindowInterval = Interval.fromDateTimes(start ?? end!, end ?? start!);
        return (
          candidateWindowInterval.overlaps(existingWindowInterval) ||
          candidateWindowInterval.abutsStart(existingWindowInterval) ||
          candidateWindowInterval.abutsEnd(existingWindowInterval)
        );
      });
    const THIRTY_MINS = 1800;
    const isInvalidInterval =
      // Add validation to office hour window creation to ensure the minimum time is at least one hour by disabling 30 min's slot
      position === 'start'
        ? window.end && timeOption.toSeconds() >= window.end?.toSeconds() - THIRTY_MINS
        : window.start && timeOption.toSeconds() <= window.start.toSeconds() + THIRTY_MINS;
    return Boolean(overlapsOtherWindow || isInvalidInterval);
  };

  return (
    <Box p={2} key={window.windowId}>
      <Box display="flex" gap={2}>
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => {
              dispatch({ type: 'remove', day, windowId: window.windowId });
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <TextField
          select
          label="From"
          value={window.start?.toFormat(h_mm_a) ?? ''}
          onChange={({ target: { value } }) => {
            dispatch({ type: 'update', day, windowId: window.windowId, position: 'start', value });
          }}
          error={doesTimeHaveError('start')}
          fullWidth
        >
          {startTimeOptions.map((time) => (
            <MenuItem key={time} value={time} disabled={isTimeOptionDisabled(time, 'start')}>
              {time}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="To"
          value={window.end?.toFormat(h_mm_a) ?? ''}
          onChange={({ target: { value } }) => {
            dispatch({ type: 'update', day, windowId: window.windowId, position: 'end', value });
          }}
          error={doesTimeHaveError('end')}
          fullWidth
        >
          {endTimeOptions.map((time) => (
            <MenuItem key={time} value={time} disabled={isTimeOptionDisabled(time, 'end')}>
              {time}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box pt={1} />
      <Box display="flex" justifyContent="flex-end" alignItems="center" gap={1}>
        <Typography variant="caption">HOURS</Typography>
        <Typography fontWeight="bold">{isIncompleteWindow ? '-' : getWindowHours(window).toFixed(1)}</Typography>
      </Box>
    </Box>
  );
};

export default WindowInput;

const startTimeOptions = [
  '12:00 AM',
  '12:30 AM',
  '1:00 AM',
  '1:30 AM',
  '2:00 AM',
  '2:30 AM',
  '3:00 AM',
  '3:30 AM',
  '4:00 AM',
  '4:30 AM',
  '5:00 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
] as const;

const endTimeOptions = [
  '12:30 AM',
  '1:00 AM',
  '1:30 AM',
  '2:00 AM',
  '2:30 AM',
  '3:00 AM',
  '3:30 AM',
  '4:00 AM',
  '4:30 AM',
  '5:00 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
  '12:00 AM',
] as const;
