import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { theme } from './theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { rollbarConfig } from './common/components/RollBar/RollBar';
import { Provider } from '@rollbar/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AddToHomeScreenProvider } from './common/hooks/useAddToHomeScreenEvent';
import { ROUTE_PATH } from './routing/route-paths';
import '@kindlyhuman/component-library/dist/style.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      onError: (err: any) => {
        if (err.response?.status === 403) {
          localStorage.removeItem('token');
          window.location.href = ROUTE_PATH.landingPage;
        }
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AddToHomeScreenProvider>
    <QueryClientProvider client={queryClient}>
      {/* <If value={Boolean(process.env.REACT_APP_USE_LOCAL)}>
        <ReactQueryDevtools initialIsOpen={true} />
      </If> */}
      <Provider config={rollbarConfig}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <CssBaseline />
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </AddToHomeScreenProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

if (process.env.REACT_APP_USE_LOCAL) {
  reportWebVitals(console.log);
}
