import React from 'react';
import { Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const BackButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: '2rem',
      marginTop: '1.5rem',
      marginBottom: '1rem',
    }}
    onClick={onClick}
  >
    <ArrowBackIosIcon sx={{ color: 'primary.main', cursor: 'pointer' }} />
    <Typography sx={{ cursor: 'pointer' }} fontWeight={600} color="primary.main" component="div" variant="subtitle1">
      Back
    </Typography>
  </div>
);

export default BackButton;
