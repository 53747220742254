import React from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import { OnboardingStatus, OnboardMenuList } from './OnboardMenuList/OnboardMenuList';
import { ROUTE_PATH } from '../../routing/route-paths';
import { Link } from 'react-router-dom';
import { OnboardingNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { useCurrentUser } from '../../contexts/user-context';
import { SupportType } from '../Support/Support';
import { CallToActionFooter } from '../../common/components/CallToActionFooter';
import { useTraitTagsForListener } from '../../common/http/hooks/tags';

export interface OnboardMenuItem {
  name: string;
  status: OnboardingStatus;
  href: string;
}

export default function OnboardMenu() {
  const user = useCurrentUser();
  const traitTagsQuery = useTraitTagsForListener();

  const hasAnyTag =
    traitTagsQuery?.data?.profileTags.some((tag) => tag && tag.listenerTagId !== null) ||
    traitTagsQuery?.data?.backgroundTags.some((tag) => tag && tag.status !== 'notApplied');

  const list: OnboardMenuItem[] = [
    {
      name: 'Background Check',
      status: user.listenerRole?.backgroundCheckVerified
        ? OnboardingStatus.DONE
        : OnboardingStatus.PENDING ?? OnboardingStatus.PENDING,
      href: ROUTE_PATH.onboarding.backgroundCheck,
    },
    {
      name: 'Read Listener Guide',
      status: user.listenerRole?.isGuideCompleted ? OnboardingStatus.DONE : OnboardingStatus.PENDING,
      href: ROUTE_PATH.onboarding.guide,
    },
    {
      name: 'About Me',
      status: user.listenerRole?.aboutMe
        ? OnboardingStatus.DONE
        : user.listenerRole?.aboutMeRejected
        ? OnboardingStatus.REVISIT
        : OnboardingStatus.PENDING,
      href: ROUTE_PATH.onboarding.aboutMe,
    },
    {
      name: 'Request Tags',
      status: hasAnyTag ? OnboardingStatus.DONE : OnboardingStatus.PENDING,
      href: ROUTE_PATH.onboarding.tags,
    },
    {
      name: 'Listening Academy',
      status: user.listenerRole?.requiredTrainingComplete
        ? OnboardingStatus.DONE
        : OnboardingStatus.PENDING ?? OnboardingStatus.PENDING,
      href: ROUTE_PATH.onboarding.listenerAcademy,
    },
    {
      name: 'Set Office Hours',
      status: user.listenerRole?.hasRequiredOfficeHours
        ? OnboardingStatus.DONE
        : OnboardingStatus.PENDING ?? OnboardingStatus.PENDING,
      href: ROUTE_PATH.onboarding.schedule,
    },
  ];

  return (
    <>
      <OnboardingNavigator />
      <Container sx={{ backgroundColor: 'common.white', marginBottom: 'auto', padding: 0 }}>
        <Grid container mt={2}>
          <Grid mt={2} mb={1} pl={4} pb={1} pr={4} item>
            <Typography mb={2} fontWeight={800} variant="h5">
              Onboarding Hub
            </Typography>
            <Typography variant="body1">
              Once ALL below requirements are marked as “Done”, your profile will begin surfacing to Members!
            </Typography>
          </Grid>
          <Grid width="100%" item>
            <OnboardMenuList onboardingList={list} />
          </Grid>
        </Grid>
      </Container>
      <CallToActionFooter>
        <Button
          component={Link}
          to={ROUTE_PATH.support}
          state={{
            type: user.listenerRole?.isListener ? SupportType.LISTENER_ONBOARDING : SupportType.PEER_ONBOARDING,
          }}
          variant="contained"
        >
          Contact Us
        </Button>
      </CallToActionFooter>
    </>
  );
}
