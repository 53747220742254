export interface AdministratorRole {
  type: string;
}

export enum ListenerRoleStatus {
  'enabled' = 'enabled',
  'disabled' = 'disabled',
}
export enum ProfilePhotoStatus {
  'pending' = 'pending',
  'approved' = 'approved',
  'rejected' = 'rejected',
}
export enum PeerState {
  'onboarding_peer' = 'onboarding_peer',
  'rejected_peer' = 'rejected_peer',
  'active_peer' = 'active_peer',
  'upgrading_peer' = 'upgrading_peer',
  'active_listener' = 'active_listener',
}

export interface ListenerRole {
  about_me?: string | null;
  proposed_about_me?: string | null;
  about_me_rejected: boolean;
  is_peer_active: boolean;
  peer_status: ListenerRoleStatus;
  availability_changes_at: string | null;
  availability_changes_to: boolean | null;
  available: boolean;
  background_check_fee_collected: boolean | null;
  background_check_reward_applied: boolean | null;
  background_check_verified: boolean;
  calls_succeeded: number;
  checkr_id: string | null;
  can_take_calls: boolean;
  completed_training: any;
  connection_blocked: boolean;
  connection_passes: number;
  connection_requests: number;
  current_profile_photo: ProfilePhoto | null;
  has_completed_listener_guide: boolean;
  has_completed_peer_guide: boolean;
  has_required_story: boolean;
  has_required_experiences: boolean;
  has_required_office_hours: boolean;
  stripe_user_id: null | string;
  created_at: string;
  first_active_listener_at: string | null;
  first_active_peer_at: string | null;
  id: number;
  is_active: boolean;
  is_reviewable: boolean;
  last_tier_evaluation_at: string;
  military_tag: boolean | null;
  notifications_caller_online: boolean;
  notifications_incoming_call: boolean;
  notifications_resources: boolean;
  premier_listener: boolean;
  profile_photo_approved: boolean;
  profile_photo_file_url: string;
  profile_photo_modified_at: string;
  profile_photo_square_file_url: string;
  profile_traits: string[];
  proposed_profile_photo: ProfilePhoto | null;
  required_training_complete: boolean;
  share_link_url: string;
  spirituality: string;
  spirituality_tag: string;
  state: PeerState;
  status: ListenerRoleStatus;
  stripe_connect_state_token: string | null;
  stripe_customer_id: number | null;
  stripe_payment_intent_id: number | null;
  stripe_payment_method_id: string | null;
  tier: Tier | undefined;
  tier_id: number;
  tier_version: number;
  type: string;
}

export interface ProfilePhoto {
  file_url: string;
  id: number;
  status: ProfilePhotoStatus;
}

export interface Tier {
  experience_listen_rate: number;
  impact_score_required: number;
  tier_version: number;
  office_hour_rate: number;
  rate: number;
  training_required: number;
  availability_required: number;
  status: string;
  minimum_office_hours: number;
  name: string;
  id: number;
  max_weekly_office_hours?: number;
  is_default: boolean;
  description: string;
  pay_office_hours: boolean;
  calls_required: number;
  max_daily_office_hours: number;
  paid_office_hours_cap: number;
  listener_share: number;
  call_earnings: number;
  sort_weight: number;
  call_bonus: number;
  experience_bonus: number;
  max_experience_payouts: number;
}

export interface User {
  access_role: string | null;
  administrator_role: AdministratorRole | null;
  administrator_role_id: number | null;
  api_key: string;
  authorization_token: string;
  caller_role_id: number | null;
  city: string;
  client_administrator_role: null;
  client_administrator_role_id: null;
  counseling_configuration: null;
  created_at: string;
  current_app_version: string | null;
  date_of_birth: string;
  dialcare_processing_date?: string;
  display_name: string;
  proposed_display_name: string;
  display_name_rejected: boolean;
  email_address: string;
  family: string;
  first_name: string;
  gender: string;
  hide_payment_tiles: boolean;
  how_did_you_hear: string;
  id: number;
  pronoun: string;
  is_partial: boolean;
  is_text_compatible_phone: boolean;
  languages: string[];
  last_active_at: string;
  last_name: string;
  listener_role: ListenerRole | null;
  listener_role_id: number;
  military_branch: null;
  military_role: null;
  mobile_phone: string;
  mobile_phone_verification_started_at: string | null;
  mobile_phone_verification_token: string | null;
  mobile_phone_verified: boolean;
  needs_password: boolean;
  password_reset_token: string | null;
  password_reset_token_created_at: string | null;
  race: string;
  relationship: string;
  relationship_type: string;
  spirituality: string;
  state: string;
  status: string;
  street_address_1: string;
  street_address_2: string;
  timezone: string;
  user_hash: string;
  user_id_hash_android: string;
  user_id_hash_ios: string;
  user_id_hash_web: string;
  zip_code: string;
}

export interface ApiError {
  description: string;
  error_code: string;
}

export interface ApplicationMetaData {
  data: {
    app: MetaDataProperties;
  };
}

export interface MetaDataProperties {
  LOC_LISTENER_BACKGROUND_CHECK_FEE: number;
  LOC_LISTENER_BACKGROUND_CHECK_REWARD: number;
  LOC_DEFAULT_CLIENT_LOGO: string;
  LOC_CALL_CENTS_PER_MINUTE: number;
  LOC_LISTENER_DISCOVERY_MAX_DISPLAY: number;
  LOC_MINIMUM_LISTENER_EXPERIENCES: number;
  LOC_MINIMUM_PEER_EXPERIENCES: number;
  TERMS_OF_SERVICE: string;
  CLIENT_ADMIN_TERMS_OF_SERVICE: string;
  PRIVACY_POLICY: string;
  ABOUT_LOC: string;
  LISTENER_FAQ: string;
  PEER_FAQ: string;
  timezone_offsets: any; // TODO: how to make this object
  supported_timezones: string[];
  supported_races: Object;
  supported_pronouns: Object;
  DEFAULT_MORPHII_METADATA: MorphiiMetadata;
}

export interface Resource {
  id: number;
  name: string;
  description: string;
  phone_number: string;
  url: string;
  url_description: string;
  text_number: string;
  status: object;
  notes: string;
  sort_weight: number;
  identifier: string;
  client_id: number;
  image_file_path: string;
}

export interface MorphiiMetadata {
  id: string;
  label: string;
  delighted: {
    polarity: number;
  };
  disappointed: {
    polarity: number;
  };
}

export interface StateOption {
  label: string;
  value: string;
}

export interface Object {
  [key: string]: string;
}

export interface ThinkificResponse {
  thinkific_url: string;
}

export interface ConnectionRequest {
  status: string;
  tier_id: number;
  listener_acknowledged: boolean;
  expired: boolean;
  request: {
    perform_backup_requests: boolean;
    caller_role_id: number;
    audio_file_url: string | null;
    audio_duration: number | null;
    call_units_length: number | null;
    topic: {
      audio_file_url: string;
      name: string;
      morphii: [number];
      id: number;
      theme: string;
      morphii_metadata: MorphiiMetadata[];
      is_shadow_topic: boolean;
    };
    original_backup_request_id: number | null;
    original_request_id: number | null;
    call_id: number | null;
    call: any;
    topic_id: number | null;
    id: number;
    caller_role: {
      status: string;
      notes: string;
      stripe_customer_id: string;
      stripe_payment_method_id: number | null;
      user: {
        first_name: string;
        id: number;
        display_name: string;
        last_name: string;
        pronoun: string | null;
      };
      stripe_payment_failed: boolean;
      id: number;
      is_call_units: boolean | null;
      notifications_incoming_call: boolean;
      stripe_payment_failure_code: string | null;
      created_at: string;
      notifications_listener_online: boolean;
      type: string;
      notifications_check_ins: boolean;
      notifications_resources: boolean;
    };
    connections_processed_at: string | null;
    text: string;
    is_backup_request: boolean;
    status: string;
    partner_code: null;
    updated_at: string | null;
    created_at: string;
    call_limit: null;
  };
  updated_at: null;
  id: number;
  scheduled_at: string | null;
  listener_role_id: number;
  evaluation_ends_at: string | null;
  offered_at: string | null;
  request_id: number;
  created_at: string;
}

export interface CallRequest {
  request: {
    status: string;
    perform_backup_requests: boolean;
    caller_role_id: number;
    connections_processed_at: string | null;
    text: string | null;
    audio_file_url: string | null;
    topic_id: number;
    updated_at: string | null;
    id: number;
    call_id: number;
    original_backup_request_id: number | null;
    is_backup_request: boolean;
    original_request_id: number | null;
    scheduled_at: string | null;
    created_at: string;
  };
  caller_role: {
    id: number;
    user: {
      display_name: string;
      mobile_phone: string;
      pronoun: string | null;
      first_name: string;
      email_address: string;
      id: number;
      last_name: string;
    };
  };
  state: {
    // TODO: be more specific about possible state-type values
    type: string;
  };
  ended_at: string | null;
  created_at: string;
  phone_number: string | null;
  crisis_id: number | null;
}

export interface StripeResponse {
  stripe_url: string;
}

declare global {
  interface Window {
    branch: any;
  }
}

export interface resetPassword {
  token: string | null;
  password: string;
}

export interface ListenersAudio {
  duration: number;
  message_quality: number;
  excerpt: any;
  listener_role_id: number;
  id: number;
  topic_tags: TopicTag[];
  file_url: string;
  created_at: string;
  title: string;
}

export enum TagStatus {
  'APPROVED' = 'APPROVED',
  'REJECTED' = 'REJECTED',
  'UNGRADED' = 'UNGRADED',
}

export interface TopicTag {
  tag_id: number;
  status: TagStatus;
  listener_audio_id: number;
  media: Medium[];
  tag: Tag;
  listener_role_id: any;
  id: number;
  sort_order: number;
  created_at: string;
  postcall_metadata_id: any;
}

// TODO: use a more reasonable interface-inheritance pattern here
export type ListenerTag = Omit<TopicTag, 'listener_audio_id' | 'media' | 'tag'> & { tag: Omit<Tag, 'media'> };

export interface Tag {
  tag_type: string;
  is_visible: boolean;
  is_required: boolean;
  media: Medium[];
  id: number;
  sort_order: number;
  is_default: boolean;
  name: string;
  status?: boolean;
}

export interface Medium {
  file_key: string;
  file_url: string;
}
