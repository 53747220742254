import React, { useState } from 'react';

import { Experience } from '../../common/http/hooks/listener-audio';
import ExperienceList from './ExperienceList';
import { Box } from '@mui/system';
import Step, { StepStatus } from '../../common/components/Step';
import AddExperienceFlow from './AddExperienceFlow';
import EditExperienceFlow from './EditExperienceFlow';

enum ExperienceFlowName {
  List = 'list',
  Add = 'add',
  Edit = 'edit',
}

type ExperienceFlow =
  | { name: ExperienceFlowName.List }
  | { name: ExperienceFlowName.Add }
  | { name: ExperienceFlowName.Edit; experience: Experience };

const Experiences: React.FC = () => {
  const [activeFlow, setActiveFlow] = useState<ExperienceFlow>({ name: ExperienceFlowName.List });

  switch (activeFlow.name) {
    case ExperienceFlowName.List:
      return (
        <ExperienceList
          addNewExperience={() => {
            setActiveFlow({ name: ExperienceFlowName.Add });
          }}
          editExperience={(experience) => {
            setActiveFlow({ name: ExperienceFlowName.Edit, experience });
          }}
        />
      );
    case ExperienceFlowName.Add:
      return (
        <AddExperienceFlow
          goBack={() => {
            setActiveFlow({ name: ExperienceFlowName.List });
          }}
        />
      );
    case ExperienceFlowName.Edit:
      return (
        <EditExperienceFlow
          experience={activeFlow.experience}
          goBack={() => {
            setActiveFlow({ name: ExperienceFlowName.List });
          }}
        />
      );
  }
};

export default Experiences;

export enum ExperienceStep {
  Record = 'record',
  Tag = 'tag',
}

const steps = [ExperienceStep.Record, ExperienceStep.Tag];
const stepIndex = (step: ExperienceStep) => steps.indexOf(step);

export const AddExperienceSteps: React.FC<{
  activeStep: ExperienceStep;
  setActiveStep: (step: ExperienceStep) => void;
}> = ({ activeStep, setActiveStep }) => {
  const getStepStatus = (step: ExperienceStep) => {
    if (stepIndex(step) < stepIndex(activeStep)) return StepStatus.Completed;
    if (stepIndex(step) > stepIndex(activeStep)) return StepStatus.ToDo;
    return StepStatus.Active;
  };

  return (
    <Box display="flex" justifyContent="space-evenly" mx={4} my={2}>
      {steps.map((step, index) => (
        <Step
          status={getStepStatus(step)}
          onClick={() => {
            setActiveStep(step);
          }}
          key={step}
        >
          {index + 1}
        </Step>
      ))}
    </Box>
  );
};
