import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';

import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { CreateExperiencePayload } from '../../common/http/hooks/listener-audio';
import { ExperienceStep } from './Experiences';
import RecordStep from '../../common/components/RecordStep';
import TagStep from '../../common/components/TagStep';

interface DefaultFormValues {
  audioFile: Blob | null;
  title: string;
  topicTagIds: number[];
  audioUrl: string | undefined;
}

export type TagFilters = {
  tag_group_ids: number[];
  tag_ids: number[];
};

export const ExperienceFlow: React.FC<{
  header?: React.ReactNode;
  goBack: () => void;
  onSubmit: (payload: CreateExperiencePayload) => void;
  isSubmitting: boolean;
  defaultValues: DefaultFormValues;
  onSetExperienceAudio?: () => void;
}> = ({ goBack, onSubmit, isSubmitting, defaultValues, onSetExperienceAudio, header }) => {
  const [activeStep, setActiveStep] = useState(ExperienceStep.Record);
  const [experienceAudio, setExperienceAudio] = useState<Blob | File | null>(defaultValues.audioFile);
  const [title, setTitle] = useState(defaultValues.title);
  const [selectedTopicIds, setSelectedTopicIds] = useState(defaultValues.topicTagIds);

  const onClickSubmit = () => {
    if (experienceAudio) onSubmit({ audioFile: experienceAudio, title, topicTagIds: selectedTopicIds });
    else console.error("Can't submit without experience audio");
  };

  const getContents = (): { step: React.ReactElement } => {
    switch (activeStep) {
      case ExperienceStep.Record:
        return {
          step: (
            <RecordStep
              minDuration={45}
              goNext={() => {
                setActiveStep(ExperienceStep.Tag);
              }}
              goBack={() => {
                goBack();
              }}
              audio={experienceAudio}
              setAudio={(audio) => {
                setExperienceAudio(audio);
                onSetExperienceAudio?.();
              }}
              saveAudioTitle={(title) => {
                setTitle(title);
              }}
              audioTitle={title}
              recordTabTitle={<RecordStepTitle />}
              recordTabDescription={<RecordStepDescription />}
              uploadTabDescription={<UploadStepDescription />}
              uploadTabTitle={<UploadStepTitle />}
            />
          ),
        };
      case ExperienceStep.Tag:
        return {
          step: (
            <TagStep
              selectedTopicIds={selectedTopicIds}
              setSelectedTopicIds={setSelectedTopicIds}
              goNext={onClickSubmit}
              goBack={() => {
                setActiveStep(ExperienceStep.Record);
              }}
              isSubmitting={isSubmitting}
              title={<TagStepTitle />}
              description={<TagStepDescription />}
            />
          ),
        };
    }
  };

  const { step } = getContents();
  return (
    <>
      {header ? header : <PageNavigator isBackButtonVisible={false} />}
      <Box pt={3} />
      {step}
    </>
  );
};

const RecordStepTitle: React.FC = () => (
  <Typography color="primary.main" fontWeight={800} fontSize="24px">
    Record your experience
  </Typography>
);
const RecordStepDescription: React.FC = () => (
  <Typography>
    Press the record button and begin sharing your experience. Once you are finished, press the button again to end your
    recording. Please explain what your experience was, how you navigated the experience and what you learned.
  </Typography>
);

const UploadStepTitle: React.FC = () => (
  <Typography color="primary.main" fontWeight={800} fontSize="24px">
    Already have a recording to share?
  </Typography>
);
const UploadStepDescription: React.FC = () => <Typography>Upload your audio recording below:</Typography>;

const TagStepTitle: React.FC = () => (
  <Typography color="primary.main" fontWeight={800} fontSize="24px">
    Tag your experience
  </Typography>
);

const TagStepDescription: React.FC = () => (
  <Typography>
    Please choose the tags that most relate to this recording. These will help our Members to connect with you based on
    shared experiences!
  </Typography>
);
