import React, { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

import Spinner from '../../common/components/Spinner/Spinner';
import { SupportPage } from '../../pages/Support/Support';
import { TermsPage } from '../../pages/Terms/Terms';
import { ROUTE_PATH } from '../route-paths';
import RouterWrapper from '../RouterWrapper';

const BankingInformation = lazy(() => import('../../pages/BankingInformation/BankingInformation'));
const Experiences = lazy(() => import('../../pages/Experiences/Experiences'));
const PeerHome = lazy(() => import('../../pages/Home/PeerHome'));
const Profile = lazy(() => import('../../pages/Profile/Profile'));
const TraitTags = lazy(() => import('../../pages/Tags/TraitTags'));
const Roles = lazy(() => import('../../pages/Roles/Roles'));

export const peerActiveRoutes: RouteObject[] = [
  {
    path: ROUTE_PATH.home.home,
    element: (
      <Suspense fallback={<Spinner />}>
        <PeerHome />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.profile,
    element: (
      <Suspense fallback={<Spinner />}>
        <Profile />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.roles,
    element: (
      <Suspense fallback={<Spinner />}>
        <Roles />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.experiences,
    element: (
      <Suspense fallback={<Spinner />}>
        <Experiences />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.tags,
    element: (
      <Suspense fallback={<Spinner />}>
        <TraitTags />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.banking,
    element: (
      <Suspense fallback={<Spinner />}>
        <BankingInformation />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.home.terms,
    element: (
      <Suspense fallback={<Spinner />}>
        <TermsPage />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.support,
    element: (
      <Suspense fallback={<Spinner />}>
        <SupportPage />
      </Suspense>
    ),
  },
];

const PeerActiveRouter: React.FC = () => <RouterWrapper routes={peerActiveRoutes} defaultPath={ROUTE_PATH.home.home} />;

export default PeerActiveRouter;
