import { Grid, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../routing/route-paths';
import { theme } from '../../theme';
import { UnauthenticatedTopNavigator } from '../../common/components/TopNavigator/UnauthenticatedTopNavigator';
import { NotFoundJpeg } from '../../assets/images';
import useAuth from '../../common/hooks/useAuth';
import { useState } from 'react';

const MwaRedirect = () => {
  const navigate = useNavigate();
  const [loggedOut, setLoggedOut] = useState(false);
  const { logout, keycloak } = useAuth();

  if (keycloak?.authenticated && !loggedOut) {
    setLoggedOut(true);
    logout();
  }
    

  return (
    <>
      <UnauthenticatedTopNavigator />
      <Grid sx={{ height: '100vh' }}>
        <Grid sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid sx={{ color: theme.palette.primary.main }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={NotFoundJpeg} alt="not-found" />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6" component="div">
                It appears you are a member trying to login as a peer.
              </Typography>
              <a href={process.env.REACT_APP_CALLER_LINK}>
                <Button
                  sx={{ width: 'auto', px: 4, mt: 4 }}
                  variant="contained"
                  className="btn btn-blue h-11 w-full font-bold"
                >
                  Login as a Member
                </Button>
              </a>
            </Box>
            <Grid sx={{ display: 'flex', justifyContent: 'center', pt: 5 }}>
              <Button
                sx={{ width: 'auto', px: 4 }}
                variant="contained"
                className="btn btn-blue h-11 w-full font-bold mt-4"
                onClick={() => navigate(ROUTE_PATH.landingPage)}
              >
                Go Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MwaRedirect;
