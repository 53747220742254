import { Box, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { useSupportRequest } from '../../common/http/hooks/support';
import { FormattedUser } from '../../common/http/hooks/user';
import { ROUTE_PATH } from '../../routing/route-paths';
import { BasicSupportPayload, SupportForm } from './Support';

// dropdown with one option, then navigates to home and pops a success banner
export const ListenerDowngradeSupport: React.FC<{ user: FormattedUser }> = ({ user }) => {
  const navigate = useNavigate();
  const supportMutation = useSupportRequest();

  const submit = (formData: SupportForm) => {
    const { group, label } = JSON.parse(formData?.support_type);
    const input: BasicSupportPayload = {
      user_id: user.id,
      group: group,
      subject: label,
      context: 'peer_request',
      comments: formData.context_text.trim(),
    };
    supportMutation.mutate(input, {
      onSuccess: () => {
        Toast.success('Thank you for your message.  We will be in touch shortly regarding your query.');
        navigate(ROUTE_PATH.home.profile);
      },
    });
  };

  const items = [
    {
      label: 'Ask about Peer role',
      group: 'FEEDBACK',
    },
  ];

  return (
    <>
      <PageNavigator />
      <Box px={'1rem'}>
        <Typography fontWeight={800} variant="h6" color={'text.primary'} ml={2} mt={1} mb={3}>
          Support
        </Typography>
        <SupportForm submit={submit} menuItems={items} />
      </Box>
    </>
  );
};
