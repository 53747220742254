import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import React from 'react';

import ToggleTagButton from './ToggleTagButton';
import { useTagGroups } from '../http/hooks/tags';
import { FooterContainer } from '../../pages/ActiveCall/ActiveCallFooter';

const MAX_TOPICS = 5;

const TagStep: React.FC<{
  selectedTopicIds: number[];
  setSelectedTopicIds: (setter: (ids: number[]) => number[]) => void;
  goNext: () => void;
  goBack: () => void;
  isSubmitting: boolean;
  title: React.ReactNode;
  description: React.ReactNode;
}> = ({ selectedTopicIds, setSelectedTopicIds, goNext, isSubmitting, title, description }) => {
  const { data: tagGroup } = useTagGroups();

  const onToggleTopic = (topicId: number) => () => {
    setSelectedTopicIds((prevIds) => {
      if (prevIds.includes(topicId)) return prevIds.filter((id) => id !== topicId);
      if (prevIds.length < MAX_TOPICS) return [...prevIds, topicId];
      const [_first, ...rest] = prevIds;
      return [...rest, topicId];
    });
  };
  return (
    <>
      <Box mx={4}>
        {title}
        <Box pt={2} />
        {description}
        <Box pt={3} />
        <Box display="flex" flexWrap="wrap" gap={2}>
          {tagGroup &&
            tagGroup.map((tagGroup) => {
              return tagGroup.tags.map((tag: any) => {
                return (
                  <div key={tag.id}>
                    <ToggleTagButton
                      selected={selectedTopicIds.includes(tag.id)}
                      onClick={onToggleTopic(tag.id)}
                      key={tag.id}
                    >
                      {tag.name}
                    </ToggleTagButton>
                  </div>
                );
              });
            })}
        </Box>
      </Box>
      <Box height="100%" />
      <Box mt={4} />
      <FooterContainer>
        <LoadingButton loading={isSubmitting} onClick={goNext} variant="contained">
          Submit
        </LoadingButton>
      </FooterContainer>
    </>
  );
};

export default TagStep;
