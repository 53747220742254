import React, { useState } from 'react';

import { useExperiences } from '../../common/http/hooks/listener-audio';
import AddExperienceFlow from '../Experiences/AddExperienceFlow';
import EditExperienceFlow from '../Experiences/EditExperienceFlow';
import { Box, Button, Typography } from '@mui/material';
import { CustomAudioPlayer } from '../MyStory/CustomAudio';

import ExperienceAudio1 from '../../assets/audios/Nate_Emotional.mp4';
import ExperienceAudio2 from '../../assets/audios/Julane.mp4';
import ExperienceAudio3 from '../../assets/audios/Chelsea_Community.mp4';
import ExperienceAudio4 from '../../assets/audios/Aimee_Work.mp4';
import { ExperiencesFooterContainer } from '../../common/components/RecordStep';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const Description: React.FC<{ text: string }> = ({ text }) => (
  <Typography fontWeight={400} fontSize="18px" lineHeight="24px" letterSpacing="-0.16 px" sx={{ pt: 2 }}>
    {text}
  </Typography>
);

const Title: React.FC<{ text: string }> = ({ text }) => (
  <Typography pt={2} fontWeight={800} component="h1" variant="h5">
    {text}
  </Typography>
);

interface ExperiencesLandingContainerProps {
  header?: React.ReactNode;
  title: string;
  description: string;
  descriptionAddition?: string;
  children?: React.ReactNode;
}

export const ExperiencesLandingContainer: React.FC<ExperiencesLandingContainerProps> = ({
  title,
  description,
  descriptionAddition,
  children,
  header,
}) => {
  const [currentAudio, setCurrentAudio] = useState<string | null>(null);

  const handleAudioClick = (audioUrl: string) => {
    setCurrentAudio(audioUrl);
  };

  return (
    <>
      {header}
      <Box mx={4}>
        <Title text={title} />
        <Description text={description} />
        {descriptionAddition && <Description text={descriptionAddition} />}
        <Box pt={2} />
        {children}
        <CustomAudioPlayer
          audioUrl={ExperienceAudio1}
          title1="Peer Nate"
          title2="Emotional Support"
          isPlaying={currentAudio === ExperienceAudio1}
          onAudioClick={() => handleAudioClick(ExperienceAudio1)}
        />
        <CustomAudioPlayer
          audioUrl={ExperienceAudio2}
          title1="Peer Julane"
          title2="Health Support"
          isPlaying={currentAudio === ExperienceAudio2}
          onAudioClick={() => handleAudioClick(ExperienceAudio2)}
        />
        <CustomAudioPlayer
          audioUrl={ExperienceAudio3}
          title1="Peer Chelsea"
          title2="Community Support"
          isPlaying={currentAudio === ExperienceAudio3}
          onAudioClick={() => handleAudioClick(ExperienceAudio3)}
        />
        <CustomAudioPlayer
          audioUrl={ExperienceAudio4}
          title1="Peer Aimee"
          title2="Work Support"
          isPlaying={currentAudio === ExperienceAudio4}
          onAudioClick={() => handleAudioClick(ExperienceAudio4)}
        />
      </Box>
    </>
  );
};

enum ExperienceOnboardingState {
  Tutorial,
  Experience,
}

export const ExperienceOnboarding: React.FC<{ transitionPage: () => void; onBack: () => void }> = ({
  transitionPage,
  onBack,
}) => {
  const [pageState, setPageState] = useState(ExperienceOnboardingState.Tutorial);
  const { data: experiences } = useExperiences();

  switch (pageState) {
    case ExperienceOnboardingState.Tutorial:
      return (
        <>
          <ExperiencesLandingContainer
            title={'Share your experience'}
            description={
              'Sharing your experience helps others feel supported in their own everyday challenges. We recommend you write your story down and practice a couple of times before recording.'
            }
            descriptionAddition={
              'Listen to examples from other Peers below to get an idea of the kind of experience you can share, and check out the FAQ in the top left corner of your screen for a more tips and best practices!'
            }
          />
          <ExperiencesFooterContainer>
            <Button
              color="light"
              startIcon={<ChevronLeftIcon sx={{ color: 'text.light' }} />}
              onClick={onBack}
              variant="text"
            >
              Back
            </Button>
            <Button onClick={() => setPageState(ExperienceOnboardingState.Experience)} variant="contained">
              {experiences && experiences.length > 0 ? 'Edit' : 'Add'} Experience
            </Button>
          </ExperiencesFooterContainer>
        </>
      );
    default:
      return experiences && experiences.length > 0 ? (
        <EditExperienceFlow
          transitionPage={transitionPage}
          header={<></>}
          experience={experiences![0]}
          goBack={() => setPageState(ExperienceOnboardingState.Tutorial)}
        />
      ) : (
        <AddExperienceFlow
          transitionPage={transitionPage}
          header={<></>}
          goBack={() => setPageState(ExperienceOnboardingState.Tutorial)}
        />
      );
  }
};
