import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, useTheme, Box } from '@mui/material';
import { useQuery } from 'react-query';
import { ApplicationMetaData } from '../../common/types';
import { getApplicationMetadata } from '../../common/http/http-service';
import CloseIcon from '@mui/icons-material/Close';

export interface TACModalProps {
  close: () => void;
  open: boolean;
}

const TermsAndConditionModal = (props: TACModalProps) => {
  const { close, open } = props;
  const metaData = useQuery<ApplicationMetaData>('metadata', getApplicationMetadata);
  const theme = useTheme();

  return (
    <Dialog PaperProps={{ style: { backgroundColor: theme.palette.background.paper } }} open={open} onClose={close}>
      <DialogTitle fontWeight={800}>
        <Grid container alignItems="baseline" justifyContent="space-between">
          <Grid ml={2} mb={2} item>
            <Typography fontWeight={800} variant="h5">
              Terms & Conditions
            </Typography>
          </Grid>
          <Grid item alignSelf="right" mr={-2}>
            <IconButton onClick={close} aria-label="close">
              <CloseIcon sx={{ color: theme.palette.text.primary }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} flexDirection="column">
          <Grid mb={2} item>
            <div dangerouslySetInnerHTML={{ __html: metaData.data?.data.app.TERMS_OF_SERVICE ?? '' }} />
          </Grid>
        </Grid>
        <Box width="100%" display="flex" justifyContent="center">
          <Button variant="contained" onClick={close}>
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TermsAndConditionModal;
