import { Link } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import Toast from '../common/components/PopUpMessage/PopUpMessage';
import { useNotifications } from '../common/http/hooks/notifications';
import http from '../common/http/http-provider';
import { ROUTE_PATH } from '../routing/route-paths';
import { useAuthInterceptor } from './useAuthInterceptor';

const AuthenticatedLayout: React.FC<{ children: React.ReactElement | null }> = ({ children }) => {
  const queryClient = useQueryClient();

  useNotifications();

  useAuthInterceptor();

  // the 500 status check below re-renders the app due to having to use a href here. looking for a fix to this.
  useEffect(() => {
    const authInterceptor = http.interceptors.response.use(
      (res) => res,
      (err: AxiosError) => {
        if (err.response?.status.toString().substring(0, 1) === '5') {
          Toast.error(
            <>
              Something went wrong, please try again. If you continue to have trouble, please contact&nbsp;
              <Link color="text.light" sx={{ textDecoration: 'underline' }} href={ROUTE_PATH.support}>
                support
              </Link>
              .
            </>,
            'Oops!',
          );
        }
        return Promise.reject(err);
      },
    );

    return () => {
      http.interceptors.response.eject(authInterceptor);
    };
  }, [queryClient]);

  return children;
};

export default AuthenticatedLayout;
