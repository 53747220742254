export const ROUTE_PATH = {
  onboarding: {
    onboarding: '/onboarding',
    guide: '/onboarding/guide',
    profile: '/onboarding/profile',
    aboutMe: '/onboarding/about-me',
    tags: '/onboarding/tags',
    listenerAcademy: '/onboarding/listener-academy',
    story: '/onboarding/my-story',
    experiences: '/onboarding/experiences',
    schedule: '/onboarding/schedule',
    backgroundCheck: '/onboarding/background-check',
    banking: '/onboarding/banking',
    clientOnboardingRoute: '/onboarding/client-route/:clientId/:clientName',
  },
  apply: {
    // the process URLs are used in FormFacade. If we change them here, we have to change them there too.
    apply: '/apply',
    processListener: '/apply/process',
    processPeer: '/apply/peer/process',
  },
  home: {
    home: '/home',
    profile: '/home/profile',
    call: '/home/call',
    crisis: '/home/call/crisis/:crisisId',
    postCall: '/home/post-call/:postCallId',
    roles: '/home/roles',
    aboutMe: '/home/about-me',
    listenerAcademy: '/home/listener-academy',
    story: '/home/my-story',
    experiences: '/home/experiences',
    schedule: '/home/schedule',
    terms: '/home/terms',
    banking: '/home/banking',
    report: '/home/call/report',
    reports: '/home/reports',
    tags: '/home/tags',
    lobby: '/home/lobby',
  },
  support: '/support',
  faq: {
    listener: '/faqs/listener',
    peer: '/faqs/peer',
  },
  intro: {
    peer: '/intro/peer',
  },
  create: {
    listener: '/create/listener',
    peer: '/create/peer',
  },
  verify: '/verify',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  terms: '/terms',
  login: '/login',
  landingPage: '/',
  mwaRedirect: '/mwa',
  switchboardRedirect: '/sb',
} as const;

type NestedStringObject<T extends string> = T | { [key: string]: NestedStringObject<T> };

const getNestedStringObjectValues = <T extends string>(object: NestedStringObject<T>): T[] =>
  typeof object === 'string' ? [object] : Object.values(object).flatMap(getNestedStringObjectValues);

export const allPaths = getNestedStringObjectValues(ROUTE_PATH);
