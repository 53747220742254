import React from 'react';
import invariant from 'tiny-invariant';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';

interface AudioUploadButtonProps {
  onAudioUpload: (blob: Blob) => void;
}

const AudioUploadButton: React.FC<AudioUploadButtonProps> = ({ onAudioUpload }) => {
  const handleAudioFileChange = (file: File | undefined) => {
    invariant(file, 'No File Found.');

    onAudioUpload(file);
  };

  return (
    <>
      <form>
        <Box display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center">
          <AudioButton handleAudioFileChange={(event) => handleAudioFileChange(event.target.files?.[0])} />
        </Box>
      </form>
    </>
  );
};

const AudioButton: React.FC<{ handleAudioFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void }> = ({
  handleAudioFileChange,
}) => (
  <Button
    variant="outlined"
    sx={{ minWidth: '18rem', color: 'text.secondary', borderColor: 'text.secondary' }}
    component="label"
  >
    Upload Audio File
    <input type="file" hidden accept="audio/*" onChange={handleAudioFileChange} />
  </Button>
);

export default AudioUploadButton;
