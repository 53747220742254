import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { changePassword } from '../http/http-service';
import { ApiError } from '../types';
import { AxiosError } from 'axios';
import { If } from './If';
import Toast from './PopUpMessage/PopUpMessage';
import { useCurrentUser } from '../../contexts/user-context';

export interface ChangePasswordProps {
  openState: boolean;
  close: () => void;
}

export interface ChangePasswordData {
  current_password: string;
  new_password: string;
  confirm_new_password?: string;
}

const changePasswordSchema = yup.object().shape({
  current_password: yup.string().required('Current password is required.'),
  new_password: yup.string().required('New password is required.'),
  confirm_new_password: yup.string().oneOf([yup.ref('new_password')], 'Passwords must match.'),
});

function ChangePasswordModal(props: ChangePasswordProps) {
  const theme = useTheme();
  const user = useCurrentUser();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ChangePasswordData>({
    resolver: yupResolver(changePasswordSchema),
  });
  const [passwordError, setPasswordError] = useState(false);

  const submit = (changePasswordData: ChangePasswordData) => {
    delete changePasswordData.confirm_new_password;
    mutation.mutate(changePasswordData);
  };

  const mutation = useMutation(
    (changePasswordData: ChangePasswordData): Promise<boolean> => {
      return changePassword(user.id, changePasswordData);
    },
    {
      onSuccess: () => {
        Toast.success('Your new password has been saved.');
        props.close();
      },
      onError: (err: AxiosError<ApiError, ApiError>) => {
        if (err) setError('new_password', { message: err.response?.data.description });
      },
      onMutate: () => {
        setPasswordError(false);
      },
    },
  );

  return (
    <Dialog
      PaperProps={{ style: { backgroundColor: theme.palette.background.paper, width: '100%' } }}
      open={props.openState}
    >
      <DialogTitle fontWeight={800}>
        <Grid container alignItems="baseline" justifyContent="space-between" mb={-1}>
          <Grid item>
            <Typography variant="h6" fontWeight={800}>
              Change Password
            </Typography>
          </Grid>
          <Grid item alignSelf="right" mr={-2}>
            <IconButton onClick={props.close} aria-label="close">
              <CloseIcon sx={{ color: theme.palette.text.primary }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={handleSubmit(submit)}>
          <Grid container spacing={2} mt={-1} flexDirection="column">
            <Grid item>
              <TextField
                {...register('current_password')}
                id="current_password"
                name="current_password"
                fullWidth
                label="Current Password"
                type="password"
                error={Boolean(errors.current_password)}
                variant="outlined"
                helperText={errors.current_password ? errors.current_password.message : ''}
              />
            </Grid>
            <If value={passwordError}>
              <Grid item mt={-2}>
                <Typography color={theme.palette.error.main} variant="caption">
                  Password Incorrect
                </Typography>
              </Grid>
            </If>
            <Grid item>
              <TextField
                {...register('new_password')}
                id="new_password"
                name="new_password"
                fullWidth
                label="New Password"
                type="password"
                error={Boolean(errors.new_password)}
                variant="outlined"
                helperText={errors.new_password ? errors.new_password.message : ''}
              />
            </Grid>
            <Grid item>
              <TextField
                {...register('confirm_new_password')}
                id="confirm_new_password"
                name="confirm_new_password"
                fullWidth
                label="Confirm New Password"
                type="password"
                error={Boolean(errors.confirm_new_password)}
                variant="outlined"
                helperText={errors.confirm_new_password ? errors.confirm_new_password.message : ''}
              />
            </Grid>
            <Grid alignSelf="center" item mt={1} mb={1}>
              <Button sx={{ width: '200px' }} variant="contained" type="submit">
                Change Password
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ChangePasswordModal;
