import * as React from 'react';
import spinner from '../../../assets/images/spinner.svg';
import { Grid, useTheme } from '@mui/material';
import './style.css';

export default function Spinner() {
  const theme = useTheme();
  return (
    <Grid container position="fixed" left={0} right={0} top={0} bottom={0} sx={{ zIndex: '2000' }}>
      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        sx={{ background: 'rgba(0,0,0,0.5)' }}
      >
        <img
          src={spinner}
          width="65px"
          height="px"
          alt="spinner"
          className="clockwiseSpin loadingSpinner"
          style={{ color: theme.palette.text.light }}
        />
      </Grid>
    </Grid>
  );
}
