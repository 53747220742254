import { useQuery } from 'react-query';
import invariant from 'tiny-invariant';
import { isWordable } from '../../stringUtils';
import { ApplicationMetaData } from '../../types';
import { getApplicationMetadata } from '../http-service';
import { useCurrentUser } from '../../../contexts/user-context';

const useMetadata = <T,>({ select }: { select: (metadata: ApplicationMetaData) => T }) =>
  useQuery(['metadata'], getApplicationMetadata, { select, staleTime: Infinity, refetchOnWindowFocus: false });

export const useListenerFAQs = () =>
  useMetadata({
    select: ({
      data: {
        app: { LISTENER_FAQ: listenerFAQs },
      },
    }) => listenerFAQs,
  });

export const usePeerFAQs = () =>
  useMetadata({
    select: ({
      data: {
        app: { PEER_FAQ: peerFAQs },
      },
    }) => peerFAQs,
  });

export const useMinimumExperiences = () => {
  const user = useCurrentUser();

  return useMetadata({
    select: ({
      data: {
        app: {
          LOC_MINIMUM_LISTENER_EXPERIENCES: minListenerExperiences,
          LOC_MINIMUM_PEER_EXPERIENCES: minPeerExperiences,
        },
      },
    }) => {
      invariant(user.listenerRole, 'Listener role is not defined on user.');

      const minExperiences = user.listenerRole.isListener ? minListenerExperiences : minPeerExperiences;
      invariant(isWordable(minExperiences), `Number cannot be converted to word: ${minExperiences}`);

      return minExperiences;
    },
  });
};

export const useTimezoneOptions = () =>
  useMetadata({
    select: ({
      data: {
        app: { supported_timezones: supportedTimezones },
      },
    }) => supportedTimezones,
  });
