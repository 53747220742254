import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { ArrowBackIosRounded } from '@mui/icons-material';
import { Box } from '@mui/system';

const BackButton: React.FC<{ onClick: () => void; children?: string }> = ({ onClick, children = 'Back' }) => (
  <Grid container justifyContent="left" alignItems="center">
    <Box onClick={onClick} display="flex" alignItems="center" px={1} sx={{ cursor: 'pointer' }}>
      <IconButton>
        <ArrowBackIosRounded color="primary" />
      </IconButton>
      <Typography fontWeight={600} color="primary.main">
        {children}
      </Typography>
    </Box>
  </Grid>
);

export default BackButton;
