import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, Stack, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { useConfirmGuide, useSendGuide } from '../../common/http/hooks/guide';
import { ROUTE_PATH } from '../../routing/route-paths';
import { useCurrentUser } from '../../contexts/user-context';

interface EmailForm {
  emailAddress: string;
}

interface GuidePageLayoutProps {
  onGuideSentSuccess: () => void;
  title: React.ReactNode;
  description: React.ReactNode;
  agreement: React.ReactNode;
}

export const TitleText: React.FC<React.ComponentProps<typeof Typography>> = (props) => (
  <Typography fontWeight={800} variant="h5" width="100%" {...props} />
);

export const AgreementText = styled(Typography)({ lineHeight: '24px' });

const emailSchema = yup.object().shape({ emailAddress: yup.string().email('Must be valid email') });

const GuidePageLayout: React.FC<GuidePageLayoutProps> = ({ title, description, agreement, onGuideSentSuccess }) => {
  const nav = useNavigate();

  const user = useCurrentUser();
  const sendGuideMutation = useSendGuide();
  const confirmGuideMutation = useConfirmGuide();

  const [isGuideAccepted, setIsGuideAccepted] = useState(user.listenerRole?.isGuideCompleted ?? false);

  const { handleSubmit, setValue } = useForm<EmailForm>({
    resolver: yupResolver(emailSchema),
    defaultValues: { emailAddress: user.emailAddress ?? '' },
  });

  useEffect(() => {
    if (user.emailAddress) setValue('emailAddress', user.emailAddress);
  }, [user.emailAddress, setValue]);

  useEffect(() => {
    if (user.listenerRole?.isGuideCompleted) setIsGuideAccepted(true);
  }, [user.listenerRole?.isGuideCompleted, setValue]);

  const onSubmit = (formData: EmailForm) => {
    sendGuideMutation.mutate(formData, { onSuccess: onGuideSentSuccess });
  };

  const onConfirm = () => {
    confirmGuideMutation.mutate(undefined, {
      onSuccess: () => {
        nav(ROUTE_PATH.onboarding.onboarding);
      },
    });
  };

  return (
    <>
      <PageNavigator />
      <Box mt={2} />
      <Stack mx={4} alignItems="center">
        {title}
        <Box mt={2} />
        {description}
        <Box mt={4} />
        <Stack component="form" onSubmit={handleSubmit(onSubmit)} width="100%" alignItems="center">
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={!sendGuideMutation.isEnabled || user.emailAddress === ''}
            loading={sendGuideMutation.isLoading}
          >
            Send Guide
          </LoadingButton>
        </Stack>
        <Box mt={4} />
        <Box component="label" display="flex">
          <Checkbox
            color="primary"
            sx={{ '& .MuiSvgIcon-root': { fontSize: 60 } }}
            checked={isGuideAccepted}
            onChange={({ target: { checked } }) => setIsGuideAccepted(checked)}
          />
          <Box ml={1} />
          {agreement}
        </Box>
        <Box mt={3} />
        <LoadingButton
          onClick={onConfirm}
          variant="contained"
          disabled={!sendGuideMutation.isSuccess || !isGuideAccepted || !confirmGuideMutation.isEnabled}
          loading={confirmGuideMutation.isLoading}
        >
          Done
        </LoadingButton>
      </Stack>
    </>
  );
};

export default GuidePageLayout;
