import { alpha, Box, Button, Typography } from '@mui/material';
import get from 'lodash/fp/get';
import React from 'react';

import { DayOfWeek, daysOfWeek } from '../../common/http/hooks/availabilities';
import { sumHours } from './SchedulePage';
import type { FormState } from './useScheduleReducer';

interface DaySelectorProps {
  formState: FormState;
  selectedDay: DayOfWeek;
  setSelectedDay: (day: DayOfWeek) => void;
}

const dayButtonStyles = {
  unselected: {
    button: { bgcolor: 'background.kindlygray3' },
    weekday: { color: 'text.secondary' },
    hours: { color: 'text.primary' },
  },
  selected: {
    button: { bgcolor: 'primary.main' },
    weekday: { color: 'primary.contrastText' },
    hours: { color: 'primary.contrastText' },
  },
  error: {
    button: { bgcolor: 'error.main' },
    weekday: { color: 'error.contrastText' },
    hours: { color: 'error.contrastText' },
  },
} as const;

const DaySelector: React.FC<DaySelectorProps> = ({
  formState: { formValues, formErrors },
  selectedDay,
  setSelectedDay,
}) => {
  const allErrorsInAllDays = [...formErrors.day, ...formErrors.time];

  return (
    <Box display="grid" gridTemplateColumns={`repeat(${daysOfWeek.length}, 1fr)`} gap={1}>
      {daysOfWeek.map((day) => {
        const hasTimeError = formErrors.time.some((error) => error.day === day);
        const hasError = hasTimeError || formErrors.day.some((error) => error.day === day);

        const status = hasError ? 'error' : day === selectedDay ? 'selected' : 'unselected';
        const { button, hours, weekday } = dayButtonStyles[status];
        const opacity = status === 'unselected' && allErrorsInAllDays.length > 0 ? 0.5 : 1;

        return (
          <Button
            onClick={() => {
              setSelectedDay(day);
            }}
            color="primary"
            key={day}
            sx={{
              width: 'unset',
              minWidth: 0,
              height: 'unset',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              opacity,
              ...button,
              '&:hover': {
                bgcolor: (theme) => alpha(get(button.bgcolor, theme.palette), 0.5),
              },
            }}
          >
            <Typography fontWeight="bold" {...weekday}>
              {day.charAt(0).toUpperCase()}
            </Typography>
            <Typography variant="subtitle2" {...hours}>
              {sumHours(formValues[day]).toFixed(1)}
            </Typography>
          </Button>
        );
      })}
    </Box>
  );
};

export default DaySelector;
