import type { DayOfWeek, ScheduleWindow } from '../../common/http/hooks/availabilities';

interface DayErrorOptions {
  day: DayOfWeek;
}

interface TimeErrorOptions extends DayErrorOptions {
  windowId: ScheduleWindow['windowId'];
  position: 'start' | 'end';
}

// TODO: not sure if this should block saving-- if so, we can just change 'warning' to 'error'.
export const buildNotEnoughWeekHoursError = (minOfficeHours: number) =>
  ({
    severity: 'warning',
    type: 'not-enough-week-hours',
    message: `A minimum of ${minOfficeHours} hours are required per week`,
  } as const);
export type NotEnoughWeekHoursError = ReturnType<typeof buildNotEnoughWeekHoursError>;

export const buildTooManyWeekHoursError = (paidOfficeHoursCap: number) =>
  ({
    severity: 'warning',
    type: 'too-many-week-hours',
    message: `Do not exceed ${paidOfficeHoursCap} hours per week`,
  } as const);
export type TooManyWeekHoursError = ReturnType<typeof buildTooManyWeekHoursError>;

export const buildTooManyDayHoursError = (maxDailyOfficeHours: number, options: DayErrorOptions) =>
  ({
    severity: 'warning',
    type: 'too-many-day-hours',
    message: `Do not exceed ${maxDailyOfficeHours} hours per day`,
    ...options,
  } as const);
export type TooManyDayHoursError = ReturnType<typeof buildTooManyDayHoursError>;

export const buildMissingTimeError = (options: TimeErrorOptions) =>
  ({ severity: 'error', type: 'missing-time', ...options } as const);
export type MissingTimeError = ReturnType<typeof buildMissingTimeError>;
