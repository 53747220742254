import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import { BackToPageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { useSupportRequest } from '../../common/http/hooks/support';
import { FormattedUser } from '../../common/http/hooks/user';
import { ROUTE_PATH } from '../../routing/route-paths';
import { CallSupportPayload, SupportForm } from './Support';

export const ActiveCallSupport: React.FC<{ user: FormattedUser; callId: number }> = ({ user, callId }) => {
  const navigate = useNavigate();
  const supportMutation = useSupportRequest();

  const submit = (formData: SupportForm) => {
    const { group, label } = JSON.parse(formData?.support_type);
    const input: CallSupportPayload = {
      user_id: user.id,
      call_id: callId,
      group: group,
      subject: label,
      context: 'listener_of_call',
      comments: formData.context_text.trim(),
    };
    supportMutation.mutate(input, {
      onSuccess: () => {
        Toast.success('Thank you for your message.  We will be in touch shortly regarding your query.');
        navigate(ROUTE_PATH.onboarding.onboarding);
      },
    });
  };

  const items = [
    {
      label: 'Call Connection Issue',
      group: 'TECHNOLOGY',
    },
    {
      label: 'Block Caller',
      group: 'BLOCK',
    },
    {
      label: 'Caller Call Concern',
      group: 'REPORT USER',
    },
  ];

  return (
    <>
      <BackToPageNavigator />
      <Box px={'1rem'}>
        <Typography fontWeight={800} variant="h6" color={'text.primary'} ml={2} mt={1} mb={3}>
          Support
        </Typography>
        <SupportForm submit={submit} menuItems={items} />
      </Box>
    </>
  );
};

export const PostCallSupport: React.FC<{ user: FormattedUser; callId: number }> = ({ user, callId }) => {
  const navigate = useNavigate();
  const supportMutation = useSupportRequest();

  const submit = (formData: SupportForm) => {
    const { group, label } = JSON.parse(formData?.support_type);
    const input: CallSupportPayload = {
      user_id: user.id,
      call_id: callId,
      group: group,
      subject: label,
      context: 'listener_of_call',
      comments: formData.context_text.trim(),
    };
    supportMutation.mutate(input, {
      onSuccess: () => {
        Toast.success('Thank you for your message.  We will be in touch shortly regarding your query.');
        navigate(-1);
      },
    });
  };

  const items = [
    {
      label: 'Call Connection Issue',
      group: 'TECHNOLOGY',
    },
    {
      label: 'Block Caller',
      group: 'BLOCK',
    },
    {
      label: 'Caller Call Concern',
      group: 'REPORT USER',
    },
  ];

  return (
    <>
      <BackToPageNavigator />
      <Box px={'1rem'}>
        <Typography fontWeight={800} variant="h6" color={'text.primary'} ml={2} mt={1} mb={3}>
          Support
        </Typography>
        <SupportForm submit={submit} menuItems={items} />
      </Box>
    </>
  );
};
