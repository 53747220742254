import React, { useEffect } from 'react';
import { useAuthInterceptor } from './useAuthInterceptor';

const UnauthenticatedLayout: React.FC<{ children: React.ReactElement | null }> = ({ children }) => {
  useAuthInterceptor();

  return children;
};

export default UnauthenticatedLayout;
