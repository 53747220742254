import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button, Tab, Tabs, TextField, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';
import AudioUploadButton from './AudioUploadButton/AudioUploadButton';
import useAudioRecorder from './Audio/useAudioRecorder';
import getBlobDuration from 'get-blob-duration';
import invariant from 'tiny-invariant';
import {
  MAX_EXPERIENCE_TITLE_CHARS,
  TitleForm,
  useExperienceTitleForm,
} from '../../pages/Experiences/TitleExperienceInputForm';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AudioController } from './Audio/AudioController';

enum TabSelection {
  RECORD,
  UPLOAD,
}

const RecordStep: React.FC<{
  minDuration: number;
  goNext: () => void;
  goBack: () => void;
  // I don't like this, but the title is embedded in the page so it's this or create an entirely new component for MyStory
  canChangeTitle?: boolean;
  audio: Blob | File | null;
  setAudio: (blob: Blob | File | null) => void;
  saveAudioTitle: (title: string) => void;
  audioTitle: string;
  recordTabTitle: React.ReactNode;
  recordTabDescription: React.ReactNode;
  uploadTabTitle: React.ReactNode;
  uploadTabDescription: React.ReactNode;
}> = ({
  goNext,
  goBack,
  audio,
  setAudio,
  recordTabTitle,
  recordTabDescription,
  uploadTabDescription,
  uploadTabTitle,
  audioTitle,
  saveAudioTitle,
  minDuration,
  canChangeTitle = true,
}) => {
  const [tabSelection, setTabSelection] = useState<TabSelection>(TabSelection.RECORD);
  const [isRecordingValid, setIsRecordingValid] = useState(true);
  const recordingControls = useAudioRecorder();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useExperienceTitleForm(audioTitle);

  const onTitleChange = ({ title }: TitleForm) => {
    saveAudioTitle(title);
  };

  const onAudioUpload = (blob: Blob | null) => {
    setAudio(blob);
  };

  const handleTabChange = (_: SyntheticEvent, newValue: TabSelection) => {
    setTabSelection(newValue);
  };

  return (
    <>
      <Tabs
        value={tabSelection}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <ExperienceTab label="Record" value={TabSelection.RECORD} />
        <ExperienceTab label="Upload" value={TabSelection.UPLOAD} />
      </Tabs>
      <Box mt={4} />
      <form onChange={handleSubmit(onTitleChange)}>
        <TabPanel value={tabSelection} index={TabSelection.UPLOAD}>
          <Box mx={4}>
            {uploadTabTitle}
            <Box pt={2} />
            {uploadTabDescription}
          </Box>
          {!audio ? (
            <>
              <Box pt={4}></Box>
              <AudioUploadButton onAudioUpload={onAudioUpload} />
              <Box pt={4} />
              <AudioRequirements minDuration={minDuration} />
            </>
          ) : (
            <>
              <Box pt={6} />
              <AudioController
                audioUrl={audio ? URL.createObjectURL(audio) : undefined}
                setAudioBlob={onAudioUpload}
                recorderControls={recordingControls}
              />
              <Box pt={2} />
              <AudioErrors audio={audio} minDuration={minDuration} setIsRecordingValid={setIsRecordingValid} />
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={2}>
                {audio instanceof File && (
                  <>
                    <Box pt={2} />
                    <FileName name={audio.name} />
                  </>
                )}
              </Box>
            </>
          )}
          <Box pt={6} />
          <TitleInputSection canChangeTitle={canChangeTitle} errors={errors} register={register} />
          <Box pt={2} />
          {/* crappy hack to push the footer down. i'd like fix this but can't figure out how atm */}
          {!canChangeTitle && <Box minHeight="25vh" />}
        </TabPanel>
        <TabPanel value={tabSelection} index={TabSelection.RECORD}>
          <Box mx={4}>
            {recordTabTitle}
            <Box pt={2} />
            {recordTabDescription}
          </Box>
          <Box pt={4}></Box>
          <AudioController
            audioUrl={audio ? URL.createObjectURL(audio) : undefined}
            setAudioBlob={onAudioUpload}
            recorderControls={recordingControls}
          />
          <Box pt={2} />
          {audio ? (
            <AudioErrors audio={audio} minDuration={minDuration} setIsRecordingValid={setIsRecordingValid} />
          ) : (
            <AudioRequirements minDuration={minDuration} />
          )}
          <Box pt={3} />
          <TitleInputSection canChangeTitle={canChangeTitle} errors={errors} register={register} />
        </TabPanel>
        <Box mt={4} />
        <ExperiencesFooterContainer>
          <Button
            color="light"
            startIcon={<ChevronLeftIcon sx={{ color: 'text.light' }} />}
            onClick={goBack}
            variant="text"
          >
            Back
          </Button>
          <Button
            endIcon={<ChevronRightIcon />}
            disabled={!audio || !isRecordingValid || !isValid}
            onClick={goNext}
            variant="contained"
          >
            Accept
          </Button>
        </ExperiencesFooterContainer>
      </form>
    </>
  );
};

const FileName: React.FC<{ name: string }> = ({ name }) => (
  <Box display="flex" justifyContent="center" alignItems="center">
    <Typography>{name}</Typography>
  </Box>
);

const AudioRequirements = ({ minDuration }: { minDuration: number }) => (
  <Box textAlign="center" mt={2}>
    <Typography>Story minimum: {minDuration} seconds</Typography>
    <Typography>Story maximum: 3 minutes</Typography>
  </Box>
);

enum ExperienceAudioError {
  TOO_SHORT,
  TOO_LARGE,
  TOO_LONG,
}

const AudioErrors: React.FC<{
  minDuration: number;
  audio: Blob | File | null;
  setIsRecordingValid: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ audio, setIsRecordingValid, minDuration: minimumDuration }) => {
  const [error, setError] = useState<ExperienceAudioError>();
  const maximumDuration = 60 * 3;
  const TenMB = 10 * 1000 * 1000;

  const setNewError = (errorType: ExperienceAudioError) => {
    setIsRecordingValid(false);
    setError(errorType);
  };

  const checkValidity = (size: number, duration: number) => {
    size >= TenMB && setNewError(ExperienceAudioError.TOO_LARGE);
    duration < minimumDuration && setNewError(ExperienceAudioError.TOO_SHORT);
    duration > maximumDuration && setNewError(ExperienceAudioError.TOO_LONG);
  };

  useEffect(() => {
    invariant(audio, 'audio file required');
    getBlobDuration(audio).then((duration) => {
      setIsRecordingValid(true);
      const size = audio?.size;
      checkValidity(size, duration);
    });
  }, [audio]);

  return (
    <>
      {error === ExperienceAudioError.TOO_SHORT && <ExperienceTooShortMessage />}
      {error === ExperienceAudioError.TOO_LARGE && <ExperienceTooLargeMessage />}
      {error === ExperienceAudioError.TOO_LONG && <ExperienceTooLongMessage />}
    </>
  );
};

const ExperienceTooLargeMessage = () => (
  <Typography textAlign="center" sx={{ fontWeight: '800' }} color="error.main">
    File size too large.
  </Typography>
);

const ExperienceTooShortMessage = () => (
  <Typography textAlign="center" sx={{ fontWeight: '800' }} color="error.main">
    Your recording does not meet the minimum time requirement. Please try again.
  </Typography>
);

const ExperienceTooLongMessage = () => (
  <Typography textAlign="center" sx={{ fontWeight: '800' }} color="error.main">
    Your recording exceeds the maximum time requirement. Please try again.
  </Typography>
);

const ExperienceTitleInput: React.FC<{
  errors: FieldErrors<TitleForm>;
  register: UseFormRegister<TitleForm>;
}> = ({ errors, register }) => {
  return (
    <>
      <TextField
        sx={{ width: '89%' }}
        error={Boolean(errors.title)}
        label="EXPERIENCE TITLE"
        inputProps={{ maxLength: MAX_EXPERIENCE_TITLE_CHARS }}
        {...register('title')}
      />
      {errors.title && (
        <Typography color="error.main" pt={2} fontSize="16px">
          {errors.title.message}
        </Typography>
      )}
    </>
  );
};

export default RecordStep;

export const ExperiencesFooterContainer = styled(Box)(({ theme: { spacing, zIndex, palette } }) => ({
  justifyContent: 'space-between',
  position: 'sticky',
  bottom: 0,
  display: 'flex',
  width: '100%',
  padding: spacing(3),
  zIndex: zIndex.appBar,
  background: palette.background.patterned,
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ flex: '1 1 auto' }}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const ExperienceTab = styled(Tab)(() => ({
  '&.Mui-selected': {
    fontWeight: 800,
  },
  fontWeight: 400,
  textTransform: 'none',
  fontSize: 'large',
}));

const TitleInputSection: React.FC<{
  canChangeTitle: boolean;
  errors: FieldErrors<TitleForm>;
  register: UseFormRegister<TitleForm>;
}> = ({ canChangeTitle, errors, register }) => {
  return canChangeTitle ? (
    <>
      <Box pt={4} />
      <Box mx={4}>
        <Typography color="primary.main" fontWeight={800} fontSize="24px">
          Title your Experience
        </Typography>
        <Box pt={2} />
        <Typography>Please give a short title to sum up what this experience communicates.</Typography>
        <Box pt={4} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <ExperienceTitleInput errors={errors} register={register} />
      </Box>
    </>
  ) : null;
};
