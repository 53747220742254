import React, { useState } from 'react';

import { type BackgroundTag } from '../../common/http/hooks/tags';
import BackgroundTagApplication from './BackgroundTagApplication';
import { TraitTagsList } from './TraitTagsList';

const TraitTags = () => {
  const [addingBackgroundTag, setAddingBackgroundTag] = useState<BackgroundTag | null>(null);

  return addingBackgroundTag === null ? (
    <TraitTagsList onAddBackgroundTag={setAddingBackgroundTag} />
  ) : (
    <BackgroundTagApplication
      tag={addingBackgroundTag}
      goBack={() => {
        setAddingBackgroundTag(null);
      }}
    />
  );
};

export default TraitTags;
