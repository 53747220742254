import React, { useState } from 'react';
import { Button, Drawer, Grid, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createRoot } from 'react-dom/client';
import { theme } from '../../../theme';
const TIMEOUT = 5000;
const container = document.getElementById('popup');
const root = createRoot(container!);

interface popUpprops {
  message: string | React.ReactNode;
  properties: propertyType;
}

interface propertyType {
  background: string;
  headerText: string;
}
export const Popup = ({ message, properties }: popUpprops) => {
  const [open, setopen] = useState(false);
  const timeout = React.useRef<any>(null);

  const removePopUp = () => {
    setopen(false);
    clearTimeout(timeout.current);
    setTimeout(() => {
      root.render(null);
    }, 100);
  };

  React.useEffect(() => {
    setopen(true);
    timeout.current = setTimeout(() => {
      removePopUp();
    }, TIMEOUT);
    return () => {
      clearTimeout(timeout.current);
    };
  }, []); //eslint-disable-line

  return (
    <Drawer
      anchor="top"
      open={open}
      sx={{
        zIndex: '2000',
      }}
      onClose={removePopUp}
      variant="temporary"
    >
      <Grid sx={{ background: properties.background }}>
        <Grid display="flex" justifyContent="space-between" alignItems="center" xs={12} pl={3} pr={1} pt={3}>
          <Typography
            fontWeight={800}
            textAlign="left"
            align="left"
            fontSize="24px"
            lineHeight={1}
            color={theme.palette.text.light}
          >
            {properties.headerText}
          </Typography>
          <Button onClick={removePopUp}>
            <CloseIcon sx={{ color: theme.palette.text.light, fontSize: '30px' }} />
          </Button>
        </Grid>
        <Grid px={3} pt={1} pb={3}>
          <Typography
            color={theme.palette.text.light}
            fontWeight={700}
            fontSize="18px"
            lineHeight="24px"
            letterSpacing="-0.164727px"
            textAlign="left"
            align="left"
          >
            {message}
          </Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
};

const ToastProperties = {
  success: {
    background: '#63D577',
    headerText: 'Success!',
  },
  error: {
    background: '#EF364F',
    headerText: 'Oops!',
  },
  warning: {
    background: '#FF5D00',
    headerText: 'Oops!',
  },
};

const generatePopUp = (message: any, properties: propertyType) => {
  root.render(<Popup message={message} properties={properties} />);
};

const Toast = {
  success: (message: any, headerText = ToastProperties.success.headerText) =>
    generatePopUp(message, { ...ToastProperties.success, headerText }),
  error: (message: any, headerText = ToastProperties.error.headerText) =>
    generatePopUp(message, { ...ToastProperties.error, headerText }),
  warning: (message: any) => generatePopUp(message, ToastProperties.warning),
};

export default Toast;
