import React, { ReactNode } from 'react';

export interface IfProps {
  value: boolean;
  children: ReactNode;
}

export const If = ({ value, children }: IfProps) => {
  return <>{value && children}</>;
};
