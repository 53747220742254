import { Button } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import applyPeerImage from '../../assets/images/apply-peer-image.png';
import { ROUTE_PATH } from '../../routing/route-paths';
import { useCurrentUser } from '../../contexts/user-context';
import ApplicationPendingLayout from './ApplicationPendingLayout';
import { FooterContainer } from '../ActiveCall/ActiveCallFooter';
import { SupportType } from '../Support/Support';
import { Steps } from './PeerApplicationFlow';

const PENDING_HEADER = 'Application Submitted';
const PENDING_BODY =
  'Thank you for submitting your application to be a Peer with Kindly Human! Your application has been sent to the Care Team and will be reviewed as soon as possible.';
interface PeerApplciationPendingProps {
  setActiveStep: React.Dispatch<React.SetStateAction<Steps>>;
  setRevisit: React.Dispatch<React.SetStateAction<boolean>>;
}
const PeerApplicationPending: React.FC<PeerApplciationPendingProps> = ({ setActiveStep, setRevisit }) => {
  const user = useCurrentUser();

  return (
    <>
      <ApplicationPendingLayout
        setActiveStep={setActiveStep}
        setRevisit={setRevisit}
        imageSrc={applyPeerImage}
        headerText={PENDING_HEADER}
        bodyText={PENDING_BODY}
        peerState={user.listenerRole?.state}
      />
      <FooterContainer textAlign="center">
        <Button
          variant="contained"
          color="secondary"
          component={Link}
          to={{
            pathname: ROUTE_PATH.support,
          }}
          state={{ type: SupportType.PEER_APPLICATION_PENDING }}
        >
          Contact Us
        </Button>
      </FooterContainer>
    </>
  );
};

export default PeerApplicationPending;
