import http from './http-provider';
import { LoginFormData } from '../../pages/Login/Login';
import { ApplicationMetaData, CallRequest, ThinkificResponse, User, resetPassword, Resource } from '../types';
import { ChangePasswordData } from '../components/ChangePasswordModal';
import { StripeReportData } from '../../pages/BankingInformation/BankingInformation';

export const login = async ({ emailAddress, password, trusted }: LoginFormData) => {
  const { data } = await http.post<User>('/users/authenticate', { email_address: emailAddress, password, trusted });
  return data;
};

export const requestForgetPasswordLink = async (emailAddress: string) => {
  const { data } = await http.post<User>(
    `users/request_password_reset?email_address=${encodeURIComponent(emailAddress)}`,
  );
  return data;
};

export const requestResetPassword = async (payload: resetPassword) => {
  const { data } = await http.post<any>('users/redeem_password_reset', payload);
  return data;
};

export const getUser = async (): Promise<User> => {
  const {
    data: { mobile_phone: mobilePhone, ...data },
  } = await http.get<User>('/users/me');
  const trimmedPhone = mobilePhone.startsWith('+') ? mobilePhone.slice(2) : mobilePhone;
  return { ...data, mobile_phone: trimmedPhone };
};

export const getThinkificUrl = async (userId: number | null): Promise<ThinkificResponse> => {
  const { data } = await http.post<ThinkificResponse>(`/users/${userId}/thinkific_url`);
  return data;
};

// TODO: I don't think callId should be optional here
export const getClientResources = async (callId: number | undefined) => {
  const { data } = await http.get<Resource[]>(`/calls/${callId}/resources`);
  return data;
};

export const getApplicationMetadata = async (): Promise<ApplicationMetaData> => {
  const { data } = await http.get<ApplicationMetaData>('/settings/app');
  return data;
};

export const acceptConnection = async (connection_id: number) => {
  return await http.post<any>(`/connections/${connection_id}/offer`, {
    start_call: true,
  });
};

export const passConnection = async (connection_id: number) => {
  const { data } = await http.post<any>(`/connections/${connection_id}/pass`);
  return data;
};

export const setAvailability = async (listener_role_id: number | undefined | null, status: boolean) => {
  const { data } = await http.post<any>(
    `/availabilities/toggle?listener_role_id=${listener_role_id}&available=${status}`,
  );
  return data;
};

export const getActiveCalls = async (listener_role_id: number | undefined) => {
  if (listener_role_id !== undefined) {
    const { data } = await http.get<CallRequest[]>(`/calls/?listener_role_id=${listener_role_id}&is_active=true`);
    return data;
  }
};

export const getAudio = async (url: string) => {
  return http.get(url, {
    responseType: 'blob',
  });
};

export const endCall = async (call_id: number) => {
  await http.post(`/calls/${call_id}/end`);
};

export const changePassword = async (userId: number | undefined, changePasswordData: ChangePasswordData) => {
  const { data } = await http.post<boolean>(`/users/${userId}/change_password`, changePasswordData);
  return data;
};

export const getStripeUrl = async (userId: number | null): Promise<string> => {
  const { data } = await http.post<string>(`/listeners/${userId}/stripe_dashboard_url`);
  return data;
};

export const getStripeReport = async (userId: number | null): Promise<StripeReportData> => {
  const { data } = await http.get<StripeReportData>(`/users/${userId}/stripe_report`);
  return data;
};
const phoneNumberCache: { [key: string]: boolean } = {};

export const testPhoneNumber = async (value?: string): Promise<boolean> => {
  if (!value || value.length !== 10) return false;

  // Check the cache first
  if (phoneNumberCache[value] !== undefined) {
    return phoneNumberCache[value];
  }

  try {
    await http.get<boolean>('/users/mobile_phone_valid', {
      params: { mobile_phone: value },
    });
    phoneNumberCache[value] = true; // Cache the result
    return true;
  } catch (error: any) {
    if (error.response && error.response.status === 429) {
      // Do not cache the result if the status code is 429
      return false;
    }
    phoneNumberCache[value] = false; // Cache the result
    return false;
  }
};

export const testEmail = async (email: string | undefined): Promise<boolean> => {
  if (!email) return false;
  try {
    const { data } = await http.get<boolean>('/users/email_address_available', {
      params: { email_address: email },
    });
    return data;
  } catch {
    return false;
  }
};

export const getTopicsTags = async (params: any) => {
  const { data } = await http.get<any>('/tags/', { params });
  return data;
};

export const postListenerAudio = async (listener_role_id: number | undefined, input: any) => {
  return await http.post<any>(`/listeners/${listener_role_id}/audio`, input);
};

export const putListenerAudio = async (
  listener_role_id: number | undefined,
  audio_id: number | undefined,
  input: any,
) => {
  return await http.put<any>(`/listeners/${listener_role_id}/audio/${audio_id}`, input);
};

export const getMyStoryAudioByListener = async (listener_role_id: number | undefined) => {
  const { data } = await http.get<any>(`/listeners/${listener_role_id}/audio`);
  return (
    data &&
    data.length > 0 &&
    data.filter((item: any) => item?.topic_tags.some((topic: any) => topic.tag.name === 'My Story'))
  );
};
