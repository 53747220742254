import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { NotFoundJpeg } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
import { If } from '../../common/components/If';
import { UnauthenticatedTopNavigator } from '../../common/components/TopNavigator/UnauthenticatedTopNavigator';
import { ROUTE_PATH } from '../../routing/route-paths';

const NotFound404: React.FunctionComponent = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  return (
    <>
      <UnauthenticatedTopNavigator />
      <Grid sx={{ height: '100vh' }}>
        <Grid sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid sx={{ color: theme.palette.primary.main }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={NotFoundJpeg} alt="not-found" />
            </Box>
            <Typography
              sx={{
                pt: 5,
                textAlign: 'center',
                fontWeight: 800,
                fontSize: '24px',
                lineHeight: '33px',
                letterSpacing: '-0.22px',
              }}
            >
              Oops!
            </Typography>
            <Typography
              sx={{
                pt: 0.5,
                textAlign: 'center',
                fontWeight: 800,
                fontSize: '24px',
                lineHeight: '33px',
                letterSpacing: '-0.22px',
              }}
            >
              Page not found...
            </Typography>
            <Typography
              sx={{
                py: 4,
                textAlign: 'center',
                fontWeight: 700,
                fontSize: '18px',
                lineHeight: '24px',
                letterSpacing: '-0.16px',
                color: 'text.primary',
              }}
            >
              Please try again.
            </Typography>
            <If value={Boolean(token)}>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontWeight: 700,
                  fontSize: '18px',
                  lineHeight: '24px',
                  letterSpacing: '-0.16px',
                  width: '300px',
                  color: 'text.primary',
                }}
              >
                If you continue having issues, please contact support.
              </Typography>
            </If>
            <Grid sx={{ display: 'flex', justifyContent: 'center', pt: 5 }}>
              <Button
                sx={{ width: 'auto', px: 4 }}
                variant="contained"
                className="btn btn-blue h-11 w-full font-bold mt-4"
                onClick={() => navigate(ROUTE_PATH.home.home)}
              >
                Go Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default NotFound404;
