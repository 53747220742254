import React from 'react';
import { FormattedUser } from '../common/http/hooks/user';

const UserContext = React.createContext<FormattedUser | null>(null);

export const UserProvider: React.FC<{ children: React.ReactElement; user: FormattedUser }> = ({ children, user }) => (
  <UserContext.Provider value={user}>{children}</UserContext.Provider>
);

export const useCurrentUser = () => {
  const user = React.useContext(UserContext);

  if (!user) throw new Error('useCurrentUser is being called from an unauthenticated route.');

  return user;
};
