import { Button, Grid, Typography } from '@mui/material';
import { ROUTE_PATH } from '../../../routing/route-paths';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { PageNavigator } from '../../../common/components/TopNavigator/TopNavigator';
import { Box } from '@mui/system';
import { SupportType } from '../../Support/Support';
import { useCurrentUser } from '../../../contexts/user-context';

const FAQsLayout: React.FC<{ questions: string; close: () => void }> = ({ questions }) => {
  const user = useCurrentUser();

  return (
    <>
      <Box>
        <Box dangerouslySetInnerHTML={{ __html: questions }} />
        <Grid container gap={2} direction="column" alignItems="center" mt={4} bgcolor="background.kindlygray3">
          <Grid item>
            <Typography fontWeight={700}>Have additional questions?</Typography>
          </Grid>
          <Grid item display="flex" gap={4} mt={2}>
            <Button
              component={Link}
              to={ROUTE_PATH.support}
              state={{
                type: user.listenerRole?.isListener ? SupportType.LISTENER_ONBOARDING : SupportType.PEER_ONBOARDING,
              }}
              variant="contained"
            >
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default FAQsLayout;
