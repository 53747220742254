import { useMutation, useQuery, useQueryClient } from 'react-query';
import http from '../http-provider';
import { useCurrentUser } from '../../../contexts/user-context';
import invariant from 'tiny-invariant';
import { DateTime, Duration } from 'luxon';
import { isMyStoryTag, type TagResponse } from './tags';

export interface ListenerAudio {
  id: number;
  title: string;
  duration: number;
  created_at: string;
  message_quality: number;
  topic_tags: { tag: TagResponse }[];
  file_url: string;
}

export type ExperienceQuality = 'approved' | 'inReview' | 'revisit';

export const experienceQualityMapping: Record<ExperienceQuality, { bgcolor: string; status: string }> = {
  approved: { bgcolor: 'success.main', status: 'Approved' },
  inReview: { bgcolor: 'warning.main', status: 'In Review' },
  revisit: { bgcolor: 'error.main', status: 'Revisit' },
};

export interface Experience {
  id: number;
  title: string;
  duration: Duration;
  createdAt: DateTime;
  quality: ExperienceQuality;
  fileUrl: string;
  topicIds: number[];
}

export interface CreateExperiencePayload {
  title: string;
  topicTagIds: number[];
  audioFile: Blob;
}

export type UpdateExperiencePayload = Partial<CreateExperiencePayload>;

const fetchListenerAudio = async (listenerRoleId: number) => {
  const { data } = await http.get<ListenerAudio[]>(`/listeners/${listenerRoleId}/audio`);
  return data;
};

const fetchAudioFile = async (fileUrl: string) => {
  const { data } = await http.get<Blob>(fileUrl, { responseType: 'blob' });
  return data;
};

const createExperience = async (
  { audioFile: audioBlob, title, topicTagIds }: CreateExperiencePayload,
  listenerRoleId: number,
) => {
  const audioUrl = await readBlobToUrl(audioBlob);
  invariant(typeof audioUrl === 'string', 'Unable to parse audio file to URL');

  const { data: createdExperienceData } = await http.post<ListenerAudio>(`/listeners/${listenerRoleId}/audio`, {
    audio_file_url: audioUrl,
    title,
    topic_tag_ids: topicTagIds,
  });

  return createdExperienceData;
};

const updateExperience = async (
  experienceId: number,
  listenerRoleId: number,
  { audioFile: audioBlob, title, topicTagIds }: UpdateExperiencePayload,
) => {
  const audioUrl = audioBlob && (await readBlobToUrl(audioBlob));
  invariant(!audioUrl || typeof audioUrl === 'string', 'Unable to parse audio file to URL');

  const { data: updatedExperience } = await http.put<ListenerAudio>(
    `/listeners/${listenerRoleId}/audio/${experienceId}`,
    {
      audio_file_url: audioUrl,
      title,
      topic_tag_ids: topicTagIds,
    },
  );

  return updatedExperience;
};

const readBlobToUrl = (blob: Blob) =>
  new Promise<string | ArrayBuffer | null>((resolve) => {
    const freader = new FileReader();
    freader.onload = () => {
      resolve(freader.result);
    };
    freader.readAsDataURL(blob);
  });

const isMyStory = ({ topic_tags: topicTags }: ListenerAudio): boolean => topicTags.some(({ tag }) => isMyStoryTag(tag));

const formatQuality = (rawQuality: number): ExperienceQuality => {
  if ([3, 5].includes(rawQuality)) return 'approved';
  if (rawQuality === 7) return 'inReview';
  if (rawQuality === 10) return 'revisit';
  throw new Error(`Got invalid value for message_quality: ${rawQuality}`);
};

const formatExperience = ({
  id,
  title,
  duration,
  created_at: createdAtTimestamp,
  message_quality: rawQuality,
  file_url: fileUrl,
  topic_tags: tags,
}: ListenerAudio): Experience => ({
  id,
  title,
  duration: Duration.fromObject({ seconds: duration }),
  createdAt: DateTime.fromISO(createdAtTimestamp),
  quality: formatQuality(rawQuality),
  fileUrl,
  topicIds: tags.map(({ tag: { id } }) => id),
});

const audioKeys = {
  all: ['audio'] as const,
  file: (fileUrl: string) => [...audioKeys.all, 'file', fileUrl] as const,
};

const useAudio = <T,>({ select }: { select: (audio: ListenerAudio[]) => T }) => {
  const user = useCurrentUser();
  const listenerRoleId = user.listenerRole?.id;
  invariant(listenerRoleId, 'Cannot fetch audio; user has no listener role.');

  return useQuery(audioKeys.all, () => fetchListenerAudio(listenerRoleId), { select });
};

export const useMyStory = () =>
  useAudio({
    select: (audios) => {
      const myStory = audios.find(isMyStory);
      return myStory && formatExperience(myStory);
    },
  });

export const useExperiences = () =>
  useAudio({ select: (audios) => audios.filter((audio) => !isMyStory(audio)).map(formatExperience) });

export const useAudioFile = (fileUrl: string) => useQuery(audioKeys.file(fileUrl), () => fetchAudioFile(fileUrl));

export const useCreateExperience = () => {
  const user = useCurrentUser();
  const query = useQueryClient();

  return useMutation(
    async (payload: CreateExperiencePayload) => {
      invariant(user.listenerRole, 'listenerRole is not defined on user.');
      return createExperience(payload, user.listenerRole.id);
    },
    {
      onSuccess: () => {
        query.invalidateQueries(audioKeys.all);
      },
    },
  );
};

export const useUpdateExperience = (experienceId: number) => {
  const queryClient = useQueryClient();
  const user = useCurrentUser();

  return useMutation(
    async (payload: UpdateExperiencePayload) => {
      invariant(user.listenerRole, 'listenerRole is not defined on user.');
      return updateExperience(experienceId, user.listenerRole.id, payload);
    },
    {
      onSuccess: (updatedExperience) => {
        queryClient.setQueryData(audioKeys.all, (experiences: ListenerAudio[] | undefined) =>
          experiences
            ? experiences.map((experience) => (experience.id === updatedExperience.id ? updatedExperience : experience))
            : [updatedExperience],
        );
      },
    },
  );
};
