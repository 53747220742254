import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import Spinner from '../../common/components/Spinner/Spinner';
import ToggleTagButton from '../../common/components/ToggleTagButton';
import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import {
  useToggleProfileTag,
  useTraitTagsForListener,
  type BackgroundTag,
  type ProfileTag,
} from '../../common/http/hooks/tags';

const ProfileTagButton: React.FC<{ tag: ProfileTag }> = ({ tag }) => {
  const toggleTagMutation = useToggleProfileTag(tag);

  return (
    <ToggleTagButton
      selected={tag.listenerTagId !== null}
      onClick={toggleTagMutation.mutate}
      disabled={toggleTagMutation.isLoading}
      data-testid="profile-tag-button"
    >
      {tag.name}
    </ToggleTagButton>
  );
};

export const TraitTagsList: React.FC<{ onAddBackgroundTag: (tag: BackgroundTag) => void }> = ({
  onAddBackgroundTag,
}) => {
  const traitTagsQuery = useTraitTagsForListener();

  if (!traitTagsQuery.data) return <Spinner />;
  const { profileTags, backgroundTags } = traitTagsQuery.data;

  const renderBackgroundTagStatus = (tag: BackgroundTag): React.ReactElement => {
    switch (tag.status) {
      case 'notApplied':
        return (
          <Button
            variant="contained"
            sx={{ width: 'unset', px: 4 }}
            onClick={() => {
              onAddBackgroundTag(tag);
            }}
          >
            Apply
          </Button>
        );
      case 'pending':
        return (
          <Box bgcolor="warning.main" px={2} py={1} borderRadius={2}>
            <Typography color="warning.contrastText" fontWeight={700}>
              Pending
            </Typography>
          </Box>
        );
      case 'approved':
        return (
          <Box bgcolor="secondary.main" px={2} py={1} borderRadius={2}>
            <Typography color="secondary.contrastText" fontWeight={700}>
              Approved
            </Typography>
          </Box>
        );
      case 'rejected':
        return (
          <Box bgcolor="error.main" px={2} py={1} borderRadius={2}>
            <Typography color="error.contrastText" fontWeight={700}>
              Rejected
            </Typography>
          </Box>
        );
    }
  };

  return (
    <>
      <PageNavigator />
      <Stack px={3}>
        <Typography fontWeight={800} variant="h5" component="h1">
          Profile Tags
        </Typography>
        <Box pt={1} />
        <Typography>Select all of the tags that apply.</Typography>
        <Box pt={2} />
        <Box flexWrap="wrap" display="flex" gap={1}>
          {profileTags.map((tag) => (
            <ProfileTagButton tag={tag} key={tag.id} />
          ))}
        </Box>
        <Box pt={3} />
        <Typography fontWeight={800} variant="h5" component="h1">
          Background Tags
        </Typography>
        <Box pt={1} />
        <Typography>Background Tags require review and approval before being displayed with your profile.</Typography>
        <Box pt={2} />
        <Stack gap={1}>
          {backgroundTags.map((tag) => (
            <Box key={tag.id} display="flex" justifyContent="space-between" alignItems="center">
              <Box
                px={1.5}
                py={0.5}
                width="fit-content"
                bgcolor={tag.status === 'approved' ? 'secondary.main' : undefined}
                sx={{
                  borderWidth: 1,
                  borderColor: 'primary.main',
                  borderStyle: 'solid',
                  borderRadius: 2,
                }}
              >
                <Typography
                  color={tag.status === 'approved' ? 'primary.main' : undefined}
                  fontWeight={tag.status === 'approved' ? 600 : 'normal'}
                >
                  {tag.name}
                </Typography>
              </Box>
              {renderBackgroundTagStatus(tag)}
            </Box>
          ))}
        </Stack>
        <Box pt={10} />
      </Stack>
    </>
  );
};
