import React, { createContext, useContext, useEffect, useState } from 'react';

type AddToHomeScreenData = {
  show: boolean;
  event: any;
};

// boolean value as to whether we should show the
export const ADD_TO_HOME_SCREEN_SESSION_KEY = 'add-to-home-screen-state';

const AddToHomeScreenContext = createContext<AddToHomeScreenData>({ show: false, event: null });

export const AddToHomeScreenProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState<Event>();

  useEffect(() => {
    if (Boolean(sessionStorage.getItem(ADD_TO_HOME_SCREEN_SESSION_KEY)) == false) {
      setOpen(false);
    }
  }, [sessionStorage]);

  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    // Prevent the mini-infobar from appearing on mobile
    // Stash the event so it can be triggered later.
    setEvent(e);
    // Update UI notify the user they can install the PWA
    // setShowPrompt(true);
    if (!Boolean(sessionStorage.getItem(ADD_TO_HOME_SCREEN_SESSION_KEY))) {
      setOpen(true);
    }
  });

  window.addEventListener('appinstalled', () => {
    // Hide the app-provided install promotion
    setOpen(false);
    // Clear the deferredPrompt so it can be garbage collected
    setEvent(undefined);
    // send analytics event to indicate successful install
    console.log('PWA was installed');
  });

  const data = {
    show: open,
    event: event,
  };

  return <AddToHomeScreenContext.Provider value={data}>{children}</AddToHomeScreenContext.Provider>;
};

export const useAddToHomeScreenContext = () => {
  return useContext(AddToHomeScreenContext);
};
