import { Typography } from '@mui/material';
import React from 'react';

import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import GuidePageLayout, { AgreementText, TitleText } from './GuidePageLayout';

const ListenerGuidePage: React.FC = () => (
  <GuidePageLayout
    onGuideSentSuccess={() => {
      Toast.success('Listener Guide sent Successfully!');
    }}
    title={<TitleText>The Listener Guide</TitleText>}
    description={
      <Typography color="text.primary">
        The Peer Listener Guide houses all the information you will need to be part of the Kindly Human platform! You
        can have the handbook re-sent to you at any time by confirming your email below!
      </Typography>
    }
    agreement={
      <AgreementText display="flex" alignItems="center">
        I have read and understand the information shared in the Listener Guide.
      </AgreementText>
    }
  />
);

export default ListenerGuidePage;
