import { DateTime, Interval } from 'luxon';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import invariant from 'tiny-invariant';

import { useCurrentUser } from '../../../contexts/user-context';
import http from '../http-provider';

interface OfficeHoursResponse {
  [key: string]: {
    current_availability: {
      start: string;
      end: string;
      day: string;
      start_utc: string;
      end_utc: string;
    }[];
  };
}

export interface ScheduleResponse {
  day_of_week: DayOfWeek;
  starts_at: string;
  ends_at: string;
  id: number;
}

const availabilitiesKeys = {
  all: ['availabilities'] as const,
  officeHours: () => [...availabilitiesKeys.all, 'officeHours'] as const,
  schedule: () => [...availabilitiesKeys.all, 'schedule'] as const,
};

const fetchOfficeHours = async (listenerRoleId: number, timezone: string, startDate: string, endDate: string) => {
  const { data } = await http.get<OfficeHoursResponse>(
    `/listeners/${listenerRoleId}/availabilities?${new URLSearchParams({
      start_date: startDate,
      end_date: endDate,
      local_timezone: timezone,
    })}`,
  );

  return data;
};

export const useOfficeHours = () => {
  const user = useCurrentUser();
  const userTimeZone = user.timezone;

  return useQuery(
    availabilitiesKeys.officeHours(),
    () => {
      invariant(user.listenerRole?.isListener, 'Schedule cannot be retrieved for non-listener.');

      const startDate = DateTime.now().startOf('hour');
      const start = startDate.toISODate();
      const end = startDate.plus({ days: 6 }).toISODate();

      return fetchOfficeHours(user.listenerRole.id, user.timezone, start, end);
    },
    {
      select: (schedule) =>
        Object.entries(schedule)
          .sort(
            ([date1], [date2]) =>
              DateTime.fromFormat(date1, 'yyyy-MM-dd').valueOf() - DateTime.fromFormat(date2, 'yyyy-MM-dd').valueOf(),
          )
          .flatMap(([, { current_availability: availabilityWindows }]) =>
            availabilityWindows.map(({ start_utc: start, end_utc: end }) => {
              let startDate = DateTime.fromISO(start).setZone(userTimeZone);
              let endDate = DateTime.fromISO(end).setZone(userTimeZone);
              if (startDate >= endDate) {
                endDate = endDate.plus({ days: 1 });
              }
              return Interval.fromDateTimes(startDate, endDate);
            }),
          ),
    },
  );
};

const fetchSchedule = async (listenerRoleId: number) => {
  const { data } = await http.get<ScheduleResponse[]>('/availabilities/', {
    params: { listener_role_id: listenerRoleId },
  });
  return data;
};

// we're just dealing with times on the Schedule page, so we should make sure every DateTime we create is on the same day.
export const normalizeDateTime = (dateTime: DateTime) => dateTime.set({ year: 2000, month: 1, day: 1 });

const formatScheduleWindow = ({ starts_at: start, ends_at: end, id: windowId }: ScheduleResponse) => ({
  start: normalizeDateTime(DateTime.fromFormat(start, 'HH:mm:ss')),
  end: normalizeDateTime(DateTime.fromFormat(end, 'HH:mm:ss')).plus({ days: end === '00:00:00' ? 1 : 0 }),
  windowId,
});

export type ScheduleWindow = ReturnType<typeof formatScheduleWindow>;
export type Schedule = Record<DayOfWeek, ScheduleWindow[]>;

const formatSchedule = (scheduleResponse: ScheduleResponse[]) =>
  daysOfWeek.reduce(
    (schedule, day) => ({
      ...schedule,
      [day]: scheduleResponse.filter(({ day_of_week: dayOfWeek }) => day === dayOfWeek).map(formatScheduleWindow),
    }),
    {} as Schedule,
  );

export const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;
export type DayOfWeek = (typeof daysOfWeek)[number];
export const isDayOfWeek = (day: string): day is DayOfWeek => (daysOfWeek as readonly string[]).includes(day);

export const useSchedule = () => {
  const { listenerRole } = useCurrentUser();
  invariant(listenerRole, 'listener role not found on user');

  return useQuery(availabilitiesKeys.schedule(), () => fetchSchedule(listenerRole.id), { select: formatSchedule });
};

type UpdateSchedulePayload = Record<DayOfWeek, { starts_at: string; ends_at: string }[]>;

const formatUpdateSchedulePayload = (schedule: Schedule) =>
  Object.fromEntries(
    Object.entries(schedule).map(([day, windows]) => [
      day,
      windows.map(({ start, end }) => ({ starts_at: start.toISOTime(), ends_at: end.toISOTime() })),
    ]),
  ) as UpdateSchedulePayload;

const updateSchedule = async (schedule: Schedule) => {
  const payload = formatUpdateSchedulePayload(schedule);
  const { data: updatedSchedule } = await http.put<ScheduleResponse[]>('/availabilities/', payload, {
    baseURL: process.env.REACT_APP_API_V3,
  });
  return updatedSchedule;
};

export const useUpdateSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation(updateSchedule, {
    onSuccess: (updatedSchedule) => {
      queryClient.invalidateQueries(['currentUser']);
      queryClient.invalidateQueries(availabilitiesKeys.officeHours());
      queryClient.setQueryData<ScheduleResponse[]>(availabilitiesKeys.schedule(), updatedSchedule);
    },
  });
};
