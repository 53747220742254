import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { allPaths } from './route-paths';
import NotFound404 from '../pages/NotFound-404/NotFound-404';

// There's a little bit going on in here. It's meant to be a reusable component that will give us the normal router-behavior we want, without having to think about it.
// We accomplish this by building out a more comprehensive list of routes, after the ones we manually include in the router.
// First, we simply spread in the routes that we actually care about in our router.
// Then, we spread in an array of every route that can exist in the app, saying "if the user ends up on a page where they're not supposed to be, take them back to the defaultPath that we passed in."
// And finally, we pass in a catch-all, saying "if they end up on a route that doesn't exist at all, show them a 404 page."
const RouterWrapper: React.FC<{ routes: RouteObject[]; defaultPath: typeof allPaths[number] }> = ({
  routes,
  defaultPath,
}) =>
  useRoutes([
    ...routes,
    ...allPaths.map((path) => ({
      path,
      element: <Navigate to={defaultPath} />,
    })),
    {
      path: '*',
      element: <NotFound404 />,
    },
  ]);

export default RouterWrapper;
