import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import { PageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { FormattedUser } from '../../common/http/hooks/user';
import { ROUTE_PATH } from '../../routing/route-paths';
import { CommentOnlySupportForm, BasicSupportPayload } from './Support';
import { Steps as PeerApplicationSteps } from '../Apply/PeerApplicationFlow';
import { useSupportRequest } from '../../common/http/hooks/support';

export interface ApplicationSupportFormData {
  context_text: string;
}

// no dropdown, then pops a success banner after
export const ListenerApplicationSupport: React.FC<{ user: FormattedUser }> = ({ user }) => {
  const navigate = useNavigate();
  const supportMutation = useSupportRequest();

  const submit = (formData: ApplicationSupportFormData) => {
    const input: BasicSupportPayload = {
      user_id: user.id,
      group: 'ONBOARDING',
      subject: 'Contact Us',
      context: 'listener_onboarding',
      comments: formData.context_text.trim(),
    };
    supportMutation.mutate(input, {
      onSuccess: () => {
        Toast.success('Thank you for your message.  We will be in touch shortly regarding your query.');
        navigate(`${ROUTE_PATH.apply.apply}?action=apply-form`);
      },
    });
  };

  return (
    <>
      <PageNavigator />
      <Box px={'1rem'}>
        <Typography fontWeight={800} variant="h6" color={'text.primary'} ml={2} mt={1} mb={3}>
          Contact Us
        </Typography>
        <CommentOnlySupportForm submit={submit} />
      </Box>
    </>
  );
};

export const PeerApplicationSupport: React.FC<{ user: FormattedUser }> = ({ user }) => {
  const navigate = useNavigate();
  const supportMutation = useSupportRequest();

  const submit = (formData: ApplicationSupportFormData) => {
    const input: BasicSupportPayload = {
      user_id: user.id,
      group: 'ONBOARDING',
      subject: 'Contact Us',
      context: 'listener_onboarding',
      comments: formData.context_text.trim(),
    };
    supportMutation.mutate(input, {
      onSuccess: () => {
        Toast.success('Thank you for your message.  We will be in touch shortly regarding your query.');
        navigate(`${ROUTE_PATH.apply.apply}?step=${PeerApplicationSteps.Pending}`);
      },
    });
  };

  return (
    <>
      <PageNavigator />
      <Box px={'1rem'}>
        <Typography fontWeight={800} variant="h6" color={'text.primary'} ml={2} mt={1} mb={3}>
          Contact Us
        </Typography>
        <CommentOnlySupportForm submit={submit} />
      </Box>
    </>
  );
};
