import React from 'react';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextareaAutosize, Typography } from '@mui/material';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import { ROUTE_PATH } from '../../routing/route-paths';
import { UnauthenticatedTopNavigator } from '../../common/components/TopNavigator/UnauthenticatedTopNavigator';
import { useCurrentUser } from '../../contexts/user-context';
import { ApplicationSupportFormData, ListenerApplicationSupport, PeerApplicationSupport } from './ApplicationSupport';
import { ListenerMenuSupport, PeerMenuSupport } from './MenuSupport';
import invariant from 'tiny-invariant';
import { ListenerOnboardingSupport, PeerOnboardingSupport } from './OnboardingSupport';
import { ListenerDowngradeSupport } from './ProfileSupport';
import { ActiveCallSupport, PostCallSupport } from './CallSupport';
import { BackToPageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { FormattedUser } from '../../common/http/hooks/user';
import { useSupportRequest } from '../../common/http/hooks/support';

export interface Suggestion {
  label: string;
  group: string;
}

export interface BasicSupportPayload {
  user_id: number;
  group: string;
  subject: string;
  context: string;
  comments: string;
}

export interface CallSupportPayload extends BasicSupportPayload {
  call_id: number;
}

export interface CallRequestSupportPayload extends BasicSupportPayload {
  request_id: number | null;
}

export interface UnauthenticatedSupportPayload {
  group: string;
  subject: string;
  context: string;
  comments: string;
}

export interface SupportForm {
  support_type: string;
  context_text: string;
}

const supportSchema = yup.object().shape({
  context_text: yup.string().trim().required('This field is required'),
});

export const CommentOnlySupportForm: React.FC<{ submit: (formData: ApplicationSupportFormData) => void }> = ({
  submit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SupportForm>({
    resolver: yupResolver(supportSchema),
  });

  return (
    <form noValidate onSubmit={handleSubmit(submit)}>
      <Typography fontWeight={400} fontSize="12px" lineHeight="24px" letterSpacing="-0.6px" pb={1} mt={1}>
        WHAT DO YOU NEED ASSISTANCE WITH?
      </Typography>
      <Box pt={2} pb={2}>
        <TextareaAutosize
          {...register('context_text')}
          aria-label="minimum height"
          minRows={3}
          placeholder="Please enter your message to support here..."
          style={{
            width: '100%',
            height: '150px',
            outline: 0,
            fontSize: '18px',
            fontWeight: 400,
            fontFamily: 'Manrope',
            padding: '8px 8px 8px 8px',
            borderRadius: '10px',
            borderColor: 'background.kindlygray2',
          }}
        />
        {errors?.context_text?.message ? (
          <Typography color={'error.main'} sx={{ fontSize: '15px' }}>
            {errors.context_text.message}
          </Typography>
        ) : null}
      </Box>
      <Box display="flex" justifyContent="center" pt="28px">
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </Box>
    </form>
  );
};

export const SupportForm: React.FC<{ menuItems: Suggestion[]; submit: (formData: SupportForm) => void }> = ({
  menuItems,
  submit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(supportSchema),
  });

  return (
    <form noValidate onSubmit={handleSubmit(submit)}>
      <Box mt={2} pb={4}>
        <FormControl fullWidth>
          <InputLabel id="support-select-label">SUPPORT TYPE</InputLabel>
          <Select
            {...register('support_type')}
            labelId="support-select-label"
            id="support-types"
            label="SUPPORT TYPE"
            defaultValue={menuItems?.length ? JSON.stringify(menuItems[0]) : null}
            sx={{ width: '100%' }}
          >
            {menuItems.map((item: Suggestion, index) => (
              <MenuItem value={JSON.stringify(item)} key={index}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Typography fontWeight={400} fontSize="12px" lineHeight="24px" letterSpacing="-0.6px" pb={1}>
          CONTEXT & COMMENTS
        </Typography>
      </Box>
      <Box pt={2} pb={2}>
        <TextareaAutosize
          {...register('context_text')}
          aria-label="minimum height"
          minRows={3}
          placeholder="Please enter your message to support here..."
          style={{
            width: '100%',
            height: '150px',
            outline: 0,
            fontSize: '18px',
            fontWeight: 400,
            fontFamily: 'Manrope',
            padding: '8px 8px 8px 8px',
            borderRadius: '10px',
            borderColor: 'background.kindlygray2',
          }}
        />
        {errors?.context_text?.message ? (
          <Typography color={'error.main'} sx={{ fontSize: '15px' }}>
            {/* @ts-ignore */}
            {errors?.context_text?.message}
          </Typography>
        ) : null}
      </Box>
      <Box display="flex" justifyContent="center" pt="28px">
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </Box>
    </form>
  );
};

export enum SupportType {
  LISTENER_MENU,
  PEER_MENU,
  PEER_APPLICATION_PENDING,
  LISTENER_APPLICATION_PENDING,
  LISTENER_ONBOARDING,
  PEER_ONBOARDING,
  LISTENER_DOWNGRADE,
  GENERIC,
  ACTIVE_CALL,
  POST_CALL,
}

interface SupportPageState {
  type: SupportType;
  callId?: number;
}

export const SupportPage: React.FC = () => {
  const user = useCurrentUser();
  const location = useLocation();

  const state = location.state as SupportPageState;

  if (!state) {
    return <BasicUserSupportRequest user={user} />;
  }

  const type = state.type;

  switch (type) {
    case SupportType.LISTENER_MENU:
      return <ListenerMenuSupport user={user} />;
    case SupportType.PEER_MENU:
      return <PeerMenuSupport user={user} />;
    case SupportType.PEER_APPLICATION_PENDING:
      return <PeerApplicationSupport user={user} />;
    case SupportType.LISTENER_APPLICATION_PENDING:
      return <ListenerApplicationSupport user={user} />;
    case SupportType.LISTENER_ONBOARDING:
      return <ListenerOnboardingSupport user={user} />;
    case SupportType.PEER_ONBOARDING:
      return <PeerOnboardingSupport user={user} />;
    case SupportType.LISTENER_DOWNGRADE:
      return <ListenerDowngradeSupport user={user} />;
    case SupportType.POST_CALL:
      invariant(state.callId, 'callId required for Call based support pages');
      return <PostCallSupport user={user} callId={state.callId} />;
    case SupportType.ACTIVE_CALL:
      invariant(state.callId, 'callId required for Call based support pages');
      return <ActiveCallSupport user={user} callId={state.callId} />;
    default:
      return <BasicUserSupportRequest user={user} />;
  }
};

export const BasicUserSupportRequest: React.FC<{ user: FormattedUser }> = ({ user }) => {
  const navigate = useNavigate();
  const supportMutation = useSupportRequest();

  const submit = (formData: ApplicationSupportFormData) => {
    const input: BasicSupportPayload = {
      user_id: user.id,
      group: 'CONTACT',
      subject: 'Contact Us',
      context: 'general_contact',
      comments: formData.context_text.trim(),
    };
    supportMutation.mutate(input, {
      onSuccess: () => {
        Toast.success('Thank you for your message.  We will be in touch shortly regarding your query.');
        navigate(-1);
      },
    });
  };

  return (
    <>
      <BackToPageNavigator />
      <Box px={'1rem'}>
        <Typography fontWeight={800} variant="h6" color={'text.primary'} ml={2} mt={1} mb={3}>
          Contact Us
        </Typography>
        <CommentOnlySupportForm submit={submit} />
      </Box>
    </>
  );
};

export const UnauthenticatedSupportPage: React.FC = () => {
  const navigate = useNavigate();
  const supportMutation = useSupportRequest();

  const submit = (formData: ApplicationSupportFormData) => {
    const input: UnauthenticatedSupportPayload = {
      group: 'ONBOARDING',
      subject: 'Contact Us',
      context: '404',
      comments: formData.context_text.trim(),
    };
    supportMutation.mutate(input, {
      onSuccess: () => {
        Toast.success('Thank you for your message.  We will be in touch shortly regarding your query.');
        navigate(ROUTE_PATH.landingPage);
      },
    });
  };

  return (
    <>
      <UnauthenticatedTopNavigator />
      <Box px={'1rem'}>
        <Typography fontWeight={800} variant="h6" color={'text.primary'} ml={2} mt={1} mb={3}>
          Contact Us
        </Typography>
        <CommentOnlySupportForm submit={submit} />
      </Box>
    </>
  );
};
