// no dropdown options with a success banner after

import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import { OnboardingPageNavigator } from '../../common/components/TopNavigator/TopNavigator';
import { useSupportRequest } from '../../common/http/hooks/support';
import { FormattedUser } from '../../common/http/hooks/user';
import { ROUTE_PATH } from '../../routing/route-paths';
import { BasicSupportPayload, SupportForm } from './Support';

export const PeerOnboardingSupport: React.FC<{ user: FormattedUser }> = ({ user }) => {
  const navigate = useNavigate();
  const supportMutation = useSupportRequest();

  const submit = (formData: SupportForm) => {
    const { group, label } = JSON.parse(formData?.support_type);
    const input: BasicSupportPayload = {
      user_id: user.id,
      group: group,
      subject: label,
      context: 'listener_onboarding',
      comments: formData.context_text.trim(),
    };
    supportMutation.mutate(input, {
      onSuccess: () => {
        Toast.success('Thank you for your message.  We will be in touch shortly regarding your query.');
        navigate(ROUTE_PATH.onboarding.onboarding);
      },
    });
  };

  const items = [
    {
      label: 'Questions about payment',
      group: 'FINANCE',
    },
    {
      label: 'Question about recordings',
      group: 'TECHNOLOGY',
    },
    {
      label: 'Inquiry into Peer Listener',
      group: 'HELP',
    },
    {
      label: 'Other',
      group: 'HELP',
    },
  ];

  return (
    <>
      <OnboardingPageNavigator />
      <Box px={'1rem'}>
        <Typography fontWeight={800} variant="h6" color={'text.primary'} ml={2} mt={1} mb={3}>
          Support
        </Typography>
        <SupportForm submit={submit} menuItems={items} />
      </Box>
    </>
  );
};

export const ListenerOnboardingSupport: React.FC<{ user: FormattedUser }> = ({ user }) => {
  const navigate = useNavigate();
  const supportMutation = useSupportRequest();

  const submit = (formData: SupportForm) => {
    const { group, label } = JSON.parse(formData?.support_type);
    const input: BasicSupportPayload = {
      user_id: user.id,
      group: group,
      subject: label,
      context: 'listener_onboarding',
      comments: formData.context_text.trim(),
    };
    supportMutation.mutate(input, {
      onSuccess: () => {
        Toast.success('Thank you for your message.  We will be in touch shortly regarding your query.');
        navigate(ROUTE_PATH.onboarding.onboarding);
      },
    });
  };

  const items = [
    {
      label: 'Suggestion',
      group: 'PRODUCT',
    },
    {
      label: 'Payment Concern',
      group: 'FINANCE',
    },
    {
      label: 'Training',
      group: 'ACADEMY',
    },
  ];

  return (
    <>
      <OnboardingPageNavigator />
      <Box px={'1rem'}>
        <Typography fontWeight={800} variant="h6" color={'text.primary'} ml={2} mt={1} mb={3}>
          Support
        </Typography>
        <SupportForm submit={submit} menuItems={items} />
      </Box>
    </>
  );
};
