import { useMutation, useQueryClient } from 'react-query';
import Toast from '../../components/PopUpMessage/PopUpMessage';
import http from '../http-provider';
import { AxiosError } from 'axios';
import { ApiError, User } from '../../types';
import { FormattedUser } from './user';
import invariant from 'tiny-invariant';

export interface BackgroundCheckFormData {
  listener_role_id: number;
  email_address: string;
}

const backgroundCheck = async (backgroundCheck: BackgroundCheckFormData) => {
  let result = false;
  const email_address = encodeURIComponent(backgroundCheck.email_address);
  await http
    .post<boolean>(
      `/users/background_check?listener_role_id=${backgroundCheck.listener_role_id}&email_address=${email_address}`,
    )
    .then((res) => {
      result = res.data;
    })
    .catch(() => {
      result = false;
    });
  return result;
};

export const useBackgroundCheck = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (formData: BackgroundCheckFormData) => {
      return backgroundCheck(formData);
    },
    {
      onSuccess: () => {
        // updating the checkr_id is aysnc and when the users/me call comes back we don't have one yet. I'm updating the state with a temp value here to trigger the requested state in the background page.
        queryClient.cancelQueries(['currentUser']);
        queryClient.setQueryData<User | undefined>(['currentUser'], (prevUser: User | undefined) => {
          invariant(prevUser, 'Failed finding user during background check mutation.');
          return { ...prevUser, listener_role: { ...prevUser.listener_role!, checkr_id: 'Building Checkr Id' } };
        });
      },
      onError: (err: AxiosError<ApiError, ApiError>) => {
        Toast.error(
          err?.response?.data?.description ||
            'Something went wrong.  Please try again.  If the error occurs again, please contact support.',
        );
      },
    },
  );
};
