import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useUpdateUser } from '../../common/http/hooks/user';
import React from 'react';
import TermsAndConditionModal from '../CreateAccount/TermsAndConditionModal';
import PrivacyPolicyModal from '../CreateAccount/PrivacyPolicyModal';

export type SetPasswordForm = yup.InferType<ReturnType<typeof setPasswordSchema>>;

export const setPasswordSchema = () => {
  return yup.object().shape({
    password: yup
      .string()
      .required('Password is required')
      .matches(/^.{8,}$/, 'Minimum 8 characters'),
    passwordConfirmation: yup.string().oneOf([yup.ref('password')], 'Passwords must match'),
  });
};

export const SetPassword: React.FC<{ title: string; transitionPage: () => void }> = ({ title, transitionPage }) => {
  const [hasError, setHasError] = useState<boolean>(false);

  const updateUserMutation = useUpdateUser();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SetPasswordForm>({
    resolver: yupResolver(setPasswordSchema()),
  });

  const onSubmit = (formData: SetPasswordForm) => {
    setHasError(false);
    updateUserMutation.mutate(formData, {
      onSuccess: () => {
        transitionPage();
      },
      onError: (error: any) => {
        if (error) setError('password', { message: error.response?.data.description });
      },
    });
  };

  return (
    <>
      <Box p={3} pt={0}>
        <Typography fontWeight={800} component="h1" variant="h5">
          {title}
        </Typography>
        <Box pt={3}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              label="PASSWORD"
              error={Boolean(errors.password)}
              helperText={errors.password?.message}
              {...register('password')}
              onFocus={() => setHasError(false)}
            />
            <Box mt={3} />
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              label="RE-TYPE PASSWORD"
              error={Boolean(errors.passwordConfirmation)}
              helperText={errors.passwordConfirmation?.message}
              {...register('passwordConfirmation')}
              onFocus={() => setHasError(false)}
            />
            <Box pt={2} width="100%" display="flex" justifyContent="center">
              <Button type="submit" disabled={hasError} variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
