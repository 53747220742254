import React, { useState } from 'react';
import { If } from '../../common/components/If';
import Image from 'mui-image';
import { Button, Container, Grid, Typography, TextField, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { requestForgetPasswordLink } from '../../common/http/http-service';
import { ApiError } from '../../common/types';
import { AxiosError } from 'axios';
import * as yup from 'yup';
// images
import logo from '../../assets/images/kindly-human-logo-2-line.svg';
import Toast from '../../common/components/PopUpMessage/PopUpMessage';
import Spinner from '../../common/components/Spinner/Spinner';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from '../../routing/route-paths';

export interface ResetFormData {
  email_address: string;
}

const EmailSchema = yup.object().shape({
  email_address: yup.string().email('Must be valid email').required('Email is required'),
});

export default function ForgetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetFormData>({
    resolver: yupResolver(EmailSchema),
  });
  const [state, setState] = useState({
    sentLink: false,
    emailAddress: '',
  });

  const mutation = useMutation(
    (resetData: ResetFormData): Promise<any> => {
      const email = state.emailAddress.length > 0 ? state.emailAddress : resetData.email_address;
      return requestForgetPasswordLink(email);
    },
    {
      onSuccess: (status: boolean) => {
        setState((prev) => ({ ...prev, sentLink: status }));
        Toast.success('Password Reset Link has been sent to your email');
      },
      onError: (err: AxiosError<ApiError>) => {
        Toast.error(err.response?.data?.description || '');
      },
    },
  );

  const submit = (formData: ResetFormData) => {
    mutation.mutate(formData);
  };

  return (
    <>
      <If value={mutation.isLoading}>
        <Spinner />
      </If>
      <Box
        display="flex"
        flexDirection="column"
        color="primary.contrastText"
        alignItems="center"
        p={2}
        pb={5}
        mb={4}
        sx={{ background: ({ palette: { background } }) => background.patterned }}
      >
        <Box width="100%" display="flex" justifyContent="flex-end">
          <IconButton aria-label="close" component={Link} to={ROUTE_PATH.login} color="inherit">
            <CloseIcon />
          </IconButton>
        </Box>
        <img src={logo} />
      </Box>
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Grid mt={3} mb={3} container spacing={3} direction="column" justifyContent="center" alignItems="center">
          {/* <If value={!state.sentLink}> */}
          <Grid mt={4} item width="90%">
            <Typography fontWeight={800} variant="h5" component="h1">
              {state.sentLink ? 'Check your email for your password reset link.' : 'Forgot Password?'}
            </Typography>
            <Typography variant="body1" mt={3} mb={1}>
              {state.sentLink
                ? 'Follow the directions in your email to reset your password.  Once you have reset your password, you can try signing in again.'
                : 'Please provide your email so we can send you a secure link in order to reset your password.'}
            </Typography>
          </Grid>
          {/* </If> */}
          <Grid item width="90%">
            <TextField
              autoComplete="email"
              {...register('email_address')}
              id="email_address"
              fullWidth
              name="email_address"
              required
              label="EMAIL"
              error={Boolean(errors.email_address)}
              variant="outlined"
              helperText={errors.email_address ? errors.email_address.message : ''}
            />
          </Grid>
          <Grid mt={6} pl={0}>
            <If value={state.sentLink}>
              <Typography>Didn’t get your email?</Typography>
            </If>
            <Button
              type="submit"
              disableRipple
              variant={state.sentLink ? 'text' : 'contained'}
              sx={{
                '&.MuiButtonBase-root:hover': { background: state.sentLink ? 'transparent' : '' },
                py: state.sentLink ? 0 : 2.5,
                height: state.sentLink ? 0 : 5,
                width: 'auto',
                px: 5,
              }}
            >
              {state.sentLink ? 'Resend' : 'Send'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
