import React, { useState } from 'react';
import ListenerMenu from '../../../pages/Menu/ListenerMenu';
import Image from 'mui-image';
import { AppBar, Avatar, Drawer, IconButton, SxProps, Typography } from '@mui/material';

// icons
import MenuIcon from '@mui/icons-material/Menu';
import GridViewIcon from '@mui/icons-material/GridView';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// images
import logonav from '../../../assets/images/logo-nav.svg';
import { unstable_useBlocker, useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../../routing/route-paths';
import { useQueryClient } from 'react-query';
import PeerMenu from '../../../pages/Menu/PeerMenu';
import { useCurrentUser } from '../../../contexts/user-context';
import ConfirmModal from '../ConfirmModal';
import invariant from 'tiny-invariant';
import BackButton from './BackButton';
import FAQSModal from '../../../pages/OnboardMenu/FAQs/FAQModal';
import useAuth from '../../hooks/useAuth';

export const TopNavigatorWrapper: React.FC<{ children: React.ReactNode; sx?: SxProps }> = (props) => (
  <AppBar position="sticky" {...props} />
);

export const TopNavigatorLogo: React.FC = () => <Image duration={0} src={logonav} height="17.59" width="auto" />;

const NavigationMenu: React.FC<{ listenerStatus: boolean | undefined; closeMenu: () => void }> = ({
  listenerStatus,
  closeMenu,
}) => (listenerStatus ? <ListenerMenu closeMenu={closeMenu} /> : <PeerMenu closeMenu={closeMenu} />);

export const HomeNavigator: React.FC = () => {
  const [menuState, setMenuState] = useState<boolean>(false);
  const user = useCurrentUser();
  return (
    <>
      <TopNavigatorWrapper
        sx={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          minHeight: '100px',
          minWidth: '100%',
          px: 3,
        }}
      >
        <Avatar sx={{ backgroundColor: 'success.light', color: 'primary.main' }}>
          <GridViewIcon />
        </Avatar>
        <TopNavigatorLogo />
        {menuState ? (
          <IconButton
            onClick={() => {
              setMenuState(false);
            }}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={() => {
              setMenuState(true);
            }}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
        )}
      </TopNavigatorWrapper>
      <Drawer
        elevation={0}
        sx={{
          width: '100%',
          '& .MuiDrawer-paper': {
            width: '100%',
            boxSizing: 'border-box',
            backgroundColor: 'background.default',
          },
        }}
        anchor="right"
        open={menuState}
      >
        <NavigationMenu
          closeMenu={() => {
            setMenuState(false);
          }}
          listenerStatus={user.listenerRole?.isListener}
        />
      </Drawer>
    </>
  );
};

const SavePageWrapper: React.FC<{
  children: React.ReactNode;
  isDirty: boolean;
  isOpen: boolean;
  save: () => void;
  close: () => void;
  exit: () => void;
}> = ({ children, isDirty, isOpen, save, close, exit }) => {
  const blocker = unstable_useBlocker(isDirty);

  const onSave = () => {
    save();
    blocker.proceed!();
  };

  const onClose = () => {
    close();
    blocker.proceed!();
  };

  const onExit = () => {
    exit();
    blocker.reset!();
  };

  return (
    <>
      {children}
      <ConfirmModal
        title="Are you sure?"
        text="You have unsaved changes.  If you leave this screen without saving, your changes will be lost."
        button1text="Save Changes"
        button2text="Discard Changes"
        close={onClose}
        exit={onExit}
        confirmAction={onSave}
        openState={isOpen || blocker.state === 'blocked'}
      />
    </>
  );
};

export const ActivePageNavigation: React.FC<{
  saveNeeded: boolean;
  isBackButtonVisible: boolean;
  save?: () => void;
  clear?: () => void;
}> = ({ saveNeeded, isBackButtonVisible, save, clear }) => {
  const navigate = useNavigate();
  const [menuState, setMenuState] = useState<boolean>(false);
  const [isConfirmingOpenMenu, setIsConfirmingOpenMenu] = useState(false);
  const [goingHome, setGoingHome] = useState<boolean>(false);
  const user = useCurrentUser();

  const checkPageState = () => {
    if (menuState) {
      navigate(ROUTE_PATH.home.home);
    } else if (saveNeeded) {
      setIsConfirmingOpenMenu(true);
    } else if (!menuState) {
      setMenuState(true);
      if (clear) {
        clear();
      }
    }
  };

  const navigateHome = () => {
    if (menuState) {
      navigate(ROUTE_PATH.home.home);
    } else if (saveNeeded) {
      setGoingHome(true);
      setIsConfirmingOpenMenu(true);
    } else if (!menuState) {
      navigate(ROUTE_PATH.home.home);
      if (clear) {
        clear();
      }
    }
  };

  const onConfirmClose = () => {
    if (clear) {
      clear();
    }
    setIsConfirmingOpenMenu(false);
    setMenuState(true);
  };

  const onSave = () => {
    if (save) {
      save();
    }
    if (goingHome) {
      navigate(ROUTE_PATH.home.home);
    } else {
      setIsConfirmingOpenMenu(false);
      setMenuState(true);
    }
  };

  return (
    <SavePageWrapper
      isDirty={saveNeeded}
      close={onConfirmClose}
      exit={() => setIsConfirmingOpenMenu(false)}
      isOpen={isConfirmingOpenMenu}
      save={onSave}
    >
      <TopNavigatorWrapper
        sx={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          minHeight: '100px',
          minWidth: '100%',
          px: 3,
        }}
      >
        <IconButton onClick={() => navigateHome()} size="large" edge="start" color="inherit" aria-label="menu">
          <GridViewIcon />
        </IconButton>
        <TopNavigatorLogo />
        <IconButton onClick={() => checkPageState()} size="large" edge="start" color="inherit" aria-label="menu">
          <CloseIcon />
        </IconButton>
      </TopNavigatorWrapper>
      {isBackButtonVisible && <BackButton onClick={() => navigateHome()} />}
      <Drawer
        elevation={0}
        SlideProps={{ direction: menuState ? 'right' : 'left' }}
        sx={{
          width: '100%',
          '& .MuiDrawer-paper': {
            width: '100%',
            boxSizing: 'border-box',
            backgroundColor: 'background.default',
          },
        }}
        anchor="right"
        open={menuState}
      >
        <NavigationMenu
          closeMenu={() => {
            setMenuState(false);
          }}
          listenerStatus={user.listenerRole?.isListener}
        />
      </Drawer>
    </SavePageWrapper>
  );
};

export const OnboardingNavigator = () => {
  const navigate = useNavigate();
  const [menuState, setMenuState] = useState<boolean>(false);
  const user = useCurrentUser();

  return (
    <>
      <TopNavigatorWrapper
        sx={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          minHeight: '100px',
          minWidth: '100%',
          px: 3,
        }}
      >
        <IconButton
          onClick={() => navigate(ROUTE_PATH.home.home)}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <GridViewIcon />
        </IconButton>
        <TopNavigatorLogo />
        <IconButton
          onClick={() => setMenuState((state) => !state)}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          {menuState ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </TopNavigatorWrapper>
      <Drawer
        elevation={0}
        SlideProps={{ direction: menuState ? 'right' : 'left' }}
        sx={{
          width: '100%',
          '& .MuiDrawer-paper': {
            width: '100%',
            boxSizing: 'border-box',
            backgroundColor: 'background.default',
          },
        }}
        anchor="right"
        open={menuState}
      >
        <NavigationMenu
          closeMenu={() => {
            setMenuState(false);
          }}
          listenerStatus={user.listenerRole?.isListener}
        />
      </Drawer>
    </>
  );
};

export const OnboardingHomeNavigator: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const { logout } = useAuth();

  return (
    <>
      <TopNavigatorWrapper
        sx={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          minHeight: '100px',
          minWidth: '100%',
          px: 3,
        }}
      >
        <Typography
          color="text.light"
          sx={{
            fontSize: 16,
            fontWeight: 600,
            cursor: 'pointer',
          }}
          onClick={() => setOpenModal(true)}
        >
          FAQs
        </Typography>
        <TopNavigatorLogo />
        <Typography
          color="text.light"
          sx={{
            fontSize: 16,
            fontWeight: 600,
            cursor: 'pointer',
          }}
          onClick={logout}
        >
          Sign Out
        </Typography>
      </TopNavigatorWrapper>
      <FAQSModal open={openModal} close={() => setOpenModal(false)} />
    </>
  );
};

export const OnboardingPageNavigator: React.FC<{
  isBackButtonVisible?: boolean;
  saveNeeded?: boolean;
  save?: () => void;
}> = ({ isBackButtonVisible = true, saveNeeded = false, save }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isConfirmingOpenMenu, setIsConfirmingOpenMenu] = useState(false);
  const { logout } = useAuth();

  const checkPageState = () => {
    if (saveNeeded) {
      setIsConfirmingOpenMenu(true);
    } else {
      navigate(ROUTE_PATH.onboarding.onboarding);
    }
  };

  const onConfirmClose = () => {
    setIsConfirmingOpenMenu(false);
    navigate(ROUTE_PATH.onboarding.onboarding);
  };

  const onSave = () => {
    if (save) {
      save();
    }
    setIsConfirmingOpenMenu(false);
    navigate(ROUTE_PATH.onboarding.onboarding);
  };

  return (
    <SavePageWrapper
      isDirty={saveNeeded}
      close={onConfirmClose}
      exit={() => setIsConfirmingOpenMenu(false)}
      isOpen={isConfirmingOpenMenu}
      save={onSave}
    >
      <TopNavigatorWrapper
        sx={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          minHeight: '100px',
          minWidth: '100%',
          px: 3,
        }}
      >
        <Typography
          color="text.light"
          sx={{
            fontSize: 16,
            fontWeight: 600,
            cursor: 'pointer',
          }}
          onClick={() => {
            setOpenModal(true);
          }}
        >
          FAQs
        </Typography>
        <TopNavigatorLogo />
        <Typography
          color="text.light"
          sx={{
            fontSize: 16,
            fontWeight: 600,
            cursor: 'pointer',
          }}
          onClick={logout}
        >
          Sign Out
        </Typography>
      </TopNavigatorWrapper>
      {isBackButtonVisible && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: '2rem',
            marginTop: '1.5rem',
            marginBottom: '1rem',
          }}
          onClick={() => checkPageState()}
        >
          <ArrowBackIosIcon sx={{ color: 'primary.main', cursor: 'pointer' }} />
          <Typography
            sx={{ cursor: 'pointer' }}
            fontWeight={600}
            color="primary.main"
            component="div"
            variant="subtitle1"
          >
            Onboarding Hub
          </Typography>
        </div>
      )}
      <FAQSModal open={openModal} close={() => setOpenModal(false)} />
    </SavePageWrapper>
  );
};

export const PageNavigator: React.FC<{
  saveNeeded?: boolean;
  isBackButtonVisible?: boolean;
  save?: () => void;
  clear?: () => void;
}> = ({ saveNeeded = false, isBackButtonVisible = true, save, clear }) => {
  const user = useCurrentUser();
  invariant(user.listenerRole, 'Listener role is not defined on user.');

  switch (user.listenerRole.state) {
    case 'onboarding_peer':
    case 'rejected_peer':
      return (
        <ActivePageNavigation
          saveNeeded={saveNeeded}
          isBackButtonVisible={isBackButtonVisible}
          save={save}
          clear={clear}
        />
      );
    case 'active_peer':
    case 'active_listener':
      return (
        <ActivePageNavigation
          saveNeeded={saveNeeded}
          isBackButtonVisible={isBackButtonVisible}
          save={save}
          clear={clear}
        />
      );
    case 'upgrading_peer':
      return <OnboardingPageNavigator isBackButtonVisible={isBackButtonVisible} />;
    default:
      throw new Error(`User is in invalid state: ${user.listenerRole.state}`);
  }
};

export const ActiveCallNavigator: React.FC = () => {
  return (
    <TopNavigatorWrapper
      sx={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        minHeight: '100px',
        minWidth: '100%',
        px: 3,
      }}
    >
      <TopNavigatorLogo />
    </TopNavigatorWrapper>
  );
};

export const ActionPageNavigation: React.FC = () => {
  const navigate = useNavigate();

  return (
    <TopNavigatorWrapper
      sx={{
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        minHeight: '100px',
        minWidth: '100%',
        px: 3,
      }}
    >
      <IconButton
        onClick={() => navigate(ROUTE_PATH.home.home)}
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        <GridViewIcon />
      </IconButton>
      <TopNavigatorLogo />
      <IconButton
        onClick={() => navigate(ROUTE_PATH.home.home)}
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
      >
        <CloseIcon />
      </IconButton>
    </TopNavigatorWrapper>
  );
};

export const BackToPageNavigator: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <TopNavigatorWrapper
        sx={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          minHeight: '100px',
          minWidth: '100%',
          px: 3,
        }}
      >
        <IconButton
          onClick={() => navigate(ROUTE_PATH.home.home)}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <GridViewIcon />
        </IconButton>
        <TopNavigatorLogo />
        <IconButton onClick={() => navigate(-1)} size="large" edge="start" color="inherit" aria-label="menu">
          <CloseIcon />
        </IconButton>
      </TopNavigatorWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: '2rem',
          marginTop: '1.5rem',
          marginBottom: '1rem',
        }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIosIcon sx={{ color: 'primary.main', cursor: 'pointer' }} />
        <Typography
          sx={{ cursor: 'pointer' }}
          fontWeight={600}
          color="primary.main"
          component="div"
          variant="subtitle1"
        >
          Back
        </Typography>
      </div>
    </>
  );
};
