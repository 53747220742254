import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import http from '../common/http/http-provider';

export const useAuthInterceptor = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const authInterceptor = http.interceptors.response.use(
      (res) => res,
      (err: AxiosError) => {
        if (err.response?.status === 403) {
          localStorage.removeItem('token');
          // TODO: extract key
          queryClient.invalidateQueries(['currentUser']);
        }
        return Promise.reject(err);
      },
    );

    return () => {
      http.interceptors.response.eject(authInterceptor);
    };
  }, [queryClient]);
};
