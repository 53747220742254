import React from 'react';
import { Stack, Divider, Box, Typography, Link, IconButton, Button, useTheme } from '@mui/material';

// icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ROUTE_PATH } from '../../routing/route-paths';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SupportType } from '../Support/Support';
import { Link as RouterLink } from 'react-router-dom';
import useAuth from '../../common/hooks/useAuth';

interface PeerMenuProps {
  closeMenu: () => void;
}

interface MenuItem {
  link: string;
  title: string;
  type?: SupportType;
}

export default function PeerMenu({ closeMenu }: PeerMenuProps) {
  const { logout } = useAuth();

  const theme = useTheme();
  const items: MenuItem[] = [
    {
      link: ROUTE_PATH.home.profile,
      title: 'Profile',
    },
    {
      link: ROUTE_PATH.home.roles,
      title: 'Role',
    },
    {
      link: ROUTE_PATH.home.experiences,
      title: 'My Experiences',
    },
    {
      link: '/home/tags',
      title: 'Tags',
    },
    {
      link: ROUTE_PATH.home.banking,
      title: 'My Banking Information',
    },
    {
      link: ROUTE_PATH.home.terms,
      title: 'Terms, Conditions & Privacy',
    },
    {
      link: ROUTE_PATH.support,
      title: 'Support',
      type: SupportType.PEER_MENU,
    },
  ];

  // HACK: we have to stick some space above the menu so the header doesn't get in its way.
  const headerHeight = document.querySelector('.MuiAppBar-root')?.clientHeight;
  return (
    <Stack divider={<Divider orientation="horizontal" flexItem />}>
      <Box mt={`${headerHeight}px`} />
      {items.map(({ title, link, type }, index) => {
        return (
          <Link
            component={RouterLink}
            onClick={() => {
              closeMenu();
            }}
            sx={{ textDecoration: 'none', color: theme.palette.text.primary }}
            key={index}
            to={link}
            state={type ? { type } : undefined}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between" ml={5} mt={1} mb={1}>
              <Typography variant="body1" fontWeight={800}>
                {title}
              </Typography>
              <IconButton size="large" edge="start" color="primary" aria-label="menu">
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          </Link>
        );
      })}
      <Box
        py={4}
        bgcolor={theme.palette.background.kindlygray3}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button color="primary" onClick={() => logout()}>
          Sign Out
        </Button>
      </Box>
    </Stack>
  );
}
