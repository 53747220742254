import React, { lazy, Suspense } from 'react';
import { ROUTE_PATH } from '../route-paths';
import Spinner from '../../common/components/Spinner/Spinner';
import { RouteObject } from 'react-router-dom';
import RouterWrapper from '../RouterWrapper';
import { peerActiveRoutes } from './PeerActiveRouter';
import OnboardMenu from '../../pages/OnboardMenu/OnboardMenu';
import ListenerGuidePage from '../../pages/Guide/ListenerGuidePage';
import BackgroundCheck from '../../pages/BackgroundCheck/BackgroundCheck';
import AboutMe from '../../pages/AboutMe/AboutMe';
import TraitTags from '../../pages/Tags/TraitTags';
import ListeningAcademy from '../../pages/ListeningAcademy/ListeningAcademy';
import SchedulePage from '../../pages/Schedule/SchedulePage';

const peerUpgradingRoutes: RouteObject[] = [
  {
    path: ROUTE_PATH.onboarding.onboarding,
    element: (
      <Suspense fallback={<Spinner />}>
        <OnboardMenu />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.onboarding.guide,
    element: (
      <Suspense fallback={<Spinner />}>
        <ListenerGuidePage />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.onboarding.backgroundCheck,
    element: (
      <Suspense fallback={<Spinner />}>
        <BackgroundCheck />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.onboarding.aboutMe,
    element: (
      <Suspense fallback={<Spinner />}>
        <AboutMe />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.onboarding.tags,
    element: (
      <Suspense fallback={<Spinner />}>
        <TraitTags />
      </Suspense>
    ),
  },

  {
    path: ROUTE_PATH.onboarding.listenerAcademy,
    element: (
      <Suspense fallback={<Spinner />}>
        <ListeningAcademy />
      </Suspense>
    ),
  },
  {
    path: ROUTE_PATH.onboarding.schedule,
    element: (
      <Suspense fallback={<Spinner />}>
        <SchedulePage />
      </Suspense>
    ),
  },
];

const PeerUpgradingRouter: React.FC = () => (
  <RouterWrapper routes={[...peerActiveRoutes, ...peerUpgradingRoutes]} defaultPath={ROUTE_PATH.home.home} />
);

export default PeerUpgradingRouter;
