import axios from 'axios';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_V2,
  headers: {
    Accept: 'application/json',
  },
});

// auth interceptor
http.interceptors.request.use(
  (request) => {
    if (request.url && !request.url.includes('authenticate'))
      request.headers!['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// rewrite local requests to same host e.g. localhost:3000 -> 192.168.1.1:3000 (useful for local mobile testing)
http.interceptors.request.use(
  (request) => {
    if (request.baseURL && request.baseURL.includes('localhost') && window.location.hostname !== 'localhost')
      request.baseURL = request.baseURL.replace('localhost', window.location.hostname);
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default http;
