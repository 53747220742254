import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slider,
  Typography,
} from '@mui/material';
import Cropper, { Area, Point } from 'react-easy-crop';
import './styles.css';
import getCroppedImg from './getCroppedImage';
import CloseIcon from '@mui/icons-material/Close';
import { theme } from '../../../theme';

export interface LocCropperProps {
  image: any;
  setProfileImage: any;
}

export interface CropperData {
  zoom: number | undefined;
  aspect: number;
  crop: Point;
}

const LocCropper = ({ image, setProfileImage }: LocCropperProps) => {
  const [state, setState] = useState(true);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [data, setData] = useState<CropperData>({
    crop: { x: 0, y: 0 },
    zoom: 1,
    aspect: 1,
  });

  const onZoomChange = (zoom: number | undefined) => {
    setData({ ...data, zoom });
  };

  const onCropChange = (crop: Point) => {
    setData({ ...data, crop });
  };

  const handleClose = async () => {
    setProfileImage(await getCroppedImg(image, croppedAreaPixels));
    setState(false);
  };

  const handleCancel = () => {
    setProfileImage(null);
    setState(false);
  };

  return (
    <Dialog fullWidth={true} open={state}>
      <DialogTitle>
        <Grid container flexDirection="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography fontWeight={800} variant="h6">
              Crop Image
            </Typography>
          </Grid>
          <Grid mr={-2} onClick={handleCancel} item>
            <IconButton aria-label="close">
              <CloseIcon fontSize="large" sx={{ color: theme.palette.text.primary }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ position: 'relative', width: '87%', alignSelf: 'center', height: '30vh' }}>
        <div className="crop-container">
          <Cropper
            image={image}
            crop={data.crop}
            zoom={data.zoom}
            aspect={data.aspect}
            cropShape="round"
            showGrid={false}
            onCropChange={onCropChange}
            onCropAreaChange={(croppedArea, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={onZoomChange}
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '20px' }}>
        <Slider
          sx={{ width: '50%', marginBottom: '1rem' }}
          value={data.zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => {
            if (typeof zoom === 'number') {
              onZoomChange(zoom);
            }
          }}
        />
        <Button onClick={() => handleClose()} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocCropper;
