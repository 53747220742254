import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { usePeerFAQs } from '../../../common/http/hooks/metadata';
import FAQsLayout from './FAQsLayout';
import Spinner from '../../../common/components/Spinner/Spinner';

export interface FAQModalProps {
  close: () => void;
  open: boolean;
}

const FAQSModal = (props: FAQModalProps) => {
  const { close, open } = props;
  const { data: peerFAQs } = usePeerFAQs();

  if (peerFAQs === undefined) return <Spinner />;

  return (
    <Dialog PaperProps={{ style: { backgroundColor: 'background.paper' } }} open={open} onClose={close}>
      <DialogTitle fontWeight={800}>
        <Grid container alignItems="baseline" justifyContent="space-between">
          <Grid ml={2} mb={2} item>
            <Typography fontWeight={800} variant="h5">
              FAQs
            </Typography>
          </Grid>
          <Grid item alignSelf="right" mr={-2}>
            <IconButton onClick={close} aria-label="close">
              <CloseIcon sx={{ color: 'text.primary' }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <FAQsLayout questions={peerFAQs} close={close} />
      </DialogContent>
    </Dialog>
  );
};

export default FAQSModal;
