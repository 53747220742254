import { useQuery, useQueryClient } from 'react-query';
import http from '../http-provider';
import { useCurrentUser } from '../../../contexts/user-context';
import { newConnectionsKeys } from './connections';

interface Notification {
  context: 'updated_call' | 'updated_connections';
}

const fetchNotifications = async (userId: number) => {
  const { data } = await http.get<Notification | null>(`/users/${userId}/notification`);
  return data;
};

export const useNotifications = (options?: { onSuccess?: (data: Notification | null) => void }) => {
  const queryClient = useQueryClient();
  const user = useCurrentUser();

  // long poll
  return useQuery(['poll', user.id], () => fetchNotifications(user.id), {
    retry: true,
    retryDelay: 1000 * 30,
    onSuccess: (data) => {
      options?.onSuccess?.(data);
      // TODO: extract query key
      if (data?.context === 'updated_call') queryClient.invalidateQueries(['activeCall']);
      if (data?.context === 'updated_connections') queryClient.invalidateQueries(newConnectionsKeys.all);
      queryClient.invalidateQueries(['poll']);
    },
  });
};
