import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface IconTooltipProps {
  title: String;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 276,
    backgroundColor: theme.palette.primary.main,
    fontSize: '14px',
    padding: '12px',
    borderRadius: '4px',
    fontWeight: 700,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
}));

const IconTooltip: React.FC<IconTooltipProps> = ({ title }) => (
  <CustomTooltip enterTouchDelay={0} leaveTouchDelay={3000} title={title} arrow>
    <InfoOutlinedIcon aria-label="tooltip" color="primary" />
  </CustomTooltip>
);

export default IconTooltip;
