import { useMutation } from 'react-query';
import { BasicSupportPayload } from '../../../pages/Support/Support';
import Toast from '../../components/PopUpMessage/PopUpMessage';
import http from '../http-provider';

const support = async (supportForm: BasicSupportPayload) => {
  const { data: result } = await http.post(`/users/${supportForm.user_id}/support`, supportForm);
  return result;
};

export const useSupportRequest = () => {
  return useMutation(
    async (supportForm: any) => {
      return support(supportForm);
    },
    {
      onError: () => {
        Toast.error('Something went wrong.  Please try again.  If the error occurs again, please contact support.');
      },
    },
  );
};
