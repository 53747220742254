import { DateTime } from 'luxon';
import { useReducer } from 'react';
import invariant from 'tiny-invariant';

import { isDayOfWeek, normalizeDateTime, type DayOfWeek } from '../../common/http/hooks/availabilities';
import type { FormattedTier } from '../../common/http/hooks/user';
import { useCurrentUser } from '../../contexts/user-context';
import {
  buildMissingTimeError,
  buildNotEnoughWeekHoursError,
  buildTooManyDayHoursError,
  buildTooManyWeekHoursError,
  type MissingTimeError,
  type NotEnoughWeekHoursError,
  type TooManyDayHoursError,
  type TooManyWeekHoursError,
} from './errors';
import { sumHours } from './SchedulePage';

export interface FormWindow {
  windowId: number;
  start: DateTime | null;
  end: DateTime | null;
}

export type FormValues = Record<DayOfWeek, FormWindow[]>;

export interface FormErrors {
  week: Array<TooManyWeekHoursError | NotEnoughWeekHoursError>;
  day: Array<TooManyDayHoursError>;
  time: Array<MissingTimeError>;
}

export type FormState = { formValues: FormValues; formErrors: FormErrors; isLoading: boolean };

export type FormAction =
  | { type: 'initialize'; formValues: FormValues }
  | { type: 'add'; day: DayOfWeek }
  | { type: 'update'; day: DayOfWeek; windowId: number; position: 'start' | 'end'; value: string }
  | { type: 'remove'; day: DayOfWeek; windowId: number };

const initFormState: FormState = {
  isLoading: true,
  formValues: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  },
  formErrors: {
    week: [],
    day: [],
    time: [],
  },
};

// we're using Math.random for temp IDs
export const isTempId = (windowId: number) => windowId < 1;

const validateTimes = (formValues: FormValues): FormErrors['time'] =>
  Object.entries(formValues).flatMap(([day, windows]) => {
    invariant(isDayOfWeek(day), `${day} is not a day of the week`);
    return windows.flatMap(({ start, end, windowId }) => {
      const startError = start ? [] : [buildMissingTimeError({ day, windowId, position: 'start' })];
      const endError = end ? [] : [buildMissingTimeError({ day, windowId, position: 'end' })];
      return [...startError, ...endError];
    });
  });

const validateDays = (
  { maxDailyOfficeHours, hasOfficeHours }: FormattedTier,
  formValues: FormValues,
): FormErrors['day'] =>
  Object.entries(formValues).flatMap(([day, windows]) => {
    invariant(isDayOfWeek(day), `${day} is not a day of the week`);
    return hasOfficeHours && sumHours(windows) > maxDailyOfficeHours
      ? [buildTooManyDayHoursError(maxDailyOfficeHours, { day })]
      : [];
  });

const validateWeek = (
  { minOfficeHours, paidOfficeHoursCap, hasOfficeHours }: FormattedTier,
  formValues: FormValues,
): FormErrors['week'] => {
  const totalHours = sumHours(Object.values(formValues).flat());
  if (totalHours < minOfficeHours) return [buildNotEnoughWeekHoursError(minOfficeHours)];
  if (hasOfficeHours && totalHours > paidOfficeHoursCap) return [buildTooManyWeekHoursError(paidOfficeHoursCap)];
  return [];
};

const useValidateForm = () => {
  const { listenerRole } = useCurrentUser();
  invariant(listenerRole, 'User has no listener role.');
  const { tier } = listenerRole;
  invariant(tier, 'User has no listener-tier.');

  return (formValues: FormValues): FormErrors => ({
    week: validateWeek(tier, formValues),
    day: validateDays(tier, formValues),
    time: validateTimes(formValues),
  });
};

// this might seem dumb but it'll save a lot of time if we ever want to change the format :D
export const h_mm_a = 'h:mm a';

const useScheduleReducer = () => {
  const validateForm = useValidateForm();

  const reducer = (state: FormState, action: FormAction): FormState => {
    switch (action.type) {
      case 'initialize':
        return { isLoading: false, formValues: action.formValues, formErrors: validateForm(action.formValues) };
      case 'add': {
        const updatedValues = {
          ...state.formValues,
          [action.day]: [...state.formValues[action.day], { start: null, end: null, windowId: Math.random() }],
        };
        return { ...state, formValues: updatedValues, formErrors: validateForm(updatedValues) };
      }
      case 'update': {
        const windowToUpdate = state.formValues[action.day].find(({ windowId }) => windowId === action.windowId)!;
        const updatedWindow = {
          ...windowToUpdate,
          [action.position]: normalizeDateTime(DateTime.fromFormat(action.value, h_mm_a)).plus({
            days: action.value === '12:00 AM' && action.position === 'end' ? 1 : 0,
          }),
        };
        const updatedValues = {
          ...state.formValues,
          [action.day]: state.formValues[action.day].map((window) =>
            window.windowId === action.windowId ? updatedWindow : window,
          ),
        };
        return { ...state, formValues: updatedValues, formErrors: validateForm(updatedValues) };
      }
      case 'remove': {
        const updatedValues = {
          ...state.formValues,
          [action.day]: state.formValues[action.day].filter(({ windowId }) => windowId !== action.windowId),
        };
        return { ...state, formValues: updatedValues, formErrors: validateForm(updatedValues) };
      }
    }
  };

  return useReducer(reducer, initFormState);
};

export default useScheduleReducer;
